import { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect, { SelectProps } from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import { errorTextLeftMargin } from 'shared/common/policies/sizes';
import { others } from 'shared/styles/colors/others';
import { hexToRgba } from 'shared/styles/colors/utils';
import theme from 'shared/styles/mui/theme';
import styled from 'styled-components';
import { InputComponentProps } from '../style/Styles';

interface Props {
  itemsPerPage?: number;
  placeholder?: string;
  options: { value: string | number; name: string; disabled?: boolean }[];
  error?: string;
  className?: string;
  width?: string;
  CustomMenuItem?: (props: any) => React.JSX.Element;
}

export const PLACEHOLDER_VALUE = '__PLACEHOLDER__';

export default function Select({
  options = [],
  itemsPerPage,
  placeholder,
  error,
  className,
  width = '',
  value,
  CustomMenuItem,
  variant = 'outlined',
  ...rest
}: Props & Omit<SelectProps, 'error'>) {
  const defaultValue = placeholder ? PLACEHOLDER_VALUE : '';
  const [open, setOpen] = useState(false);
  const [placeholderSelected, setPlaceholderSelected] = useState(
    placeholder && value === PLACEHOLDER_VALUE,
  );

  useEffect(() => {
    setPlaceholderSelected(placeholder && value === PLACEHOLDER_VALUE);
  }, [placeholder, value]);

  const widthObj = width ? { minWidth: width, maxWidth: width } : {};
  const maxHeight = isFinite(itemsPerPage) ? `${3 * itemsPerPage}rem` : '';
  const menuProps: MenuProps = {
    open,
    PaperProps: { style: { maxHeight, ...widthObj } },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    ...rest.MenuProps,
  };

  const Item = CustomMenuItem ?? StyledMenuItem;
  return (
    <SelectContainer className={className} $width={width}>
      <StyledSelect
        MenuProps={menuProps}
        value={options.length === 0 || !value ? defaultValue : value}
        {...rest}
        onChange={(e, x) => {
          setPlaceholderSelected(false);
          rest.onChange(e, x);
        }}
        $placeholder={placeholderSelected}
        $error={Boolean(error)}
        $active={open}
        onOpen={() => setOpen(true)}
        // onClose 콜백은 메뉴가 닫힌 후가 아니라 닫히기 시작할 때 호출되는데
        // 딜레이를 주지 않으면 드랍다운에 플레이스홀더 밸류가 잠깐 생겼다가 사라짐
        // 따라서 완전히 닫힌 후 setOpen을 호출하기 위해 100ms의 딜레이를 줌
        onClose={() => setTimeout(() => setOpen(false), 100)}
        className={className}
        variant={variant}
      >
        <Item disabled value={PLACEHOLDER_VALUE} $active={!open}>
          {placeholder}
        </Item>
        {options.map(({ value, name, disabled = false }) => (
          <Item value={value} key={value} disabled={disabled}>
            {name}
          </Item>
        ))}
      </StyledSelect>
      <ErrorSpan>{error}</ErrorSpan>
    </SelectContainer>
  );
}

const DISABLED_OPACITY = 0.3;
const SelectContainer = styled.div<{ $width?: string }>`
  position: relative;

  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-input {
    line-height: 1.75;
  }

  .MuiSelect-icon {
    margin-right: 0.9375rem;
  }

  &.transparent-style {
    ${({ $width }) => `
      max-width: ${$width};
      min-width: ${$width};
    `}
    background-color: transparent;

    .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.palette.primary.main};
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.palette.primary.contrastText};
    }

    .MuiInputBase-input {
      color: ${theme.palette.primary.contrastText};
      background-color: transparent;
      padding: 0.375rem 3rem 0.375rem 0.875rem;
      line-height: 1.75;
    }

    svg {
      color: ${theme.palette.primary.contrastText};
    }

    .MuiSelect-root.Mui-disabled {
      color: ${hexToRgba(theme.palette.primary.contrastText, DISABLED_OPACITY)};
      ~ svg {
        color: ${hexToRgba(theme.palette.primary.contrastText, DISABLED_OPACITY)};
      }
      ~ .MuiOutlinedInput-notchedOutline {
        border-color: ${hexToRgba(theme.palette.primary.contrastText, DISABLED_OPACITY)};
      }
    }
  }

  &.dark-style {
    .MuiSelect-root {
      background-color: transparent;
    }

    .MuiInputBase-root {
      background-color: ${theme.palette.grey[900]};
    }

    .MuiInputBase-input {
      padding: 0.375rem 3rem 0.375rem 0.875rem;
      line-height: 1.75;
      color: ${theme.palette.primary.contrastText};
    }

    .MuiSvgIcon-root {
      color: ${theme.palette.primary.contrastText};
    }
  }
`;

function getSelectTextColor({ $placeholder, $error }: { $placeholder: boolean; $error: boolean }) {
  if ($error) {
    return theme.palette.error.main;
  }
  if ($placeholder) {
    return theme.palette.text.secondary;
  }
  return theme.palette.text.primary;
}
const StyledSelect = styled(MuiSelect)<
  InputComponentProps & { $placeholder: boolean; $error: boolean }
>`
  .MuiSelect-root {
    background: ${theme.palette.background.paper};
    padding: 0.875rem 2rem 0.875rem 0.875rem;
    color: ${getSelectTextColor};

    &.Mui-disabled {
      color: ${theme.palette.text.disabled};
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ $error }) => ($error ? theme.palette.error.main : others.BORDER)};
  }

  .MuiSelect-icon {
    top: initial;
    margin-right: 0.5rem;
  }
`;

export const StyledMenuItem = styled(MenuItem)<{ $active?: boolean }>`
  display: ${({ $active = true }) => ($active ? '' : 'none')};
  min-height: 3rem; // MUI 기본 값(px)을 rem으로 치환

  li {
    background: ${theme.palette.background.paper};
  }

  // MUI 기본 값(px)을 rem으로 치환
  &.MuiListItem-gutters {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  &.MuiMenuItem-root {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
`;

const ErrorSpan = styled.span`
  color: ${theme.palette.error.main};
  font-size: 0.75rem;
  line-height: 1.25rem;
  position: absolute;
  left: ${errorTextLeftMargin};
  bottom: -1.25rem;
`;
