/**
 * 기능 : 서비스 레이어에서 버전 정보를 호출하고 콘솔에 노출 합니다.
 */
// @ts-ignore
import { version as releaseVersion } from '../../../package.json';
export const welcomeToMeissa = () => {
  console.log(
    '🛸　　　 　🌎　°　　🌓　•　　.°•　　　🚀 ✯\n' +
      '　　　★　*　　　　　°　　　　🛰 　°·　　   🪐\n' +
      '.　　　•　° ★　•  ☄     .　　　•　° ★\n' +
      `▁▂▃▄▅▆▇▇▆▅▄▃▁▂\n%cMEISSA ${releaseVersion}`,
    'color:#1cce69; background: #3d09df; font-size: 1rem; padding: 0.15rem 0.25rem;',
  );
};
