import { lazy } from 'react';
const QuickShootingComparePage = lazy(
  () =>
    import(
      /* webpackChunkName: "QuickShootingComparePage" */
      'features/quickShooting/compare/components/QuickShootingComparePage'
    ),
);
function QuickShootingCompare() {
  return <QuickShootingComparePage />;
}

export default QuickShootingCompare;
