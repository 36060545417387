import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import { IconButton, Menu, MenuItem, SvgIcon, SvgIconProps, Typography } from '@mui/material';

import React from 'react';
import { nls } from 'shared/locale/language';
import { info } from 'shared/styles/colors/info';
import { others } from 'shared/styles/colors/others';
import theme from 'shared/styles/mui/theme';
import styled from 'styled-components';

interface Props {
  name: string;
  numberOfProjects: number;
  selected?: boolean;
  empty?: boolean;
  onClick?: () => void;
  onClickEdit?: () => void;
  onClickUngroup?: () => void;
  onClickDelete?: () => void;
}
export default function ProjectFolderItem({
  name,
  numberOfProjects,
  selected,
  empty = false,
  onClick,
  onClickEdit,
  onClickUngroup,
  onClickDelete,
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  function handleClick() {
    if (!open) {
      onClick();
    }
  }

  function handleClickMenu(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleEdit() {
    handleClose();
    onClickEdit?.();
  }

  function handleUngroup() {
    handleClose();
    onClickUngroup?.();
  }

  function handleDelete() {
    handleClose();
    onClickDelete?.();
  }

  return (
    <Container selected={selected} onClick={handleClick}>
      {empty ? <EmptyStartIcon /> : <StartIcon />}
      <ItemBody>
        <Typography variant="h6">{name}</Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {`${nls.projectItemNumberInfo()}: ${numberOfProjects}`}
        </Typography>
      </ItemBody>
      <StyledIconButton onClick={handleClickMenu}>
        <MoreHorizIcon />
      </StyledIconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledMenuItem onClick={handleEdit}>
          <EditIcon />
          <Typography variant="body1">{nls.basicEditName()}</Typography>
        </StyledMenuItem>
        {numberOfProjects === 0 ? (
          <StyledMenuItem onClick={handleDelete}>
            <DeleteIcon />
            <Typography variant="body1">{nls.delete()}</Typography>
          </StyledMenuItem>
        ) : (
          <StyledMenuItem onClick={handleUngroup}>
            <FolderOffIcon />
            <Typography variant="body1">{nls.projectFolderUngroupMenuTitle()}</Typography>
          </StyledMenuItem>
        )}
      </Menu>
    </Container>
  );
}

const Container = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem;
  gap: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid ${others.BORDER};
  background: ${theme.palette.background.paper};
  box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover:not(:has(button:hover)) {
    border-radius: 0.5rem;
    border: 1px solid ${others.BORDER};
    background: ${theme.palette.grey[300]};
  }
  ${({ selected }) =>
    selected &&
    `
    border-radius: 0.5rem;
    border: 1px solid ${theme.palette.primary.main};
    background: ${info.LIGHT_BG};
    &:hover:not(:has(button:hover)) {
      border-radius: 0.5rem;
      border: 1px solid ${theme.palette.primary.main};
      background: ${info.LIGHT_BG};
    }
  `}
`;

const StartIcon = styled(SvgIcon).attrs<SvgIconProps>({ component: FolderIcon })`
  &.MuiSvgIcon-root {
    width: 3rem;
    height: 3rem;
    color: ${theme.palette.text.secondary};
`;

const EmptyStartIcon = styled(SvgIcon).attrs<SvgIconProps>({ component: FolderIcon })`
  &.MuiSvgIcon-root {
    width: 3rem;
    height: 3rem;
    color: ${theme.palette.action.disabledBackground};
  }
`;

const ItemBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const StyledIconButton = styled(IconButton)`
  z-index: 1;
  &.MuiButtonBase-root {
    margin-left: auto;
    background-color: ${theme.palette.action.hover};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  .MuiSvgIcon-root {
    color: ${theme.palette.text.secondary};
  }
`;
