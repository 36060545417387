import React, { lazy } from 'react';
const GreenShortcutPage = lazy(
  () =>
    import(
      /* webpackChunkName: "GreenShortcutPage" */ 'features/green/shortcut/component/shortcutPage/GreenShortcutPage'
    ),
);

function GreenShortcut() {
  return <GreenShortcutPage />;
}

export default GreenShortcut;
