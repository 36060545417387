import { Box } from '@mui/material';
import SearchField from 'features/styleComponents/input/SearchField';
import { useEffect, useRef } from 'react';
import { nls } from 'shared/locale/language';
import styled from 'styled-components';
import { ProjectManagementTableItem } from '../../types';
import ProjectInfoTableRow from './ProjectInfoTableRow';

interface Props {
  data: ProjectManagementTableItem[];
  searchKeyword: string;
  onSearchKeywordChange: (keyword: string) => void;
  headerCheckboxStatus: 'all' | 'some' | 'none';
  onToggleHeaderCheckbox: () => void;
  isCheckedItem: (id: number) => boolean;
  onToggleItemCheckbox: (id: number, checked: boolean) => void;
}

const SESSION_PROJECT_INFOMATION_SCROLL_KEY = 'project-table-scroll-position';

export default function ProjectInformationTable({
  data,
  searchKeyword,
  onSearchKeywordChange,
  headerCheckboxStatus,
  onToggleHeaderCheckbox,
  isCheckedItem,
  onToggleItemCheckbox,
}: Props) {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem(SESSION_PROJECT_INFOMATION_SCROLL_KEY);
    if (savedScrollPosition && tableContainerRef.current) {
      tableContainerRef.current.scrollTop = Number(savedScrollPosition);
    }
  }, []);

  const handleScroll = () => {
    if (tableContainerRef.current) {
      sessionStorage.setItem(
        SESSION_PROJECT_INFOMATION_SCROLL_KEY,
        tableContainerRef.current.scrollTop.toString(),
      );
    }
  };

  return (
    <Container>
      <Box sx={{ maxWidth: '33.5rem', height: '2.5rem' }}>
        <SearchField
          variant="outlined"
          placeholder={nls.projectListSearchPlaceholder()}
          value={searchKeyword}
          onChange={(e) => onSearchKeywordChange(e.target.value)}
        />
      </Box>
      <TableContainer ref={tableContainerRef} onScroll={handleScroll}>
        <ProjectInfoTableRow
          header
          checked={headerCheckboxStatus !== 'none'}
          onToggleCheckbox={onToggleHeaderCheckbox}
          indeterminate={headerCheckboxStatus === 'some'}
        />
        {data.map((row) => (
          <ProjectInfoTableRow
            {...row}
            key={row.id}
            checked={isCheckedItem(row.id)}
            onToggleCheckbox={(c) => onToggleItemCheckbox(row.id, c)}
          />
        ))}
      </TableContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  height: calc(100vh - 16.5rem);
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;
