import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowRight from '@mui/icons-material/ArrowRight';
import FolderIcon from '@mui/icons-material/Folder';
import { Collapse } from '@mui/material';
import OutlinedChip from 'features/styleComponents/chip/OutlinedChip';
import React, { useState } from 'react';
import { nls } from 'shared/locale/language';
import theme from 'shared/styles/mui/theme';
import styled, { css } from 'styled-components';

interface Props {
  nodeId: number;
  icon?: React.JSX.Element;
  name: string;
  level: number;
  isRoot?: boolean;
  isCurrent?: boolean;
  selected?: boolean;
  disabled?: boolean;
  onSelect?: (id: number, name?: string) => void;
  children?: React.ReactNode[];
}

export default function FolderTreeItem({
  nodeId,
  isRoot = false,
  name,
  level,
  icon = null,
  isCurrent = false,
  disabled,
  selected,
  onSelect,
  children,
}: Props) {
  const [open, setOpen] = useState(isRoot);

  function handleSelect() {
    if (disabled || isCurrent) {
      onSelect?.(null, null);
    } else {
      onSelect?.(nodeId, name);
    }
  }

  function handleArrowClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setOpen(!open);
  }

  return (
    <Container key={nodeId}>
      <ListItem
        $selected={selected}
        $isCurrent={isCurrent}
        $disabled={disabled}
        $level={level}
        onClick={handleSelect}
      >
        {!isRoot && (
          <ArrowIconButton open={open} disabled={!children?.length} onClick={handleArrowClick} />
        )}
        <TreeItemStartIcon icon={icon} disabled={disabled} />
        <ListItemContent>
          <ListItemText $disabled={disabled}>{name}</ListItemText>
          {isCurrent && <OutlinedChip label={nls.folderCurrentLocation()} colorType="grey" />}
        </ListItemContent>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </Container>
  );
}

function ArrowIconButton({ open, disabled, onClick }) {
  return (
    <ArrowIconButtonContainer $disabled={disabled} onClick={onClick}>
      {open ? <ArrowDropDown /> : <ArrowRight />}
    </ArrowIconButtonContainer>
  );
}

function TreeItemStartIcon({ icon = null, disabled }) {
  return (
    <TreeItemStartIconContainer $disabled={disabled}>
      {icon || <FolderIcon />}
    </TreeItemStartIconContainer>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 2rem;
`;

const ListItem = styled.div<{
  $selected?: boolean;
  $isCurrent?: boolean;
  $disabled?: boolean;
  $level: number;
}>`
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 0.5rem;
  height: 2rem;
  font-size: 0.875rem;
  font-weight: 600;
  transition: all 0.2s ease;
  padding-left: ${({ $level }) => $level * 2}rem;

  &:hover {
    background-color: ${theme.palette.action.hover};
  }

  ${({ $selected }) =>
    $selected &&
    css`
      background-color: rgba(0, 172, 193, 0.1);
      &:hover {
        background-color: rgba(0, 172, 193, 0.1);
      }
    `}

  ${({ $disabled, $isCurrent }) =>
    ($disabled || $isCurrent) &&
    css`
      &:hover {
        background-color: transparent;
      }
    `}
  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: default;
    `}
`;

const ListItemContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 0.625rem;
`;

const ListItemText = styled.span<{ $disabled?: boolean }>`
  display: block;
  overflow: hidden;
  max-width: calc(100% - 7.25rem);
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${theme.palette.text.disabled};
    `}
`;

const ArrowIconButtonContainer = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.125rem;
  color: ${theme.palette.text.secondary};
  &:hover {
    background-color: ${theme.palette.action.hover};
  }
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${theme.palette.text.disabled};
      pointer-events: none;
      cursor: default;
    `}
`;

const TreeItemStartIconContainer = styled.div<{ $disabled?: boolean }>`
  .MuiSvgIcon-root {
    width: 1.25rem;
    height: 1.25rem;
    color: ${theme.palette.text.secondary};
    ${({ $disabled }) =>
      $disabled &&
      css`
        color: ${theme.palette.text.disabled};
      `}
  }
`;
