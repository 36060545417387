import { useEffect, useRef, useState } from 'react';

const useInfiniteScrolling = (scrollMotionThreshold = 100) => {
  const divRef = useRef(null);
  const [isOverLoadableHeight, setIsOverLoadableHeight] = useState(false);

  const onScrolling = () => {
    const { scrollHeight, scrollTop, clientHeight } = divRef.current;
    const totalHeight = scrollHeight - (scrollTop + clientHeight + scrollMotionThreshold);
    if (scrollTop > scrollMotionThreshold && totalHeight <= 0) {
      setIsOverLoadableHeight(true);
    } else {
      setIsOverLoadableHeight(false);
    }
  };

  useEffect(() => {
    if (!divRef?.current) return null;
    divRef.current.addEventListener('scroll', onScrolling);
    return () => {
      divRef.current?.removeEventListener('scroll', onScrolling);
    };
  }, []);

  return {
    divRef,
    isOverLoadableHeight,
  };
};

export default useInfiniteScrolling;
