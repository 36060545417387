import { useMutation } from 'react-query';
import { _ParticipantCreateApiRequestPayload } from '../types';
import api from './api';

export default function useParticipantMutation() {
  const createParticiantMutation = useMutation((payload: _ParticipantCreateApiRequestPayload) =>
    api.participant.create(payload),
  );

  return { createParticiantMutation };
}
