import PersonIcon from '@mui/icons-material/Person';
import SettingIcon from '@mui/icons-material/Settings';
import { Box, ListItemIcon, MenuItem, MenuList, Typography } from '@mui/material';
import routes from 'pages/routes';
import React from 'react';
import { useHistory } from 'react-router';
import { nls } from 'shared/locale/language';
import theme from 'shared/styles/mui/theme';
import styled from 'styled-components';

export default function SettingLeftPanel() {
  const history = useHistory();

  function isSelected(key: 'project' | 'my-info') {
    return history.location.pathname.includes(key);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '15rem',
        minWidth: '15rem',
        height: '100%',
        borderRight: `1px solid ${theme.palette.divider}`,
        boxSizing: 'border-box',
        padding: '1rem 0',
      }}
    >
      <MenuList>
        <StyledMenuItem
          selected={isSelected('project')}
          onClick={() => history.push(routes.setting.project.path)}
        >
          <ListItemIcon color="inherit">
            <SettingIcon color="inherit" />
          </ListItemIcon>
          <Typography variant="button" color="inherit">
            {nls.projectInfo()}
          </Typography>
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => history.push(routes.setting.myInfo.path)}
          selected={isSelected('my-info')}
        >
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <Typography variant="button" color="inherit">
            {nls.myInfo()}
          </Typography>
        </StyledMenuItem>
      </MenuList>
    </Box>
  );
}

const StyledMenuItem = styled(MenuItem)`
  height: 3rem;
  &.MuiMenuItem-root {
    color: ${theme.palette.text.secondary};
    padding-left: 2.5rem;
  }
  &.Mui-selected {
    color: ${theme.palette.primary.main};
    background-color: transparent;
    border-right: 2px solid ${theme.palette.primary.main};
    box-sizing: border-box;
  }
  &.MuiMenuItem-root.Mui-selected {
    background-color: transparent;
  }
  > .MuiListItemIcon-root {
    color: inherit;
  }
`;
