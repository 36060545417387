import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ContainedButton } from 'features/common/button/ContainedButton';
import React, { useState } from 'react';
import { MOBILE_MAX_WIDTH } from 'shared/common/policies/screen';
import GA from 'shared/ga';
import { PROJECT_SORT } from 'shared/ga/actions/project';
import { PROJECT } from 'shared/ga/category';
import styled from 'styled-components';
import { SortOrder, sortOrderTypes } from '../../operator/SortOrder';

interface Props {
  sortOrder: SortOrder;
  setSortOrder: (sortOrder: SortOrder) => void;
}

export default function ProjectListSort({ sortOrder, setSortOrder }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleSortButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSortMenuClick = (sortOrder: SortOrder) => {
    setAnchorEl(null);
    setSortOrder(sortOrder);
    GA.event(PROJECT, PROJECT_SORT);
  };

  const handleSortMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <SortButton
        color="inherit"
        size="large"
        endIcon={<ArrowDropDown />}
        onClick={handleSortButtonClick}
      >
        {sortOrder.readableLabel}
      </SortButton>
      <Menu
        open={open}
        onClose={handleSortMenuClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {Object.entries(sortOrderTypes).map(([key, sort]: [string, SortOrder]) => (
          <MenuItem
            key={key}
            selected={sort === sortOrder}
            onClick={() => handleSortMenuClick(sort)}
          >
            {sort.readableLabel}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

const SortButton = styled(ContainedButton)`
  .MuiSvgIcon-root {
    margin-left: 0.2rem;
  }

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    display: none;
  }
`;
