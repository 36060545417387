import { css } from 'styled-components';

export const SCROLLBAR_TRACK_COLOR = 'rgba(255, 255, 255, 0.5)';
export const SCROLLBAR_THUMB_COLOR = 'rgba(0, 0, 0, 0.23)';

export const scrollStyle = css`
  scrollbar-gutter: stable;
  overflow-y: hidden;

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &:hover {
    overflow-y: auto;

    ::-webkit-scrollbar-thumb {
      background-color: ${SCROLLBAR_THUMB_COLOR};
    }
    ::-webkit-scrollbar-track {
      background-color: ${SCROLLBAR_TRACK_COLOR};
    }
  }
`;
