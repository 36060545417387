import Send from '@mui/icons-material/Send';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TextButton from 'features/common/button/TextButton';
import useGlobalDialog from 'features/common/hooks/useGlobalDialog';
import Select from 'features/common/select/Select';
import { useEffect, useState } from 'react';
import { canManipulateOtherUser } from 'shared/common/policies/permission';
import { memberPermission, PermissionType } from 'shared/common/types';
import { convertDateFormat } from 'shared/common/utils/date';
import { nls } from 'shared/locale/language';
import { useParticipantMutation } from 'shared/query/participant/useParticipantQuery';
import { useProjectInviteeMutation } from 'shared/query/projectInvitee/useProjectInviteeQuery';
import { useProjectUserMutation } from 'shared/query/projectUser/useProjectUserQuery';
import theme from 'shared/styles/mui/theme';
import { ProjectUser } from 'stores/data/types';
import styled from 'styled-components';
import BaseTableRow from './StyledTableRow';

interface Props {
  projectId: number;
  data: ProjectUser;
  deleting: boolean;
  invitee: boolean;
  checked: boolean;
  onCheckedChange: (id: number, selected: boolean) => void;
  hasSendMailPermission?: boolean;
}

const RESEND_MINIMUM_INTERVAL_IN_SECONDS = 60;

export default function MemberRow({
  projectId,
  data,
  deleting,
  invitee,
  checked,
  onCheckedChange,
  hasSendMailPermission,
}: Props) {
  const { showAlert, showConfirm } = useGlobalDialog();
  const { update: updateProjectInvitee } = useProjectInviteeMutation(data.projectId, data.id);
  const { update: updateProjectUser } = useProjectUserMutation(data.projectId, data.id);
  const { resendInvitation } = useParticipantMutation();
  const [value, setValue] = useState<PermissionType>(memberPermission[data.permission]?.value);
  const [resendRequestedAt, setResendRequestedAt] = useState<Date>(null);
  const [resendStatusDisplayed, setResendStatusDisplayed] = useState(true);

  useEffect(() => {
    if (resendStatusDisplayed) {
      return;
    }

    if (resendInvitation.isSuccess) {
      showAlert({ content: nls.resendInvitationSuccess() });
      setResendStatusDisplayed(true);
    } else if (resendInvitation.isError) {
      showConfirm({
        title: nls.resendInvitationErrorTitle(),
        content: nls.resendInvitationErrorMessage(),
        primaryButtonProps: {
          title: nls.retry(),
          onClick: () => {
            resendInvitation.mutate({ projectId, inviteeId: data.id });
            setResendStatusDisplayed(false);
          },
        },
      });
      setResendStatusDisplayed(true);
    }
  }, [resendInvitation.isError, resendInvitation.isLoading, resendInvitation.isSuccess]);

  function onSelectChange(e) {
    const newPermission: typeof memberPermission.OWNER = memberPermission[e.target.value];
    showConfirm({
      title: nls.changeGradeTitle(),
      content: nls.changeGrade(newPermission.title),
      primaryButtonProps: {
        title: nls.confirm(),
        onClick: () => updateGrade(newPermission.value),
      },
    });
  }
  function onResendClick() {
    const diffInSeconds = (new Date().valueOf() - resendRequestedAt?.valueOf()) / 1000;
    if (diffInSeconds < RESEND_MINIMUM_INTERVAL_IN_SECONDS) {
      showAlert({ content: nls.resendInvitationBlocked() });
      return;
    }
    resendInvitation.mutate({ projectId, inviteeId: data.id });
    setResendRequestedAt(new Date());
    setResendStatusDisplayed(false);
  }

  function updateGrade(x: PermissionType) {
    const successCb = () => {
      setValue(x);
    };
    const payload = { permission: x };
    if (invitee) {
      updateProjectInvitee.mutate(payload, { onSuccess: successCb });
    } else {
      updateProjectUser.mutate(payload, { onSuccess: successCb });
    }
  }

  const disableCheckbox = !canManipulateOtherUser(data);
  const showSelect = data.permissionChangeOptions?.length > 0;
  const disableSelect = deleting;
  const showCheckbox = deleting;
  const showResendButton = invitee && hasSendMailPermission;
  const disableResendButton = deleting;
  const permissionCellContent = showSelect ? (
    <StyledSelect
      value={value}
      onChange={onSelectChange}
      options={data.permissionChangeOptions
        .filter((x) => memberPermission[x.permission])
        .map((x) => ({
          value: memberPermission[x.permission].value,
          name: memberPermission[x.permission].title,
        }))}
      disabled={disableSelect}
    />
  ) : (
    <PrimaryCellContent>{memberPermission[data.permission]?.title}</PrimaryCellContent>
  );
  const localizedDateExpression = convertDateFormat(data.dateCreate);

  const tableCellWithCheckbox = (
    <TableCell>
      <StyledCheckbox
        $visible={showCheckbox}
        checked={checked}
        disabled={disableCheckbox}
        onChange={(e) => onCheckedChange(data.id, e.target.checked)}
        color="secondary"
        data-testid="member-delete-checkbox"
      />
    </TableCell>
  );

  if (invitee) {
    return (
      <StyledTableRow key={data.id} $checked={checked} $deleting={deleting}>
        {tableCellWithCheckbox}
        <TableCell>
          <PrimaryCellContent color="textSecondary">{`(${nls.invited()})`}</PrimaryCellContent>
        </TableCell>
        <TableCell>
          <SecondaryCellContent>{data.email}</SecondaryCellContent>
        </TableCell>
        <TableCell />
        <TableCell>{permissionCellContent}</TableCell>
        <TableCell>
          <PrimaryCellContent>{nls.invited()}</PrimaryCellContent>
          <SecondaryCellContent>
            {nls.invitationTimestamp(localizedDateExpression)}
          </SecondaryCellContent>
        </TableCell>
        <TableCell>
          {showResendButton && (
            <ResendButton
              startIcon={<Send />}
              color="primary"
              disabled={disableResendButton}
              onClick={onResendClick}
            >
              {nls.resendInvitation()}
            </ResendButton>
          )}
        </TableCell>
      </StyledTableRow>
    );
  }

  return (
    <StyledTableRow key={data.id} $checked={checked} $deleting={deleting}>
      {tableCellWithCheckbox}
      <TableCell>
        <PrimaryCellContent>{data.user?.company}</PrimaryCellContent>
      </TableCell>
      <TableCell>
        <PrimaryCellContent>{data.user?.fullName}</PrimaryCellContent>
        <SecondaryCellContent>{data.user?.email}</SecondaryCellContent>
      </TableCell>
      <TableCell>
        <PrimaryCellContent>{data.user?.jobTitle || '‐'}</PrimaryCellContent>
      </TableCell>
      <TableCell>{permissionCellContent}</TableCell>
      <TableCell>
        <PrimaryCellContent>{nls.joinStatus()}</PrimaryCellContent>
        <SecondaryCellContent>{nls.joinTimestamp(localizedDateExpression)}</SecondaryCellContent>
      </TableCell>
      <TableCell />
    </StyledTableRow>
  );
}

const StyledTableRow = styled(BaseTableRow)<{ $checked: boolean; $deleting: boolean }>`
  td {
    background: none;
    position: relative;
  }

  td::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.08;
    background: ${({ $checked }) => ($checked ? theme.palette.error.main : 'none')};
  }

  &:hover td::before {
    background: ${theme.palette.action.hover};
    opacity: ${({ $deleting }) => ($deleting ? 1 : 0)};
  }

  .MuiSelect-root {
    background: transparent;
  }
`;

const PrimaryCellContent = styled(Typography)``;

const SecondaryCellContent = styled(Typography).attrs({
  variant: 'body2',
  color: 'textSecondary',
})``;

const StyledCheckbox = styled(Checkbox)<{ $visible: boolean }>`
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
`;

const StyledSelect = styled(Select)`
  width: 10rem;
`;

const ResendButton = styled(TextButton)`
  min-width: 7.5rem;
`;
