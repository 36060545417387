import { useURLQueryParams } from 'features/common/hooks';
import Select, { StyledMenuItem } from 'features/common/select/Select';
import GA from 'shared/ga';
import { TWO_D_CURRENT_CCTV_ANOTHER_CCTV } from 'shared/ga/actions/twoDCurrent';
import { TWO_D_CURRENT } from 'shared/ga/category';
import { nls } from 'shared/locale/language';
import { Cctv, getCctvUrl } from 'shared/query/cctv/types';
import React, { useState } from 'react';
import styled from 'styled-components';
import palette from 'shared/styles/mui/palette';
import { hexToRgba } from 'shared/styles/colors/utils';

interface Props {
  cctvId: number | undefined;
  cctvList: Cctv[];
  onFocusedCctvChange: (cctvId: number) => void;
}
export default function CctvPlayer({ cctvId: initialId, cctvList, onFocusedCctvChange }: Props) {
  const [cctvId, setCctvId] = useState(initialId);
  const options = (cctvList || [])?.map((x) => ({ value: x?.id, name: x?.name }));
  const queryParams = useURLQueryParams();
  const partCount = queryParams.get('part');

  const handleChange = (e) => {
    const targetCctvId = +e.target?.value;
    setCctvId(targetCctvId);
    onFocusedCctvChange?.(targetCctvId);
    GA.event(TWO_D_CURRENT, TWO_D_CURRENT_CCTV_ANOTHER_CCTV, partCount);
  };

  const selectedCctv = cctvId && cctvList?.find((x) => x?.id === cctvId);
  return (
    <>
      <SelectWrapper>
        <Select
          className="dark-style"
          value={cctvId ?? initialId}
          onChange={handleChange}
          options={options}
          placeholder={nls.cctvPlayerSelectPlaceholder()}
          CustomMenuItem={CustomMenuItem}
          MenuProps={SelectMenuProps}
        />
      </SelectWrapper>
      {selectedCctv && (
        <StyledIframe
          src={getCctvUrl(selectedCctv?.clientStreamUrl)}
          title={selectedCctv?.name}
          width="100%"
          height="100%"
          scrolling="no"
        />
      )}
    </>
  );
}

const SelectWrapper = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 1000;
  min-width: 14.25rem;

  .MuiSelect-outlined,
  .MuiSelect-outlined svg {
    background-color: ${palette.text.primary};
    color: ${palette.primary.contrastText};
  }
`;

const StyledIframe = styled.iframe`
  display: flex;
  flex: 1;
  border: none;
`;
const CustomMenuItem = styled(StyledMenuItem)<{ $special: boolean }>`
  background-color: ${palette.text.primary};
  color: ${palette.primary.contrastText};

  &.MuiMenuItem-root:hover {
    background-color: ${hexToRgba(palette.primary.contrastText, 0.05)} !important;
  }
  &.Mui-selected,
  &.Mui-selected.Mui-focusVisible {
    background-color: ${hexToRgba(palette.primary.contrastText, 0.1)} !important;
  }
`;
const SelectMenuProps = {
  MenuListProps: { style: { backgroundColor: palette.text.primary } },
};
