import NotificationsIcon from '@mui/icons-material/Notifications';
import { SvgIcon, SvgIconProps } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import { nls } from 'shared/locale/language';
import theme from 'shared/styles/mui/theme';
import styled from 'styled-components';

interface Props {
  open: boolean;
  onClose: (e: any, reason: any) => void;
}

export default function AlertSnackbar({ open, onClose }: Props) {
  return (
    <StyledSnackbar
      autoHideDuration={3000}
      open={open}
      onClose={onClose}
      message={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <StyledSnackbarMessage>
          <StyledSnackbarIcon />
          <StyledSnackbarText>{nls.noticeDontShowAgainMessage()}</StyledSnackbarText>
        </StyledSnackbarMessage>
      }
    />
  );
}

const StyledSnackbar = styled(Snackbar).attrs({
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
})`
  .MuiSnackbarContent-root {
    padding: 0;
    background-color: ${theme.palette.grey[800]};
    box-shadow: ${theme.shadows[6]};
  }
  .MuiSnackbarContent-message {
    padding: 0.375rem 1rem;
    width: 100%;
  }
`;

const StyledSnackbarMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.375rem;
`;

const StyledSnackbarIcon = styled(SvgIcon).attrs<SvgIconProps>({ component: NotificationsIcon })`
  font-size: 1.125rem;
`;

const StyledSnackbarText = styled(Typography).attrs({ variant: 'body2' })`
  padding: 0.5rem 0;
  color: ${theme.palette.primary.contrastText};
`;
