import area from '@turf/area';
import { lineString } from '@turf/helpers';
import length from '@turf/length';
import { getDialogButton } from 'features/common/button/TextButton';
import { History } from 'history';
import camelCase from 'lodash/camelCase';
import orderBy from 'lodash/orderBy';
import snakeCase from 'lodash/snakeCase';
import routes from 'pages/routes';
import { datumList, ErrorMessageCode, Extension, INVALID_ADDR } from 'shared/common/types';
import { nls } from 'shared/locale/language';
import { AerialImage } from 'shared/query/aerialImage/types';
import { LoginUser, TokenObj } from 'stores/data/types';
import { DEFAULT_UNIT } from './policies/project';
import format from 'date-fns/format';

/* private function */
function getJsonToBase64(str: string) {
  let result;

  if (str === '') {
    result = {};
  }

  try {
    result = JSON.parse(atob(str));
  } catch (e) {
    result = {};
  }

  return result;
}

function getBase64ToJson(jsonObj): string {
  return btoa(JSON.stringify(jsonObj));
}

let isLock = false;
let leafletMap;

export const lock = () => {
  isLock = true;
};

export const unlock = () => {
  isLock = false;
};

export const isTokenRefreshing = (): boolean => {
  return isLock;
};

export const deferToDispatch = (callback, arg?: any): void => {
  const param = arg || null;

  if (isTokenRefreshing()) {
    setTimeout(() => {
      deferToDispatch(callback, param);
    }, 500);
  } else if (getJWTToken()) {
    callback(param);
  }
};

export function setRequestTime(time: number): void {
  localStorage.setItem('reqTime', `${time}`);
}

export function getRequestTime(): number {
  return +localStorage.getItem('reqTime');
}

export function needToRefreshToken(): boolean {
  const curTime = new Date().getTime();
  const requestTime = getRequestTime();
  const validateIn = +getValidateInTime();
  let result = true;
  // 토큰 유효 시간이 절반 이하로 남았을 경우 token 갱신
  if ((curTime - requestTime) / 1000 < validateIn / 2) {
    result = !result;
  }

  return result;
}

export function setUserInfoToStorage(userInfo: Omit<LoginUser, 'validateIn'>): void {
  localStorage.setItem('user', getBase64ToJson(userInfo));
}

export function getUserInfoToStorage(): LoginUser {
  return getJsonToBase64(localStorage.getItem('user'));
}

export function removeUserInfoToStorage(): void {
  localStorage.removeItem('user');
  localStorage.removeItem('reqTime');
  localStorage.removeItem('validateIn');
}

export function setValidateInTime(validateIn?: number): void {
  localStorage.setItem('validateIn', `${validateIn || 50}`);
}

export function getValidateInTime(): string | null {
  return localStorage.getItem('validateIn');
}

export function modifyJwtToken(jwtTokenObj: TokenObj): void {
  const curUserInfo = getUserInfoToStorage();
  const curAccessToken = curUserInfo.access;
  const curRefreshToken = curUserInfo.refresh;
  const { access, refresh, validateIn } = jwtTokenObj;

  if (access == null || refresh == null) {
    return;
  }

  if (curAccessToken != null && curAccessToken !== access && curRefreshToken !== refresh) {
    curUserInfo.access = access;
    curUserInfo.refresh = refresh;
    setUserInfoToStorage(curUserInfo);
    setValidateInTime(validateIn);
  }
}

export function getJWTToken(): string {
  const userInfo = getUserInfoToStorage();

  return userInfo && userInfo.access;
}

export function getRefreshToken(): string {
  const userInfo = getUserInfoToStorage();

  return userInfo.refresh;
}

export function isLogin(): boolean {
  return !!getJWTToken();
}

export function queryParser(query) {
  const parseQuery = query.split('?');
  const parseQueryList = (parseQuery.length > 1 && parseQuery[1].split('&')) || [];
  const len = parseQueryList.length;

  let result;

  if (len === 0) {
    result = null;
  } else {
    result = {};

    parseQueryList.forEach((value) => {
      const parseVal = value.split('=');

      result[parseVal[0]] = parseVal[1];
    });
  }

  return result;
}

export function isLastIndexCached(array: any[]) {
  const cachedArray = array || [];
  const lastIndex = cachedArray.length - 1;
  return (currentIndex: number): boolean => {
    return lastIndex === currentIndex;
  };
}

export function isValidFileExtension(file: File, extension: Extension) {
  return !!file.name && !!file.name.toLowerCase().match(extension);
}

export const rgbToHex = (rgbType: string): string => {
  if (rgbType === '' || rgbType == null) {
    return '';
  }

  const rgb = rgbType.replace(/[^%,.\d]/g, '');
  const rgbList = rgb && rgb.split(',');

  const toHex = (str) => {
    const convertStr = parseInt(str, 10).toString(16);
    return convertStr.length === 1 ? `0${convertStr}` : convertStr;
  };

  return `#${toHex(rgbList[0]) + toHex(rgbList[1]) + toHex(rgbList[2])}`;
};

export const measureLength = (x) => Math.round(length(x) * 100000) / 100;

export const measureArea = (x) => Math.round(area(x) * 100) / 100;

export const getVolumeUnit = (
  value: number | null,
  coefficient: number = DEFAULT_UNIT.VOLUME.COEFFICIENT,
  unit: string = DEFAULT_UNIT.VOLUME.NAME,
): string => {
  if (value == null) {
    return '-';
  }

  return `${(value * coefficient)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}${unit}`;
};

export const setLeafletMap = (map) => {
  leafletMap = map;
};

export const getLeafletMap = () => {
  return leafletMap;
};

function convertKeys(o: any, caseType: 'camel' | 'snake') {
  const convertMethod = caseType === 'camel' ? convertKeysToCamelCase : convertKeysToSnakeCase;

  if (o == null) {
    return o;
  }
  if (Array.isArray(o)) {
    return o.map(convertMethod);
  }
  return typeof o !== 'object'
    ? o
    : Object.keys(o).reduce((prev, current) => {
        const newKey = caseType === 'camel' ? camelCase(current) : snakeCase(current);
        // 파이썬 식별자 규칙인 영문, 숫자, 언더바(_)만 허용
        if (!/^[a-zA-Z0-9_]+$/.test(current) || current === 'formData') {
          // formData는 변형하지 않도록 수정
          prev[current] = o[current];
        } else if (typeof o[current] === 'object') {
          prev[newKey] = convertMethod(o[current]);
        } else {
          prev[newKey] = o[current];
        }
        return prev;
      }, {});
}

export function convertKeysToCamelCase(o) {
  return convertKeys(o, 'camel');
}

export function convertKeysToSnakeCase(o) {
  return convertKeys(o, 'snake');
}

export function verifyEmail(email: string): boolean {
  const emailRegex =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  const result = emailRegex.test(email);

  return result;
}

/**
 * @deprecated `useRequestWrapper`로 대체
 */
export function apiCallBack(
  history: History,
  dialog: { hideDialog: () => void },
  showAlert: (msg: string, button: React.JSX.Element) => void,
  dispatch: { user: { refresh: (f: (isExpired: boolean, errorMsgCode: string) => void) => void } },
  method: (x?: any) => void,
  parameter?,
) {
  const confirmBtnClicked = () => {
    history.push(routes.login.path, { from: history.location });
    dialog.hideDialog();
  };
  const failCb = (isExpired: boolean, errorMsgCode: string) => {
    // 혹시라도 param 이 다른 것이 넘어오더라도 true 여야만 token expired 된 것으로 판단
    if (isExpired) {
      removeUserInfoToStorage();

      const msg = (errorMsgCode && nls(errorMsgCode)) || nls(ErrorMessageCode.AUTH_JWT_TOKEN);
      const childButton = getDialogButton(confirmBtnClicked, nls.confirm(), 'primary');

      showAlert(msg, childButton);
    } else {
      history.push('/');
    }
  };

  if (needToRefreshToken()) {
    if (!isTokenRefreshing()) {
      dispatch.user.refresh(failCb);
    }
    deferToDispatch(method, parameter);
  } else {
    parameter ? method(parameter) : method();
  }
}

export function getTargetUrl(pathname: string) {
  // 지도도구 조회시 404 오류 발생한 경우 현장상황 페이지로 이동
  const annotationRegex = new RegExp(
    /\/projects\/\d+\/zones\/\d+\/snapshots\/\d+\/annotations\/(polygon|polyline|point)\/\d+/,
  );
  if (annotationRegex.test(pathname)) {
    const chunkedUrl = pathname.split('/annotations');
    return chunkedUrl[0];
  }
  return '/';
}

export function hasProperty(obj): boolean {
  return obj && Object.keys(obj).length > 0;
}

export function getObjectKeyList(obj) {
  return (obj && Object.keys(obj)) || [];
}

export function isInvalidTmsData(tmsInfo) {
  return !tmsInfo || !tmsInfo.url || tmsInfo.url === INVALID_ADDR;
}

export function getTmsUrlWithToken(tmsInfo, suffix) {
  if (!tmsInfo || !suffix) {
    return '';
  }

  const { url, token } = tmsInfo;

  return url + suffix + (token ? `?${token}` : '');
}

export function getTmsInfo(snapshotFiles, id = null) {
  if (!snapshotFiles) {
    return null;
  }

  const snapshotFile = !id
    ? snapshotFiles[0]
    : snapshotFiles?.find((file) => {
        return file.id === +id;
      });

  if (snapshotFile) {
    return {
      url: snapshotFile?.tmsUrl || '',
      token: snapshotFile?.tmsUrlToken || '',
      meta: snapshotFile?.data?.tmsMeta || null,
    };
  }
  return null;
}

/**
 * string "0" 을 포함한 숫자를 체크하는 함수
 * @param {number|string} data
 *
 * @return boolean
 */
export function isNumber(data) {
  return data && !isNaN(data);
}

/**
 * 현재 마우스 위치 구하기
 * @param e
 * @param targetImageRef
 * @return {xRatio: 마우스 x 위치 / 전체 이미지 너비, yRatio: 마우스 y 위치 / 전체 이미지 높이}
 */
export function getMousePosition(e: MouseEvent, targetImageRef: React.MutableRefObject<any>) {
  const rect = targetImageRef.current && targetImageRef.current.getBoundingClientRect();

  const { width, height } = targetImageRef.current;
  const x = e.pageX - rect.left - window.pageXOffset;
  const y = e.pageY - rect.top - window.pageYOffset;

  return {
    xRatio: x / width,
    yRatio: y / height,
  };
}

export const getCurrentOffset = (event, targetImageRef, imageSize) => {
  const parentDiv = targetImageRef.current && targetImageRef.current.parentNode;
  const { left: frameLeft, top: frameTop } = parentDiv && parentDiv.getBoundingClientRect();

  // 기준이 되는 offset을 구함
  const frameX = event.pageX - frameLeft - window.pageXOffset;
  const frameY = event.pageY - frameTop - window.pageXOffset;
  const xFrameOffset = (frameX / imageSize?.width) * 100;
  const yFrameOffset = (frameY / imageSize?.height) * 100;

  return { xFrameOffset, yFrameOffset };
};

/**
 * 마우스 위치를 기준으로 zoom in, zoom out 된 이미지의 위치(left,top) 계산
 * @param event
 * @param targetImageRef
 * @param imageSize
 * @param scale
 * @param mouseRatio
 */
export function calculateLeftTop(event, targetImageRef, imageSize, scale, { xRatio, yRatio }) {
  const { xFrameOffset, yFrameOffset } = getCurrentOffset(event, targetImageRef, imageSize);
  const left = -xRatio * 100 + xFrameOffset / scale;
  const top = -yRatio * 100 + yFrameOffset / scale;
  return { left, top };
}

/**
 * styled component 내부에 삽입하였을때 너무 자주 갱신된다는 warning 발생으로
 * inline style로 지정.
 * @param imagePosition
 */
export function getImageStyled(imagePosition: { left: number; top: number }) {
  return {
    left: `${imagePosition.left}%`,
    top: `${imagePosition.top}%`,
  };
}

/**
 * file size가 제한 크기 이하인지 확인
 *
 * @param file - file object
 * @param limit - 체크할 용량 (mb) 보내줌. ex. 50mb 체크 -> limit: 50
 *
 * @return {boolean} file size가 제한값 이하라면 true
 */
export function checkFileSize(file: File, limit: number) {
  const limitSize = limit * 1024 * 1024; // limit(MB)
  const fileSize = file.size;
  return fileSize <= limitSize;
}

/** 예기치 않은 오류가 발생했습니다.\n다시 시도해주세요.*/
export const defaultErrorMessage = nls(ErrorMessageCode.SERVER_FAIL_CODE);

/**
 * zoom 배율 계산
 * @param scale
 * @param deltaY
 * @return 2배로 늘어남 1-> 2 -> 4
 *
 */
export function calculateScale(scale: number, deltaY: number, minZoom = 1, maxZoom = 16) {
  const delta = deltaY > 0 ? -1 : 1;
  let tempScale = scale * 2 ** delta;
  tempScale = Math.min(Math.max(minZoom, tempScale), maxZoom);

  return tempScale;
}

export function getEpsgInfo(coordinate: string) {
  const coordinatesInfo = datumList.find((item) => item.value === coordinate);
  if (coordinatesInfo) {
    return {
      name: coordinatesInfo.desc,
      value: coordinatesInfo.value,
    };
  }
  return {
    name: `EPSG ${coordinate}`,
    value: coordinate,
  };
}

export function getPositionFromExif(
  {
    gpsLongitude,
    gpsLatitude,
    gpsLongitudeRef = 'E',
    gpsLatitudeRef = 'N',
  }: AerialImage['gpsInfo'] = {} as AerialImage['gpsInfo'],
) {
  // 실섭에서 gpsLongitudeRef, gpsLatitudeRef가 없는 경우가 있어서 초기값 추가
  if (!gpsLongitude || !gpsLatitude) return;
  const lng = convertDMSToDD(gpsLongitude, gpsLongitudeRef);
  const lat = convertDMSToDD(gpsLatitude, gpsLatitudeRef);

  return { lng, lat };
}

export function convertDMSToDD([degrees, minutes, seconds], direction): number {
  let dd = degrees + minutes / 60 + seconds / (60 * 60);
  if (direction == 'S' || direction == 'W') {
    dd *= -1;
  } // Don't do anything for N or E
  return dd;
}

/**
 * 이미지의 gps 정보와 기준점의 거리를 계산해 list안에 삽입
 * @param aerialImages
 * @param basePosition
 * @returns
 */
export function calculateLength(aerialImages: AerialImage[], basePosition: number[]) {
  return aerialImages.map((image) => {
    const { gpsInfo } = image;
    const { lat, lng } = (gpsInfo && getPositionFromExif(gpsInfo)) || {};

    if (!lat || !lng) return { ...image, length: Infinity };

    const line = lineString([[+lng, +lat], basePosition]);
    return { ...image, length: measureLength(line) };
  });
}

/**
 * basePosition : [longitude, latitude]
 */
export function orderByDistanceFromPoint(
  aerialImages: AerialImage[],
  basePosition: [number, number],
) {
  const imageLengthList = calculateLength(aerialImages, basePosition);
  return orderBy(imageLengthList, ['length'], ['asc']);
}

export function deepEqual(a, b) {
  if (typeof a !== typeof b) {
    return false;
  }
  if (typeof a !== 'object') {
    return a === b;
  }
  if (!a) {
    return !b;
  }
  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);
  return (
    aKeys.length === bKeys.length &&
    aKeys.every((key) => {
      return key in b && deepEqual(a[key], b[key]);
    })
  );
}

export function isTouchDevice() {
  // https://stackoverflow.com/questions/56324813/how-to-detect-touch-device-in-2019
  return matchMedia('(hover: none), (pointer: coarse)').matches;
}

/**
 * data 는 객체 {}, 또는 배열 [] 형태로 구성되어 있는 key-value 객체로 구성 됩니다.
 * ex) {key1: value1, key2: value2} 또는 [{key1: value1, key2: value2}]
 * 이러한 객체의 모든 value 값들이 null 일 경우를 판정하는 함수 입니다.
 * @param data
 */
export function isAllValuesNull(data: any) {
  if (typeof data === 'object' && data !== null) {
    return Object.values(data).every((value) => isAllValuesNull(value));
  } else if (Array.isArray(data)) {
    return data.every((item) => isAllValuesNull(item));
  } else {
    return !Boolean(data);
  }
}

/**
 * 파일 다운로드를 위한 함수
 * @param url
 * @param fileName
 */
export async function downloadFileWithCustomName(url: string, fileName: string) {
  try {
    const fileDownloadUrl = `${url}?date=${format(new Date(), 'yyyyMMddHHmmss')}`;
    const response = await fetch(fileDownloadUrl);
    // 원본 파일에서 확장자 추출
    const originalExtension = url.split('.').pop();
    // 파일 이름에서 확장자가 있는지 여부 확인
    const isExistExtension = Boolean(fileName.includes('.'));

    // 파일 확장자가 있는 경우 확장자 제거
    if (isExistExtension) {
      fileName = fileName.split('.').slice(0, -1).join('.');
    }
    // 파일 확장자 이름에 추가
    fileName += `.${originalExtension}`;

    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Download failed', error);
  }
}

export function convertLocationSearchToCamelCaseObject(search: string) {
  return convertKeysToCamelCase(
    search
      .slice(1)
      .split('&')
      .map((str) => str.split('='))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
  );
}

/**
 * Client level에서 중복되지 않는 이름을 생성합니다.
 * @param defaultName : ex) '새 폴더'
 * @param existNames : 중복을 확인할 이름 목록
 */
export function createUniqueName(defaultName: string, existNames: string[]): string {
  const existNameSet = new Set(existNames);
  let newName = defaultName;
  let count = 1;
  while (existNameSet.has(newName)) {
    newName = `${defaultName} (${count})`;
    count += 1;
  }
  return newName;
}
