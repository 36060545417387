import FolderIcon from '@mui/icons-material/Folder';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import LaunchIcon from '@mui/icons-material/Launch';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import Badge from '@mui/material/Badge';
import router from 'pages/routes';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { isGreen } from 'shared/common/customize';
import { getGnbLogo } from 'shared/common/customize/design';
import { navigationBarWidth } from 'shared/common/policies/sizes';
import { GREEN_MANUAL_URLS, MANUAL_URLS } from 'shared/common/policies/urls';
import { removeUserInfoToStorage } from 'shared/common/utils';
import { isDevWithStaging } from 'shared/common/utils/env';
import { sendProjectOption } from 'shared/ga/actions';
import { getLanguage, nls } from 'shared/locale/language';
import { useProjectQuery } from 'shared/query';
import { useNoticeQuery } from 'shared/query/notice/useNoticeQuery';
import { action } from 'shared/styles/colors/action';
import { COLOR_CHIP } from 'shared/styles/colors/symbols';
import { text } from 'shared/styles/colors/text';
import theme from 'shared/styles/mui/theme';
import styled, { css } from 'styled-components';
import { useMatchParams } from './hooks';
import useGlobalDialog from './hooks/useGlobalDialog';

const NOTICE_URL =
  getLanguage() === 'ko' ? 'https://meissa.oopy.io' : 'https://enmeissa.oopy.io/notice';

const DEV_MODES = ['TEST', 'GREEN', 'PROD'] as const;
type DevMode = typeof DEV_MODES[number];
const removeDisabledNotices = () => {
  Object.keys(localStorage)
    .filter((key) => key.startsWith('noticeDisable-'))
    .map((key) => localStorage.removeItem(key));
};

function NavigationBar({
  fixed = false,
  className: extraClassName = '',
  isErrorPage = false,
  onProjectClick = null,
}) {
  const history = useHistory();
  const { showConfirm } = useGlobalDialog();

  const notices = useNoticeQuery().data || [];
  const [hasNotices, setHasNotices] = useState(localStorage.getItem('hasNotices') === 'true');
  const { projectId } = useMatchParams();
  const projectName = useProjectQuery(projectId)?.data?.name;

  function onClickLogout() {
    const logout = () => {
      removeUserInfoToStorage();
      history.push(router.login.path);
    };

    showConfirm({
      title: nls.logoutTitle(),
      content: nls.logoutMessage(),
      primaryButtonProps: {
        onClick: logout,
      },
    });
  }

  useEffect(() => {
    if (!notices.length) return;

    const noticeIds = notices.map((notice) => notice.id);
    const localStorageNoticesIdsStr = localStorage.getItem('noticeIds');
    const localStorageNoticeIds = localStorageNoticesIdsStr
      ? JSON.parse(localStorageNoticesIdsStr)
      : [];

    // noticeIds 와 localStorageNoticeIds 를 비교해서 새로운 공지사항이 있는지 확인
    const isSame = JSON.stringify(noticeIds) === JSON.stringify(localStorageNoticeIds);

    if (!isSame) {
      setHasNotices(true);
      localStorage.setItem('hasNotices', 'true');

      // 공지사항이 바뀌면 비활성화된 공지사항들을 삭제
      removeDisabledNotices();
    }

    // 다음 비교를 위해 localStorage에 저장
    localStorage.setItem('noticeIds', JSON.stringify(noticeIds));
  }, [notices]);

  const getSavedDevMode = useCallback(() => {
    return (localStorage.getItem('devMode') ?? DEV_MODES[0]) as DevMode;
  }, []);

  const gnbLogo = useMemo(() => getGnbLogo(), []);
  const [devMode, setDevMode] = useState<DevMode>(getSavedDevMode);

  const handleNoticeClick = () => {
    setHasNotices(false);
    localStorage.setItem('hasNotices', 'false');
  };

  const handleDevMode = () => {
    if (!isDevWithStaging()) return;
    const newValueIndex = DEV_MODES.findIndex((x) => x === devMode) + 1;
    const newValue = DEV_MODES[newValueIndex % 3];
    setDevMode(newValue);
    localStorage.setItem('devMode', newValue);
  };

  const className = useMemo(() => `navigation-bar ${fixed ? 'fixed' : ''}`, [fixed]);
  const noticeLink = useMemo(
    () => (
      <ExternalLink href={NOTICE_URL} onClick={handleNoticeClick}>
        <span className="navIcon">
          {hasNotices ? (
            <StyledBadge color="error" variant="dot">
              <NotificationsIcon />
            </StyledBadge>
          ) : (
            <NotificationsIcon />
          )}
        </span>
        {nls.notice()}
      </ExternalLink>
    ),
    [hasNotices],
  );

  // https://www.notion.so/meissa/GNB-Autodesk-docs-669606af7ff34e74adc67a0ff1d617dc?pvs=4
  const isXiProject = useMemo(
    () => projectName?.indexOf('자이') > 0 && projectName?.indexOf('C&A') > 0,
    [projectName],
  );

  const MANUAL_URL = isGreen() ? GREEN_MANUAL_URLS[getLanguage()] : MANUAL_URLS[getLanguage()];

  return (
    <Wrapper className={`${className} ${extraClassName}`}>
      <div className="content">
        {isDevWithStaging() && devMode !== 'PROD' && (
          <p className={`dev ${devMode.toLowerCase()}`} onClick={handleDevMode}>
            {devMode}
          </p>
        )}
        <div className="navItems">
          <div className="logoArea" onClick={handleDevMode}>
            <img src={gnbLogo} alt="logo" />
          </div>
          <TopAreaButton
            title={nls.project()}
            icon={<FolderIcon />}
            link={router.project.index.path}
            isErrorPage={isErrorPage}
            fn={onProjectClick}
          />
          <TopAreaButton
            title={nls.projectSettingButton()}
            icon={<SettingsIcon />}
            link={router.setting.project.path}
            isErrorPage={isErrorPage}
            fn={() => sendProjectOption()}
          />
          <BottomArea>
            {isXiProject && (
              <ExternalLink href="https://acc.autodesk.com/">
                <span className="navIcon">
                  <LaunchIcon />
                </span>
                Autodesk Doc
              </ExternalLink>
            )}
            {noticeLink}
            <ExternalLink href={MANUAL_URL} data-cy="go-manual">
              <span className="navIcon">
                <ImportContactsIcon />
              </span>
              {nls.manual()}
            </ExternalLink>
            <ExternalLink onClick={onClickLogout}>
              <span className="navIcon">
                <LogoutIcon />
              </span>
              {nls.logout()}
            </ExternalLink>
          </BottomArea>
        </div>
      </div>
    </Wrapper>
  );
}

export default React.memo(NavigationBar);

function TopAreaButton({
  title,
  icon,
  link,
  isErrorPage,
  fn,
}: {
  title: string;
  icon: ReactNode;
  link: string;
  isErrorPage: boolean;
  fn?: () => void;
}) {
  if (isErrorPage) {
    return (
      <ExternalLink href={link}>
        <span className="navIcon">{icon}</span>
        {title}
      </ExternalLink>
    );
  }
  return (
    <NavLinkEx to={link} onClick={() => fn?.()}>
      <span className="navIcon">{icon}</span>
      {title}
    </NavLinkEx>
  );
}

const Wrapper = styled.div`
  display: flex;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
  }

  .content {
    width: ${navigationBarWidth};
    height: 100%;
    background: ${theme.palette.background.paper};
    border: 0;
    border-right: 1px solid ${theme.palette.divider};
    z-index: ${theme.zIndex.appBar};
    display: flex;
    flex-direction: column;
    padding: 1rem 0.25rem 1.875rem;

    p.dev {
      position: fixed;
      background-color: ${theme.palette.error.main};
      color: ${theme.palette.error.contrastText};
      padding: 0.2rem;
      top: 0;
      left: 0;
      &.green {
        background-color: ${COLOR_CHIP.GREEN};
      }
    }

    .navItems {
      flex: 1;
      display: flex;
      flex-direction: column;

      .logoArea {
        margin: 0 auto;
        width: 2.5rem;
        height: 2.5rem;
        overflow: hidden;
        margin-bottom: 2rem;

        > img {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
    }
  }
`;

const navItemStyle = css`
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  color: ${text.SECONDARY};
  display: block;
  margin-top: 1.25rem;
  text-decoration: none;
  border-radius: 0.25rem;
  padding: 0.375rem 0;

  &:hover {
    background-color: ${action.HOVER};
    color: ${text.SECONDARY};
  }

  .navIcon {
    display: block;
    margin: 0 auto 0.125rem;
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
`;

const BottomArea = styled.div`
  margin-top: auto;
`;

const ExternalLink = styled.a.attrs({ target: '_blank', rel: 'noreferrer noopener' })`
  cursor: pointer;
  ${navItemStyle};
`;
const NavLinkEx = styled(NavLink)`
  ${navItemStyle};
`;

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    top: 0.45rem;
  }
`;
