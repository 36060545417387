import FormControl from '@mui/material/FormControl';
import { PASSWORD_MIN_LENGTH } from 'shared/common/policies/limit';
import PasswordTextField from 'features/common/textfield/PasswordTextField';
import { nls } from 'shared/locale/language';
import React from 'react';
import { DialogContentText } from 'features/styleComponents/dialog/common';
import Confirm from 'features/styleComponents/dialog/Confirm';
import styled from 'styled-components';

export default function InputPasswordDialog({
  hideDialog,
  isShowing,
  onChange,
  value,
  request,
  error,
  setErrors,
}) {
  function handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      onSubmit();
    }
  }

  function isValid() {
    const passwordError = validate(value);
    passwordError && setErrors((errors) => ({ ...errors, password: passwordError }));
    return !passwordError;
  }

  function onSubmit() {
    if (!isValid()) {
      return;
    }

    request();
  }

  return (
    <Confirm
      open={isShowing}
      title={nls.confirmSecurityTitle()}
      content={nls.confirmSecurityMessage()}
      primaryButtonProps={{
        title: nls.save(),
        onClick: onSubmit,
      }}
      secondaryButtonProps={{
        title: nls.cancel(),
        onClick: hideDialog,
      }}
    >
      <DialogContentText>
        <Content>
          <PasswordTextField
            value={value}
            error={Boolean(error)}
            helperText={error}
            onChange={onChange}
            onKeyDown={handleKeyDown}
            label={nls.currentPassword()}
          />
        </Content>
      </DialogContentText>
    </Confirm>
  );
}

function validate(password) {
  let error = '';

  if (!password) {
    error = nls.phCurrentPassword();
  } else if (password.length < PASSWORD_MIN_LENGTH) {
    error = nls.minLength(PASSWORD_MIN_LENGTH);
  }

  return error;
}

const Content = styled(FormControl)`
  overflow: hidden;
  width: 100%;
  padding: 0.5rem 0;
`;
