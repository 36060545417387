import { CustomizingType, getCustomizingType } from 'shared/common/customize';
import { nls } from 'shared/locale/language';
import { useEffect } from 'react';

const PUBLIC_RESOURCE_URL = import.meta.env.VITE_PUBLIC_RESOURCE_URL || '';
const PUBLIC_RESOURCE_FOR_CLIENT_URL = `${PUBLIC_RESOURCE_URL}clients/` || '';
const PUBLIC_RESOURCE_FOR_FAVICON_URL = `${PUBLIC_RESOURCE_URL}favicons/` || '';
const getFaviconPath = (customizingType: CustomizingType) => {
  return `${PUBLIC_RESOURCE_FOR_FAVICON_URL}${customizingType}`;
};
const getResourcePath = (customizingType: CustomizingType) => {
  return `${PUBLIC_RESOURCE_FOR_CLIENT_URL}${customizingType}/`;
};

const HDC_LOGIN_IMAGE_COUNT = 10;
const getRandomIndex = (from: number, to = 1) => (new Date().getTime() % from) + to;
const getBgImagePath = (customizingType: CustomizingType, index: number | '' = '') => {
  if (customizingType === CustomizingType.HDC) {
    return `${getResourcePath(customizingType)}loginBg${getRandomIndex(HDC_LOGIN_IMAGE_COUNT)}.png`;
  }
  return `${getResourcePath(customizingType)}loginBg${index}.png`;
};

const getLoginLogoPath = (customizingType: CustomizingType) => {
  return `${getResourcePath(customizingType)}loginLogoIcon.svg?react`;
};

const getGnbLogoPath = (customizingType: CustomizingType) => {
  return `${getResourcePath(customizingType)}logoIcon.svg?react`;
};

const isIndependentDeploy = (customizingType: CustomizingType) => {
  const { MEISSA, GREEN, POSCO } = CustomizingType;
  return customizingType == MEISSA || customizingType === GREEN || customizingType === POSCO;
};

const getHTMLTitle = (customizingType: CustomizingType) => {
  if (isIndependentDeploy(customizingType)) return null;
  switch (customizingType) {
    case CustomizingType.KOLON:
      return nls.customizingTitleKolon();
    case CustomizingType.SNGIS:
      return nls.customizingTitleSngis();
    case CustomizingType.KCC:
      return nls.customizingTitleKcc();
    case CustomizingType.HDC:
      return nls.customizingTitleHdc();
    case CustomizingType.LOTTE:
      return nls.customizingTitleLotte();
    case CustomizingType.NAMI:
      return nls.customizingTitleNami();
    case CustomizingType.DONGBU:
      return nls.customizingTitleDongbu();
    default:
      return '';
  }
};

export function setTitle() {
  const title = getHTMLTitle(getCustomizingType());
  if (!title) {
    return;
  }
  const htmlTitle = document.querySelector('title');
  htmlTitle.innerText = title;
}

export function setFavicon() {
  const folder = getCustomizingType();
  if (isIndependentDeploy(folder)) return;

  const faviconList = document.getElementsByClassName('favicon');
  Array.from(faviconList).forEach((fav) => {
    const prevHref = fav?.getAttribute('href');
    if (prevHref.indexOf(folder) < 0) {
      fav.setAttribute('href', `${getFaviconPath(folder)}${prevHref}`);
    }
  });
}

export function getLoginBackgroundImage() {
  return getBgImagePath(getCustomizingType());
}

export function getLoginLogo() {
  return getLoginLogoPath(getCustomizingType());
}

export function getGnbLogo() {
  return getGnbLogoPath(getCustomizingType());
}

export function useCustomTitle() {
  useEffect(() => {
    setFavicon();
    setTitle();
  }, []);
}
