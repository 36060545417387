const MAIN = '#4CAF50';
const DARK = '#388E3C';
const LIGHT = '#81C784';
const CONTRAST_TEXT = '#FFFFFF';
/** @deprecated
 * src/shared/styles/muiV6/colors.ts 또는 Mui theme의 palette 값으로 대체해주세요.
 */
export const success = {
  MAIN,
  DARK,
  LIGHT,
  CONTRAST_TEXT,
};
