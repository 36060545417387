import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Cancel from '@mui/icons-material/Cancel';
import PersonIcon from '@mui/icons-material/Person';
import { Box, MenuItem, Select } from '@mui/material';
import { memberPermission } from 'shared/common/types';
import { nls } from 'shared/locale/language';
import { scrollStyle } from 'shared/styles/colors/scrollbar';
import theme from 'shared/styles/mui/theme';
import styled, { css } from 'styled-components';

interface Props {
  data: { email: string; grade: string }[];
  onGradeChange: (email: string, grade: string) => void;
  onDeleteClick: (email: string) => void;
  gradeOptions: (typeof memberPermission.MANAGER)[];
  loading: boolean;
}

export default function InviteeQueue({
  data,
  onGradeChange,
  onDeleteClick,
  gradeOptions,
  loading,
}: Props) {
  const empty = !data?.length;
  if (empty) {
    return <EmptyViewLayout>{nls.invitationModalEmptyMessage()}</EmptyViewLayout>;
  }

  const list = data.map((x) => {
    return (
      <ListItem key={x.email}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0.75rem',
            '& svg': { color: theme.palette.text.secondary },
          }}
        >
          <PersonIcon />
          <Email>{x.email}</Email>
        </Box>
        <Select
          value={data.find((y) => y.email === x.email).grade}
          onChange={(e) => onGradeChange(x.email, e.target.value as string)}
          sx={{ height: '2.5rem' }}
        >
          {gradeOptions.map((y) => (
            <MenuItem key={y.value} value={memberPermission[y.value].value}>
              {memberPermission[y.value].title}
            </MenuItem>
          ))}
        </Select>
        <StyledIconButton onClick={() => onDeleteClick(x.email)} $loading={loading}>
          <Cancel />
        </StyledIconButton>
      </ListItem>
    );
  });

  return <ListViewLayout>{list}</ListViewLayout>;
}

const layoutStyle = css`
  height: 16.75rem;
  margin-top: 0.5rem;
`;

const EmptyViewLayout = styled(Typography).attrs({ variant: 'body1' })`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.palette.text.disabled};
  ${layoutStyle}
  border: 1px solid ${theme.palette.divider};
  border-radius: 0.25rem;
`;

const ListViewLayout = styled.div`
  border-radius: 0.25rem;
  border: 1px solid ${theme.palette.divider};
  ${layoutStyle}
  ${scrollStyle};
`;

const ListItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 15rem 6rem;
  align-items: center;
  height: 3.5rem;
`;

const Email = styled(Typography).attrs({ variant: 'body2', color: 'textPrimary' })`
  flex-grow: 1;
  margin-left: 1rem;
`;

const StyledIconButton = styled(IconButton)<{ $loading: boolean }>`
  margin-left: auto;
  &:focus {
    outline: none;
  }

  visibility: ${({ $loading }) => ($loading ? 'hidden' : 'visible')};
`;
