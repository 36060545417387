import GetAppIcon from '@mui/icons-material/GetApp';
import BoundingBoxIcon from '@mui/icons-material/VideoStableOutlined';
import { Box, Button, FormHelperText, RadioGroup, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Autocomplete from 'features/common/Autocomplete';
import { LabelRadio } from 'features/common/radio/Radio';
import Select from 'features/common/select/Select';
import FormUnitMeasurement from 'features/project/formPage/FormUnitMeasurement';
import { isPosco } from 'shared/common/customize';
import {
  COORDINATE_SYSTEM_MAX_LENGTH,
  PROJECT_DESCRIPTION_MAX_LENGTH,
  PROJECT_NAME_MAX_LENGTH,
} from 'shared/common/policies/limit';
import GA from 'shared/ga';
import { PROJECT_SETTING_WORKER_BOUNDARY_BTN } from 'shared/ga/actions';
import { PROJECT } from 'shared/ga/category';
import { nls } from 'shared/locale/language';
import { hexToRgba } from 'shared/styles/colors/utils';
import theme from 'shared/styles/mui/theme';
import styled from 'styled-components';
import FieldBoundaryDialog from '../fieldBoundaryModal/FieldBoundaryDialog';
import SettingContentLayout from '../SettingContentLayout';
import QRCodeDownloadModal from './QRCodeDownloadModal';
import useProjectDetailManagementViewModel from './useProjectDetailManagementViewModel';

export default function ProjectDetailManagement() {
  const {
    projectName,
    onClickTitle,
    onClickDeleteProject,
    onClickSave,
    showDeleteButton,
    showSaveButton,

    data,
    showQRCodeModal,
    onClickQRCodeButton,
    onCloseQRCodeModal,
    editingFormData,

    nameError,
    onChangeName,
    onChangeDescription,
    onChangeConstructionDate,
    onChangeCompletedDate,
    coordSystemTextError,
    setCoordSystemTextError,
    coordSystemOption,
    coordSystemOptions,
    coordSystemOptionError,
    onChangeCoordSystemOption,
    onChangeVolumeUnit,

    localizedmapTypes,
    onChangeMapProvider,

    onChangeLogoutByDistance,

    coordSystemDirectInputIsActive,
    coordSystemCandidates,
    coordSystemText,
    setCoordSystemText,
    onChangeCoordSystemText,

    showBoundingBoxModal,
    onClickBoundingBoxButton,
    onCloseBoundingBoxModal,
  } = useProjectDetailManagementViewModel();

  const coordSystemTextInput = coordSystemDirectInputIsActive && (
    <AlignedAsyncAutocomplete
      options={coordSystemCandidates.data}
      optionsUpToDate={coordSystemText && coordSystemCandidates.query === coordSystemText}
      getOptionLabel={({ code }) => `EPSG ${code}`}
      onTextChange={onChangeCoordSystemText}
      onChange={(_, { code }) => setCoordSystemText(code)}
      onFocus={() => setCoordSystemText(coordSystemText)}
      value={coordSystemText}
      placeholder={nls.projectFormCoordTextHint()}
      error={coordSystemTextError}
      onNoMatchError={() => setCoordSystemTextError(nls.projectFormCoordTextNoMatches())}
      maxLength={COORDINATE_SYSTEM_MAX_LENGTH}
      prefix={coordSystemText ? 'EPSG ' : ''}
    />
  );

  return (
    <SettingContentLayout
      title={nls.projectInfo()}
      secondaryTitle={`'${projectName}' ${nls.basicDetails()}`}
      onClickTitle={onClickTitle}
      headerActions={
        <HeaderActions
          onClickDelete={onClickDeleteProject}
          onClickSave={onClickSave}
          showDeleteButton={showDeleteButton}
          showSaveButton={showSaveButton}
        />
      }
    >
      <ProjectDetailInfoGrid>
        <GridHeader variant="subtitle1" color="textSecondary" className="required">
          {nls.basicProjectName()}
        </GridHeader>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <TextField
            variant="outlined"
            value={editingFormData?.name || ''}
            onChange={onChangeName}
            fullWidth
            error={Boolean(nameError)}
            placeholder={nls.projectFormTitleHint()}
            sx={{ maxHeight: '3.5rem' }}
            slotProps={{
              htmlInput: { maxLength: PROJECT_NAME_MAX_LENGTH },
            }}
          />
          <FormHelperText error={Boolean(nameError)}>{nameError}</FormHelperText>
        </Box>

        <GridHeader variant="subtitle1" color="textSecondary">
          {nls.basicDescription()}
        </GridHeader>
        <TextField
          variant="outlined"
          value={editingFormData?.description || ''}
          onChange={onChangeDescription}
          placeholder={nls.projectFormDescriptionHint()}
          sx={{ maxHeight: '3.5rem' }}
          slotProps={{
            htmlInput: { maxLength: PROJECT_DESCRIPTION_MAX_LENGTH },
          }}
        />

        <GridHeader variant="subtitle1" color="textSecondary" className="required">
          {nls.projectConstructionDuration()}
        </GridHeader>
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <DatePicker
            label={nls.basicStartDate()}
            value={new Date(editingFormData?.constructionDate) || new Date()}
            maxDate={new Date(editingFormData?.completedDate)}
            onChange={onChangeConstructionDate}
            format="yyyy-MM-dd"
            slotProps={{
              textField: {
                inputProps: {
                  readOnly: true,
                },
                onClick: (e) => {
                  (e.currentTarget.querySelector('.MuiInputAdornment-root button') as any)?.click();
                },
                sx: {
                  cursor: 'pointer',
                  '.MuiInputBase-input': {
                    cursor: 'pointer',
                  },
                  '.MuiOutlinedInput-root': {
                    pointerEvents: 'none',
                  },
                  '.MuiInputAdornment-root': {
                    pointerEvents: 'all',
                  },
                },
              },
            }}
          />
          <DatePicker
            label={nls.basicEndDate()}
            value={new Date(editingFormData?.completedDate) || new Date()}
            minDate={new Date(editingFormData?.constructionDate)}
            onChange={onChangeCompletedDate}
            format="yyyy-MM-dd"
            slotProps={{
              textField: {
                inputProps: {
                  readOnly: true,
                },
                onClick: (e) => {
                  (e.currentTarget.querySelector('.MuiInputAdornment-root button') as any)?.click();
                },
                sx: {
                  cursor: 'pointer',
                  '.MuiInputBase-input': {
                    cursor: 'pointer',
                  },
                  '.MuiOutlinedInput-root': {
                    pointerEvents: 'none',
                  },
                  '.MuiInputAdornment-root': {
                    pointerEvents: 'all',
                  },
                },
              },
            }}
          />
        </Box>

        <GridHeader variant="subtitle1" color="textSecondary" className="required">
          {nls.projectFieldCoord()}
        </GridHeader>
        <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
          <StyledSelect
            value={coordSystemOption}
            onChange={onChangeCoordSystemOption}
            itemsPerPage={6}
            placeholder={nls.projectFormCoordSelectHint()}
            options={coordSystemOptions.map(({ desc, value }) => ({
              name: desc as string,
              value,
            }))}
            error={coordSystemOptionError}
          />
          {coordSystemTextInput}
        </Box>

        <GridHeader variant="subtitle1" color="textSecondary" className="required">
          {nls.projectMapProvider()}
        </GridHeader>
        <RadioGroup row>
          {localizedmapTypes.map(({ label, value }) => (
            <LabelRadio
              label={label}
              value={value}
              checked={editingFormData?.mapProvider === value}
              onChange={onChangeMapProvider}
              key={value}
              fullWidth={false}
            />
          ))}
        </RadioGroup>

        <GridHeader variant="subtitle1" color="textSecondary" className="required">
          {nls.volumeCalculationUnit()}
        </GridHeader>
        <FormUnitMeasurement
          projectId={data?.id}
          existingVolumeUnitId={data?.volumeUnit?.id}
          updateVolumeUnitId={onChangeVolumeUnit}
          hideLabel
        />

        <GridHeader variant="subtitle1" color="textSecondary" className="required">
          {nls.projectFormSubheadingLaborerLogoutDistance()}
        </GridHeader>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <TextField
            type="number"
            variant="outlined"
            sx={{ width: '23rem', maxHeight: '3.5rem' }}
            value={editingFormData?.data?.logoutByDistance ?? 0}
            onChange={onChangeLogoutByDistance}
          />
          <Typography variant="body1">m</Typography>
        </Box>

        {!isPosco() && (
          <>
            <GridHeader variant="subtitle1" color="textSecondary">
              {nls.workerCheckInQRButtonTitle()}
            </GridHeader>
            <Button
              variant="text"
              color="primary"
              startIcon={<GetAppIcon />}
              onClick={onClickQRCodeButton}
              sx={{
                width: 'fit-content',
                height: '2.5rem',
                padding: '0 1.375rem',
                backgroundColor: hexToRgba(theme.palette.primary.main, 0.08),
              }}
            >
              {nls.basicDownload()}
            </Button>
            {showQRCodeModal && <QRCodeDownloadModal data={data} onClose={onCloseQRCodeModal} />}
          </>
        )}

        {!isPosco() && (
          <>
            <GridHeader variant="subtitle1" color="textSecondary">
              {nls.fieldBoundaryButtonTitle()}
            </GridHeader>
            <Button
              variant="text"
              color="primary"
              startIcon={<BoundingBoxIcon />}
              onClick={() => {
                onClickBoundingBoxButton();
                GA.event(PROJECT, PROJECT_SETTING_WORKER_BOUNDARY_BTN);
              }}
              sx={{
                width: 'fit-content',
                height: '2.5rem',
                padding: '0 1.375rem',
                backgroundColor: hexToRgba(theme.palette.primary.main, 0.08),
              }}
            >
              {nls.projectFieldBoundaryDialogButton()}
            </Button>
            <FieldBoundaryDialog open={showBoundingBoxModal} onClose={onCloseBoundingBoxModal} />
          </>
        )}
      </ProjectDetailInfoGrid>
    </SettingContentLayout>
  );
}

function HeaderActions({
  onClickDelete,
  onClickSave,
  showDeleteButton,
  showSaveButton,
}: {
  onClickDelete: () => void;
  onClickSave: () => void;
  showDeleteButton: boolean;
  showSaveButton?: boolean;
}) {
  return (
    <Box sx={{ display: 'flex', gap: '0.75rem' }}>
      {showDeleteButton && (
        <Button
          variant="contained"
          onClick={onClickDelete}
          sx={{
            height: '2.5rem',
            backgroundColor: theme.palette.grey[200],
            color: theme.palette.text.primary,
            '&:hover': {
              backgroundColor: theme.palette.grey[300],
            },
          }}
        >
          {nls.delete()}
        </Button>
      )}
      {showSaveButton && (
        <Button
          variant="contained"
          color="secondary"
          onClick={onClickSave}
          sx={{ height: '2.5rem' }}
        >
          {nls.save()}
        </Button>
      )}
    </Box>
  );
}

const ProjectDetailInfoGrid = styled.div`
  display: grid;
  grid-template-columns: 10rem 1fr;
  gap: 2rem 1rem;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const GridHeader = styled(Typography)`
  display: flex;
  align-items: center;
  &.required {
    &:after {
      content: '*';
      color: ${theme.palette.error.main};
      margin-left: 0.25rem;
    }
  }
`;

const StyledSelect = styled(Select)`
  width: 17.5rem;
`;

const AlignedAsyncAutocomplete = styled(Autocomplete)`
  width: 17.5rem;
`;
