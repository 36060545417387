import TextField from '@mui/material/TextField';
import styled, { css } from 'styled-components';
import { others } from 'shared/styles/colors/others';
import theme from 'shared/styles/mui/theme';

const OutlinedTextField = styled(TextField).attrs({
  color: 'primary',
  variant: 'outlined',
})<{
  width?: string;
}>`
  margin-bottom: 1.0625rem;
  max-height: 4.4375rem;
  min-height: 4.4375rem;
  background-color: ${theme.palette.background.paper};
  &.MuiOutlinedInput-adornedEnd {
    padding-right: 0.875rem;
  }

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};

  input {
    &.MuiOutlinedInput-input {
      padding: 1.15625rem 0.875rem;
    }
    &:disabled {
      color: ${theme.palette.text.disabled};
    }
  }

  legend,
  label {
    color: ${theme.palette.text.secondary};
  }

  label.MuiInputLabel-outlined:not(.MuiInputLabel-shrink) {
    transform: translate(0.875rem, 1.25rem) scale(1); // 1920보다 작은 화면에서 hint 위치 틀어지는 현상 수정
  }

  &.mb-0,
  &.password {
    margin-bottom: 0;
  }

  p.MuiFormHelperText-contained {
    margin: 0.1875rem 0.875rem 0 0.875rem;
  }

  fieldset {
    border-color: ${others.BORDER};
  }
  .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: ${others.BORDER};
  }
`;

export default OutlinedTextField;
