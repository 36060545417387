export default {
      
  /* commom */
  add: '추가',
  cancel: '취소',
  close: '닫기',
  delete: '삭제',
  modified: '수정',
  done: '완료',
  confirm: '확인',
  home: '홈',
  project: '프로젝트',
  myInfo: '내 정보',
  manual: '매뉴얼',
  notice: '공지사항',
  logout: '로그아웃',
  save: '저장',
  downloadButton: '다운로드',
  logoutTitle: '로그아웃 확인',
  logoutMessage: '로그아웃 하시겠습니까?',
  edited: '수정됨',
  refresh: '새로고침',
  addNew: '새로 추가',
  reset: '초기화',
  dontShowAgain: '다시 보지 않기',
  total: '전체',
  select: '선택',
  warningBrowser: '권장 브라우저가 아니면 일부 동작이 원활하지 않을 수 있습니다. 권장 브라우저로 접속해주세요.',
  twoDMapButton: '2D',
  threeDMapButton: '3D',
  back: '뒤로',
  copy: '복사',
  change: '변경',
  stopButton: '중단하기',
  continuedButton: '계속하기',
  nextButton: '다음으로',
  prevButton: '이전으로',
  submitButton: '완료하기',
  skip: '건너뛰기',
  retry: '재시도',
  next: '다음',
  noPermissionMsgContent: '해당 기능에 대한 권한이 없습니다.',
  titleExitWithoutSaving: '변경 내용 확인',
  exitWithoutSaving: '변경 내용을 저장하지 않고 페이지를 나가시겠습니까?',
  textFeedbackErrorMessage: '오류가 발생했습니다. 다시 시도해주세요.',
  textFeedbackSuccessMessage: '변경 사항이 저장되었습니다.',
  textFeedbackLoadingMessage: '잠시만 기다려주세요.',
  textFeedbackWarningMessage: '경고',
  noticeTitle: '공지',
  noticeNewTitle: '새로운 기능',
  noticeErrorTitle: '오류 안내',
  noticeDontShowAgainButton: '다시 보지 않기',
  noticeDontShowAgainMessage: '다시 보기 원하시면 왼쪽 공지사항 아이콘을 클릭해주세요!',
  noticeDisableMessage: '오늘 하루 보지 않기',
  moreInformation: '자세히 보기',
  downloadChromeBrowser: 'Chrome 다운로드',
  BIM: 'BIM',
  currentDsm: '현황고',
  search: '검색',

  /* components/loginPage/LoginPage */
  loginTitle: '로그인',
  password: '비밀번호',
  inputEmail: '이메일을 입력해주세요.',
  inputPw: '비밀번호를 입력해주세요.',
  checkPwLength: '영문, 숫자를 혼합해 최소 8자 이상 입력해주세요.',
  findPassword: '비밀번호 찾기',
  email: '이메일',
  oneStopService: '원스톱 서비스',

  /* components/userPage/content/FindPw */
  goBack: '뒤로 가기',
  generateTempPwButton: '임시 비밀번호 발급',
  generateTempPwDescription: '가입 시 입력한 이메일 주소를 적어주세요.\n가입 여부 확인 후 임시 비밀번호를 발송해드립니다.',
  sentEmail: '임시 비밀번호가 발송되었습니다.\n메일함을 확인해주세요.',

  /* components/myPage/MyPage */
  myPageTitle: '내 정보',
  myPageEditTitle: '내 정보 수정',
  phPassword: '비밀번호를 입력해주세요',
  name: '이름',
  emailAddress: '이메일',
  currentPassword: '현재 비밀번호',
  newPassword: '새 비밀번호',
  confirmPassword: '비밀번호 확인',
  confirmNewPassword: '새 비밀번호 확인',
  company: '회사',
  jobTitle: '직책',
  phName: '이름을 입력해주세요.',
  phCompany: '회사를 입력해주세요.',
  phJobTitle: '직책을 입력해주세요.',
  phCurrentPassword: '현재 비밀번호를 입력해주세요.',
  phNewPassword: '새 비밀번호를 입력해주세요.',
  phConfirmPassword: '비밀번호를 한 번 더 입력해주세요.',
  phConfirmNewPassword: '새 비밀번호를 한 번 더 입력해주세요.',
  requiredName: '이름을 입력해주세요.',
  requiredCompany: '회사를 입력해주세요.',
  requiredPassword: '비밀번호를 입력해주세요.',
  requiredConfirmPassword: '비밀번호를 한 번 더 입력해주세요.',
  requiredConfirmNewPassword: '새 비밀번호를 한 번 더 입력해주세요.',
  notEqualPassword: '비밀번호가 일치하지 않습니다.',
  notEqualNewPassword: '새 비밀번호와 일치하지 않습니다.',
  minLength: '최소 ${n}자 이상 입력해주세요.',
  completedMyInfoEditing: '변경 내용이 저장되었습니다.',
  confirmSecurityTitle: '보안 확인',
  confirmSecurityMessage: '변경 내용을 저장합니다.\n보안 확인을 위해 현재 비밀번호를 입력해주세요.',

  /* components/projectPage/content/ProjectEdit */
  latlng: '경위도(EPSG 4326)',
  eastDatum: 'GRS80 동부(EPSG 5187)',
  eastSeaDatum: 'GRS80 동해(EPSG 5188)',
  centralDatum: 'GRS80 중부(EPSG 5186)',
  westDatum: 'GRS80 서부(EPSG 5185)',
  eastDatumBessel: 'Bessel 동부(EPSG 5176)',
  eastSeaDatumBessel: 'Bessel 동해(EPSG 5177)',
  centralDatumBessel: 'Bessel 중부(EPSG 5174)',
  westDatumBessel: 'Bessel 서부(EPSG 5173)',
  epsg6669: '1계 EPSG:6669(JGD2011)',
  epsg6670: '2계 EPSG:6670(JGD2011)',
  epsg6671: '3계 EPSG:6671(JGD2011)',
  epsg6672: '4계 EPSG:6672(JGD2011)',
  epsg6673: '5계 EPSG:6673(JGD2011)',
  epsg6674: '6계 EPSG:6674(JGD2011)',
  epsg6675: '7계 EPSG:6675(JGD2011)',
  epsg6676: '8계 EPSG:6676(JGD2011)',
  epsg6677: '9계 EPSG:6677(JGD2011)',
  epsg6678: '10계 EPSG:6678(JGD2011)',
  epsg6679: '11계 EPSG:6679(JGD2011)',
  epsg6680: '12계 EPSG:6680(JGD2011)',
  epsg6681: '13계 EPSG:6681(JGD2011)',
  epsg6682: '14계 EPSG:6682(JGD2011)',
  epsg6683: '15계 EPSG:6683(JGD2011)',
  epsg6684: '16계 EPSG:6684(JGD2011)',
  epsg6685: '17계 EPSG:6685(JGD2011)',
  epsg6686: '18계 EPSG:6686(JGD2011)',
  epsg6687: '19계 EPSG:6687(JGD2011)',
  selectEtc: '직접 입력',
  phToSelectCoordSystem: '좌표계를 선택해주세요',
  phToCoordSystem: '좌표계를 입력해주세요.',
  phToValidCoordinate: '올바르지 않은 좌표계입니다.',

  /* components/projectPage/content/projectMember */
  grade: '등급',
  status: '상태',
  ownerTitle: '최고 관리자',
  ownerDesc: '관리자 지정 및 전체 기능 사용',
  managerTitle: '관리자',
  managerDesc: '일반 사용자 지정 및 현장상황 관련 기능 사용',
  userTitle: '일반 사용자',
  userDesc: '방문자 지정 및 지도도구 관련 기능 사용',
  guestTitle: '방문자',
  guestDesc: '참여자 확인 및 현장상황 보기',
  pauseTitle: '사용자 정지',
  pauseDesc: '모든 기능 정지',
  joinStatus: '참여 중',
  deleteUserTitle: '참여자 삭제',
  cancelDeleteMode: '나가기',
  deleteUser: '${n}명의 참여자를 해당 프로젝트에서 삭제하시겠습니까?',
  failedToDeleteUser: '오류가 발생하여 삭제 실패했습니다.\n다시 시도해주세요.',
  changeGradeTitle: '등급 변경 확인',
  changeGrade: '해당 계정의 등급을\n${n}로 변경하시겠습니까?',
  failedToChangeGrade: '등급을 변경할 수 없습니다.\n다시 시도해주세요.',
  invalidEmailFormatAlert: '잘못된 이메일 형식입니다.\n다시 입력해주세요.',
  inviteParticipant: '참여자 초대',
  invite: '초대',
  invited: '참여 대기',
  invitationTimestamp: '${n}에 초대함',
  joinTimestamp: '${n}에 참여함',
  participantGradeTooltipHeader: '참여자 등급별 권한',
  noMemberSelected: '선택된 사용자가 없습니다.',
  noInviteesMessage: '초대 버튼을 눌러 참여자를 초대해주세요.',
  sendInvitation: '초대장 발송',
  sendInvitationLoadingMessage: '잠시만 기다려주세요.',
  sendInvitationSuccessMessage: '발송 완료하였습니다.',
  sendInvitationErrorMessage: '메일 발송에 실패했습니다.',
  resendInvitation: '초대 재발송',
  resendInvitationSuccess: '초대 이메일을 재발송했습니다.',
  resendInvitationErrorTitle: '초대 재발송 오류',
  resendInvitationErrorMessage: '초대 이메일 재발송 중 오류가 발생했습니다.\n재시도하시겠습니까?',
  resendInvitationBlocked: '1분 이내 재발송은 불가합니다.\n잠시 후 다시 시도해주세요.',
  emailEmptyError: '이메일을 입력해주세요.',
  emailFormatError: '잘못된 이메일 형식입니다. 다시 입력해주세요.',
  emailAlreadyInvited: '이미 초대한 사용자입니다.',
  emailAlreadyUser: '이미 추가한 사용자입니다.',
  gradeEmptyError: '등급을 선택해주세요.',

  /* ProjectCard */
  projectCardSnapshotNumber: '현장 개수:',
  projectCardLatestSnapshot: '최근 촬영일:',

  /* sidemenu */
  mapSideMenuAccordionGroupTitle: '지도',
  measurementSideMenuAccordionGroupTitle: '지도 도구',
  layerSideMenuAccordionGroupTitle: '레이어',
  mediaSideMenuAccordionGroupTitle: '미디어',
  issueSideMenuAccordionGroupTitle: '작업 관리',
  safetySideMenuAccordionGroupTitle: '안전 관리',
  gcpAccordionGoToEditorButtonTitle: 'GCP 마킹 확인',
  measurementSideMenuAccordionGroupTooltipMessage: '지도에 도구를 추가하여 주변 콘텐츠를 확인하고\n거리나 고도를 바탕으로 계산할 수 있습니다.',
  layerSideMenuAccordionGroupTooltipMessage: '지도에 얹어서 볼 수 있는 데이터로 투명도를\n조절하거나 지적 보상 상태를 변경할 수 있습니다.',

  /* File Archive */
  fileArchiveEmptyMessage: '아직 파일이 없습니다.\n현장상황 상세에서 파일을 추가해보세요.',
  fileArchiveSearchEmptyMessage: '검색 결과가 없습니다.\n다른 단어로 검색해보세요.',
  fileArchiveSearchPlaceholder: '파일 이름 검색',
  fileArchiveTableHeaderZone: '구역',
  fileArchiveTableHeaderFileName: '파일 이름',
  fileArchiveTableHeaderType: '종류',
  fileArchiveTableHeaderDate: '생성일',
  fileArchiveTableHeaderDownload: '다운로드',
  fileArchiveTableHeaderDelete: '삭제',
  fileArchiveTableHeaderSelect: '선택',
  fileArchiveTypeChain: '토공 횡단선',
  fileArchiveTypePoint: '위치 도구',
  fileArchiveTypePolyline: '선분 도구',
  fileArchiveTypePolygon: '면적 도구',
  fileArchiveTypePlanLevel: '계획고',
  fileArchiveTypeBim: 'BIM',
  fileArchiveTypeStratum: '지층고',
  fileArchiveTypeDrawingVector: '자동 벡터 도면',
  fileArchiveTypeDrawingRaster: '자동 래스터 도면',
  fileArchiveTypeDrawingPositioning: '수동 배치 도면',
  fileArchiveTypePolygonVector: '도면 면적',

  /* FileArchive - Drawing Download */
  fileArchiveDrawingDownloadTitle: '도면 다운로드',
  fileArchiveDrawingDownloadDesc: '파일을 먼저 압축해주세요.',
  fileArchiveDrawingDownloadDescLoading: '파일 압축중입니다. 잠시만 기다려주세요.',
  fileArchiveDrawingDownloadDescSuccess: '다운로드 준비가 완료되었습니다.',
  fileArchiveDrawingDownloadButton: '파일 압축하기',
  fileArchiveDrawingDownloadButtonSuccess: '다운로드',
  fileArchiveDrawingDownloadCancelTitle: '파일 압축 취소',
  fileArchiveDrawingDownloadCancelDesc: '아직 파일이 압축되지 않았습니다.\n압축을 취소하고 나가시겠습니까?',
  fileArchiveDrawingDownloadCancelButton: '계속하기',
  fileArchiveDrawingDownloadCancelConfirm: '나가기',

  /* projectDetailPage - common nls */
  exportTitle: '다운로드',
  idleTitle: '대기',
  runningTitle: '분석 중',
  errorTitle: '오류',
  doneTitle: '완료',

  /* components/projectDetailPage/drawingConfig/DrawingConfig */
  drawingConfigTitle: '도면 설정',
  drawingFirstStep: '도면을 확대/축소하여 위치를 식별하기 적합한 곳으로 마커를 옮겨주세요 (1/2)',
  drawingSecondStep: '마커를 드래그하여 지도에 맞게 설정하세요 (2/2)',
  drawingConfigTitleWithoutSaving: '도면 설정 중단',
  drawingConfigMessageWithoutSaving: '지금 종료하면 설정한 도면 위치가 저장되지 않습니다.\n작업을 중단하시겠습니까?',
  drawingConfigNextButton: '다음',
  drawingConfigPrevButton: '이전 단계',
  drawingConfigClear: '초기화',
  drawingConfigClose: '닫기',
  drawingConfigSubmit: '완료',

  /* components/projectDetailPage/mainContent/map/handleAnnotation */
  areaExitTooltip: '마우스 오른쪽 버튼 또는 \nESC 키를 누르면 완료됩니다.',
  areaExitTooltipTouchDevice: '완료 버튼을 누르면 생성됩니다.',

  /* components/projectDetailPage/mainContent/map/print/PrintView */
  sizeOriginal: '1배율',
  sizeDouble: '2배율',
  sizeTriple: '3배율',
  sizeFourTimes: '4배율',
  sizeFiveTimes: '5배율',

  /* components/projectDetailPage/sideMenu/annotation/Annotation */
  meterValue: '${n}m',
  squareMeterValue: '${n}m²',
  noInformationAnnotationCreator: '정보 없음',
  deletedAnnotationCreator: '삭제된 사용자입니다.',
  emptyAnnotationCreator: '2023년 4월 20일 이전 데이터는\n작성자 정보가 없습니다.',

  /* resourceToAnnotation */
  resourceToAnnotationProgressRunningChip: '분석 중',
  resourceToAnnotationDeleteMessage: '${n} 파일 삭제 시, 전체 현장상황에서 파일이 영구히 삭제됩니다. 정말 삭제하시겠습니까?',
  resourceToAnnotationErrorMessage: '오류가 발생했습니다. 다시 시도해주세요.',
  polygonVectorEmptyMessage: '파일에 폴리곤 객체가 없어 면적이 생기지 않습니다.',
  polygonVectorUploadTitle: '도면으로 면적 추가',
  polygonVectorUploadDescription: '폴리곤이 아닌 객체는 생성되지 않습니다.',
  addPolygonVectorButton: '도면으로 추가',
  runningResourceToAnnotationTooltipMessage: '분석 중인 파일은 연동을 관리할 수 없습니다.',

  /* components/projectDetailPage/sideMenu/annotation/AnnotationTool */
  axisDataTitle: '수평 길이(${n})',
  axisElevationTitle: '고도(${n})',
  cutVolumeSubTitle: '절토',
  fillVolumeSubTitle: '성토',
  volumeSoil: '토사',

  /* components/projectDetailPage/sideMenu/annotation/ElevationDiv */
  referenceDateType: '기준 날짜',
  compareDateType: '비교 날짜',
  planElevationType: '계획고',
  lengthTooltip: '길이',
  elevationTooltip: '고도 비교',
  generateElevationDownload: 'DXF 파일을 생성 중입니다.\n잠시만 기다려주세요.',
  failedCreateElevationDownload: 'DXF 파일이 생성되지 않았습니다.\n다시 시도해주세요.',
  elevationDivEmpty: '수치표면모델이 겹치지 않아 그래프가 그려지지 않습니다.',
  elevationDivWarning: '수치표면모델이 없는 부분은 근처의 데이터를 이어서 그려집니다.',
  elevationDivError: '일시적인 오류로 정보를 불러올 수 없습니다.',
  cancelCreateElevationDownloadTitle: '작업 취소',
  cancelCreateElevationDownload: 'DXF 파일을 생성 중입니다.\n작업을 중단하시겠습니까?',
  stratumElevationNth: '지층${n}',
  showElevationGraph: '그래프 보기',
  elevationChartDownloadTooltip: '단면도 그래프 다운로드',
  elevationChartCloseTooltip: '닫기',

  /* components/projectDetailPage/sideMenu/annotation/ElevationDateDiv */
  graphDownloadTitle: '그래프 다운로드',
  phToEmptyElevation: '${n} 없음',

  /* components/projectDetailPage/sideMenu/annotation/ElevationDownloadModal */
  graphSelectionDesc: '다운로드 할 그래프 종류를 선택해주세요.',
  createGraphButton: '다운로드',

  /* components/projectDetailPage/sideMenu/common/ErrorMsgDiv */
  zPositionWarningMsg: '수치표면모델이 없는 영역은 z값을 불러올 수 없습니다.',
  zPositionErrorMsg: '오류가 발생하여 정보를 불러올 수 없습니다.',

  /* components/projectDetailPage/sideMenu/snapshotDetail/ExportDiv */
  etcTitle: '기타 파일',
  planLevelTitle: '계획고',
  snapshotHeaderCompare: '현장 비교',
  snapshotHeaderEditButton: '현장 상황 수정',
  basicShare: '공유',
  snapshotHeaderPrintButton: '인쇄',
  snapshotHeaderDownloadButton: '다운로드',
  snapshotHeaderMoreButton: '더보기',
  downloadPopupTitle: '현장상황 다운로드',
  downloadTabImageTitle: '원본 이미지',
  downloadTabOrthophotoTitle: '정사사진',
  downloadTabDsmTitle: '수치표면모델',
  downloadTabPointCloudTitle: '3D 점 (Point Cloud)',
  downloadTabMeshTitle: '3D 면 (Mesh)',
  downloadTabGcpTitle: 'GCP',
  downloadTabReportTitle: '보고서',
  downloadImageName: 'images.zip',
  downloadOrthophotoName: 'tif 원본',
  downloadOrthophotoPngName: 'png 고해상도',
  downloadOrthophotoSampledName: 'png 저해상도',
  downloadDsmName: 'tif 원본',
  downloadDsmDesc: '실제 고도값(Z)를 포함한 데이터',
  downloadDsmPngName: 'png 고해상도',
  downloadDsmPngDesc: '고도를 RGB 컬러로 시각화',
  downloadPointCloudName: 'las 원본',
  downloadPointCloudPlyName: 'ply 원본',
  downloadPointCloudSampledName: 'ply 저해상도',
  downloadPointCloudSampledDesc: '점 개수를 줄인 데이터',
  downloadMeshFbxName: 'fbx 원본',
  downloadMeshObjName: 'obj 원본',
  downloadMeshObjDesc: 'obj, mtl, jpg 파일 포함',
  downloadGcpName: 'GCP',
  downloadPopupItemButton: '다운로드',
  downloadArchivePopupItemButtton: '압축 다운로드',
  downloadArchivePopupItemLoading: '압축 중',
  downloadArchivePopupItemSuccess: '압축이 완료되었습니다. 다운로드 버튼을 눌러주세요.',
  downloadArchivePopupItemError: '오류가 발생했습니다. 다시 시도해주세요.',
  downloadPopupImageView: '이미지 개별 보기 (${n})',
  beta: 'Beta',
  downloadReportButton: '보고서 다운로드',
  downloadPix4DReportLabel: 'Pix4D 분석 보고서',
  createReportButton: '보고서 생성',
  creatingReportButton: '보고서 생성 중',
  reportAnnotationInclusionModeLabel: '포함 내용',
  reportAnnotationExtensionLabel: '보고서 형식',
  reportCreationLoadingMessage: '파일을 생성중입니다. 잠시 기다려주세요.',
  reportCreationCompletedMessage: '보고서가 완성되었습니다.',
  downloadEmptyViewMessage: '파일이 없습니다.',
  downloadEmptyViewAddButton: '파일 추가',
  downloadCreateZipFileButton: '압축 파일 생성',
  downloadLoadingZipFileButton: '압축 파일 생성중',
  downloadSuccessZipFile: '압축 파일이 완성되었습니다.',
  downloadCompensationReportComment: '정사사진 및 지적 보상 상태가 포함됩니다.',
  downloadAnnotationReportLabel: '지도 도구 보고서',
  downloadCompensationReportLabel: '지적 보상 상태 보고서',
  downloadSelectedAnnotationReportLabel: '선택한 지도 도구만 포함',
  downloadAllAnnotationReportLabel: '전체 지도 도구 포함',
  downloadSelectedAnnotationReportComment: '정사사진 및 현재 지도에 표시된\n위치 · 선분 · 면적 도구가 포함됩니다.',
  downloadAllAnnotationReportComment: '정사사진 및 보관함에서 가져온 모든\n위치 · 선분 · 면적 도구가 포함됩니다.',
  sharePopoverInfo: '링크를 통해 로그인을 하지 않아도 데이터를 확인할 수 있습니다. 원하는 사람에게 링크를 공유하세요.',
  sharePopoverCopyButton: '복사',
  sharePopoverCopyCompleteButton: '복사 완료',

  /* components/projectDetailPage/sideMenu/snapshotDetail/MainDiv */
  deleteHistoryContent: '현장상황을 삭제하면 이 현장에 포함된 모든 현장상황 데이터가 함께 삭제됩니다. 정말 삭제하시겠습니까?',

  /* components/projectDetailPage/sideMenu/snapshotDetail/PlanVolumeSlider */
  planVolumeOpacity: '불투명도',

  /* components/projectDetailPage/sideMenu/snapshotDetail/snapshotDetail */
  deleteMsg: '삭제되었습니다.',

  /* components/projectDetailPage/sideMenu/snapshotDetail/3D/BIM */
  BimDataLoading: '데이터를 준비 중입니다. 잠시만 기다려주세요.',
  BimDataError: '예기치 못한 오류로 이 파일은 불러올 수 없습니다. 삭제 후 다시 업로드해주세요.',
  bimOnPointCloudNoLocation: '이 파일은 좌표 정보가 없습니다. 임의로 정가운데에 배치됩니다.',
  bimOnPointCloudOutOfPointCloudBound: '이 파일은 지도 영역을 벗어났습니다. 임의로 정가운데에 배치됩니다.',
  alertGcpWarning: '현장 범위를 벗어난 GCP 지점입니다.',

  /* components/projectDetailPage/sideMenu/snapshotNew/SnapshotNew */
  deletingSnapshot: '현장상황 삭제',
  aerialImage: '이미지',
  uploadButton: '업로드',
  uploadingButton: '업로드 중',
  stratumAliasIsEmpty: '이름을 입력해주세요',
  stratumFileIsEmpty: '파일을 선택해주세요',

  /* components/projectDetailPage/sideMenu/snapshotNew/gcp/GcpEditor */
  gcpEditorTitle: 'GCP 마킹',
  gcpEditorDesc: '마킹한 이미지 수가 3장 이상인 GCP가 3개 이상이어야 분석가능합니다.',
  resetGcpImageLocations: '전체 초기화',

  /* gcp/GcpTable */
  gcpFileSubTitle: 'GCP 목록',
  gcpName: '이름',
  positionX: 'X (Easting)',
  positionY: 'Y (Northing)',
  positionZ: 'Z (Altitude)',
  horizontalAccuracy: '수평 정확도 (${n})',
  verticalAccuracy: '수직 정확도 (${n})',
  imageCount: '마킹한 이미지',
  gcpImageCountDesc: '3D 모델 정확도를 위해 1개의 GCP 당, 최소 4장 이상의 이미지를 마킹해주세요.',

  /* gcp/GcpSetting */
  settingGcpTitle: 'GCP 마커 설정',
  descriptionGcpSetting: '마우스 오른쪽 버튼을 클릭하면 마커가 추가됩니다.',

  /* gcp/GcpImageList */
  gcpImageListTitle: '${n} 이미지 목록',
  gcpImageListDesc: '선택된 GCP와 가까운 순으로 정렬된 이미지입니다.',
  resetGcpImagesAlertTitle: 'GCP 마커 전체 초기화',
  resetGcpImagesAlert: '${n}에 설정된 모든 마커를 초기화하시겠습니까? \n자동 추천된 마커도 초기화됩니다.',
  gcpMarkingDoneButton: '마킹 완료',
  addGcpImageButton: '참고사진 추가',
  gcpImageManagePopupTitle: 'GCP 참고사진 관리',
  gcpRecommendationButton: 'GCP 자동 추천',
  gcpImageTableCellTitle: '참고사진',
  gcpRecommendationInProgressMessage: '표시한 마커를 토대로 이미지에서 GCP를 찾고 있습니다. \n최대 2분 정도 소요될 수 있습니다. 잠시만 기다려주세요.',
  gcpRecommendationErrorTitle: 'GCP 자동 추천',
  gcpRecommendationInfoMessage: '추천된 마커(GCP)의 위치는 정확하지 않을 수 있습니다. \n직접 위치를 재조정한 마커만 분석에 사용됩니다.',
  resetGcpMarkerLocationTooltip: '추천된 / 직접 마킹한 마커 모두 삭제됩니다.',
  resetGcpMarkerButtonText: '마커 초기화',
  gcpMarkerColorInPerson: '마킹 완료한 GCP',
  gcpMarkerColorRecommended: '마킹 필요한(추천된) GCP',
  gcpRecommendationStopConfirmTitle: 'GCP 자동 추천 취소',
  gcpRecommendationStopConfirmMessage: '아직 자동 추천이 진행중입니다.\n GCP 자동 추천을 중단하시겠습니까?',
  gcpRecommendationStopConfirmOk: '중단',
  gcpRecommendationStopConfirmCancel: '계속',
  gcpRecommendationTooltipHow: 'GCP당 1개 이상의 이미지를 마킹하세요. 마킹한 후에 [자동 추천] 버튼을 클릭하면 여러 GCP가 한번에 추천됩니다.',
  gcpRecommendationTooltipWhat: '한번의 마킹으로 나머지 이미지에서 GCP 위치를 자동으로 추천해줍니다. ',
  gcpRecommendationTooltipLink: 'https://www.notion.so/meissa/GCP-0dd74c481e284c0481de96af19ea176f?pvs=4#b1cb2a922d574b11bac62014a5869456',
  joinCheckBoxLabelText1: '',
  joinCheckBoxPrivacyPolicy: '개인정보처리방침',
  joinCheckBoxLabelText2: ' 및 ',
  joinCheckBoxTermsOfUse: '이용약관',
  joinCheckBoxLabelText3: '에 동의합니다.',
  joinCheckBoxErrorMessage: '약관에 동의해주세요.',
  joinTitle: '회원가입',
  joinButton: '가입',
  joinSuccessAlertMsg: '회원가입이 완료되었습니다.\n로그인하여 메이사 서비스를 이용해주세요.',
  termsOfUseUrl: 'https://meissa.notion.site/bfe791d2bcae45d29fab5cc6de722507',
  privacyPolicyUrl: 'https://meissa.notion.site/43d1e86fbd124c6d94b072ab91eb294a',
  projectFieldName: '제목',
  projectFieldDescription: '설명',
  projectFieldCoord: '좌표계',
  projectFieldPeriod: '기간',
  projectFieldStatus: '상태',
  projectStatusInProgress: '진행 중',
  projectStatusCompleted: '완료',
  projectMapProvider: '지도',
  projectMapProviderVworld: 'Vworld 지도',
  projectMapProviderBing: 'Bing 지도',
  projectMapProviderGoogle: 'Google 지도',
  projectFormHeadingCreating: '새 프로젝트 추가',
  projectFormHeadingEditing: '프로젝트 수정',
  projectFormSubheadingInfo: '기본 정보',
  projectFormSubheadingInfoDetail: '현장 상세 정보',
  projectFormSubheadingLaborerLogoutDistance: '근로자 거리 체크아웃',
  projectFormTitleHint: '프로젝트 이름을 입력해주세요.',
  projectFormTitleEmptyError: '제목을 입력해주세요.',
  projectFormDescriptionHint: '프로젝트 설명을 입력해주세요.',
  projectFormCoordSelectHint: '좌표계를 선택해주세요.',
  projectFormCoordTextHint: '숫자만 입력 가능합니다.',
  projectFormCoordTextNoMatches: '지원하지 않는 좌표계입니다.',
  projectFormCoordinateSystemTextEmptyError: '좌표계를 입력해주세요.',
  projectFormCoordinateSystemOptionEmptyError: '좌표계를 선택해주세요.',
  projectFormUnit: '단위',
  projectFormUnitAdd: '단위 추가',
  projectFormUnitHint: '추가할 단위를 입력해주세요',
  projectFormUnitValue: '계수 입력',
  projectFormSuccessConfirmTitle: '프로젝트 추가 완료',
  projectFormSuccessConfirmMessage: '${n} 프로젝트가 추가 완료되었습니다.',
  projectFormSuccessConfirmDefaultButton: '현장상황 추가',
  projectFormSuccessConfirmShowProjectListButton: '프로젝트 목록 보기',
  projectFormDistance: '거리',
  projectSettingButton: '설정',
  projectInfo: '프로젝트 관리',
  participantsInfo: '참여자 관리',
  fileArchive: '연동 파일 보관함',
  laborerHistory: '근로자 출역관리',
  projectFormUpdateSuccess: '정보가 수정되었습니다.',
  projectFormUpdateError: '오류가 발생했습니다. 다시 시도해주세요.',
  projectListSearch: '검색',
  projectListInProgressTitle: '진행 중 프로젝트',
  projectListCompletedTitle: '완료된 프로젝트',
  projectListExpiredTitle: '만료된 프로젝트',
  projectListCreateButton: '새 프로젝트 추가',
  projectListEmptyText: '프로젝트가 없습니다.',
  projectListFetchError: '오류가 발생했습니다. 다시 시도해주세요.',
  sortOrderNameAsc: '제목 오름차순',
  sortOrderNameDesc: '제목 내림차순',
  sortOrderConstructionDateAsc: '시작 날짜 오래된순',
  sortOrderConstructionDateDesc: '시작 날짜 최신순',
  projectInfoHeading: '프로젝트 관리',
  projectInfoSnapshotList: '프로젝트 현장상황 목록',
  projectDeleteConfirmTitle: '프로젝트 삭제',
  projectDeleteConfirmMessage: '프로젝트를 삭제하면 이 프로젝트에 포함된 모든 현장상황 데이터가 함께 삭제됩니다. 정말 삭제하시겠습니까?',
  projectQRCodeDownload: 'QR 다운로드',
  projectQRCodeDownloadTooltipMessage: '근로자가 Meissa Guard 앱에서 체크인 할 수 있는 QR코드를 이미지로 다운로드합니다.',
  projectFieldBoundaryDialogButton: '현장 경계 확인',
  projectQRCodeImageDownload: 'JPG 파일 다운로드',
  projectQRCodeForMeiday: 'Meissa Guard 체크인용 QR 코드',
  projectQRCodeDescription: "현장 근로자가 Meissa Guard 앱에서 이 QR코드를 스캔하면 해당 프로젝트로 체크인 할 수 있습니다.\n 체크인한 근로자의 위치는 현장상황의 '근로자 위치' 에서 확인할 수 있습니다.",
  projectFieldBoundaryInstructionMessage: '현장 경계 기준 지정한 거리를 벗어나면 노무자가 자동으로 로그아웃 됩니다.',
  zoneProjectList: '프로젝트 목록',
  zoneList: '구역 목록',
  zoneEditName: '구역 이름 수정',
  zoneSaveName: '저장',
  emptyZoneNameError: '구역 이름을 입력해주세요.',
  zoneModeToMap: '지도에서 보기',
  zoneModeToCard: '목록에서 보기',
  zoneEmptyData: '현장상황 없음',
  zoneSnapshotList: '현장상황 목록',
  zoneImageTab: '이미지',
  zoneVideoTab: '영상',
  zonePanoramaTab: '360° 파노라마',
  zoneBirdsEyeViewTab: '전경사진',
  indoorDataTab: '실내',
  zoneSortOrderDateDesc: '최신순',
  zoneSortOrderDateAsc: '오래된순',
  buildingSortOrderNameAsc: '이름 오름차순',
  buildingSortOrderNameDesc: '이름 내림차순',
  zoneSnapshotEmpty: '현장상황이 없습니다',
  zoneSnapshotError: '오류가 발생했습니다. 다시 시도해주세요.',
  zoneSnapshotRetryButton: '재시도',
  zoneSnapshotAddButton: '현장상황 추가',
  zoneSnapshotEdit: '수정',
  zoneSnapshotDelete: '삭제',
  zoneBirdsEyeViewAddButton: '전경사진 추가',
  buildingListEmpty: 'MeiScan 앱을 이용해 새로 추가해보세요.',
  createZone: '새 구역 추가',
  createZoneDefaultName: '새로운 구역',
  zoneNameEditHelperText: '수정할 이름을 입력하세요.',
  zoneDeleteMessage: "'${n}'을 삭제하면 이 구역에 포함된 모든 현장상황 데이터가 함께 삭제됩니다. 정말 삭제하시겠습니까?",
  zoneName: '구역 이름',
  editName: '이름 수정',
  zoneDelete: '구역 삭제',
  zoneNameRestriction: '구역 이름은 ${n}자까지 가능합니다.',
  videoSnapshotCreatingTitle: '영상 현장상황 추가',
  videoSnapshotEditingTitle: '영상 현장상황 수정',
  videoSnapshotFileLabel: '영상 파일',
  videoSnapshotFilePlaceholder: '영상 파일을 선택해주세요.',
  videoSnapshotFileSizeError: '최대 영상 업로드 지원 용량을 초과했습니다. (1.5GB)',
  videoSnapshotFileFormatError: '지원하지 않은 파일 형식입니다. mp4, mov 파일을 업로드 해주세요.',
  videoSnapshotFileInfo: '최대 파일 용량: 1.5GB, 파일 형식: mp4, mov',
  panoramaSnapshotCreatingTitle: '파노라마 이미지 추가',
  panoramaSnapshotEditingTitle: '파노라마 이미지 수정',
  panoramaSnapshotFilePlaceholder: '360° 파노라마 파일을 선택해주세요.',
  panoramaSnapshotFileSizeError: '최대 360° 파노라마 업로드 지원 용량을 초과했습니다. (60MB)',
  panoramaSnapshotFileFormatError: '지원하지 않은 파일 형식입니다. jpg, jpeg, png 파일을 업로드 해주세요.',
  panoramaSnapshotFileInfo: '최대 파일 용량: 60MB, 파일 형식: jpg, jpeg, png',
  panoramaConverting: '이미지를 준비 중입니다.\n업로드 후 최대 1분 정도 소요될 수 있습니다.',
  panoramaDeleteTitle: '파일 삭제',
  panoramaDeleteMessage: '해당 파일을 삭제하시겠습니까?',
  birdsEyeViewCreatingTitle: '전경사진 추가',
  birdsEyeViewEditingTitle: '전경사진 수정',
  birdsEyeViewFileLabel: '전경사진',
  birdsEyeViewFilePlaceholder: '이미지 파일을 선택해주세요.',
  birdsEyeViewFileInfo: '최대 파일 용량: 50MB, 파일 형식: jpg, png',
  birdsEyeViewFileSizeError: '최대 전경사진 업로드 지원 용량을 초과했습니다. (50MB)',
  birdsEyeViewFileFormatError: '지원하지 않은 파일 형식입니다. jpg, png 파일을 업로드 해주세요.',
  snapshotZone: '구역',
  snapshotTakeDateLabel: '촬영 날짜',
  snapshotDescriptionLabel: '설명',
  snapshotDescriptionPlaceholder: '설명을 입력해주세요.',
  fileSnapshotCreatingButton: '추가',
  fileSnapshotEditingButton: '저장',
  fileSnapshotCreatingSuccess: '${n} 현장상황이 추가 완료되었습니다.',
  fileSnapshotEditingSuccess: '${n} 현장상황이 수정 완료되었습니다.',
  locationMarkerTooltip: '[${n1}] ${n2}(${n3}) - ${n4}',
  cadastralMarkerPopup: '주소: ${n1}<br>공시지가: ${n2}원<br>기준년월: ${n3}-${n4}<br>${n5}',
  cadastralMarkerDetails: '상세보기',
  cadastralEmptyPrice: '-',
  customizingTitleSngis: '(주) 충청',
  customizingTitleKolon: '코오롱글로벌',
  customizingTitleKcc: 'KCC E&C Platform',
  customizingTitleHdc: 'HDC 현대산업개발',
  customizingTitleLotte: '롯데건설',
  customizingTitleNami: '나미글로벌',
  customizingTitleDongbu: '동부건설',
  compensationStatusLabel: '보상 상태',
  compensationRequired: '보상 예정',
  compensationPending: '보상 진행 중',
  compensationDone: '보상 완료',
  compensationUndefined: '미정',
  count: '${n}개',
  laborerMarkerPopupName: '이름 : ${n}',
  laborerMarkerPopupPhone: '휴대폰 번호 : ${n}',
  laborerMarkerPopupLocation: '마지막 위치 : ${n}',
  laborerMarkerPopupLocationHistory: '이전 위치 시간 : ${n}',
  monthsAgo: '${n}개월 전',
  weeksAgo: '${n}주 전',
  hoursAgo: '${n}시간 전',
  minutesAgo: '${n}분 전',
  secondsAgo: '${n}초 전',
  compareSnapshotSliderTitle: '비교 슬라이더',
  compareSnapshotCompareTwoTitle: '2분할 비교',
  compareSnapshotCompareFourTitle: '4분할 비교',
  compareSnapshotMemoViewTitle: '현장 메모',
  compareSnapshotStandardDate: '기준 날짜',
  compareSnapshotCompareDate: '비교 날짜',
  snapshotDetailAddFileButton: '파일 추가',
  snapshotDetailAddingFileButton: '파일 추가 중',
  snapshotDetailAddButton: '${n} 추가',
  snapshotDetailAnnotationEmpty: '${n} 도구가 없습니다.',
  snapshotDetailHazardAreaEmpty: '현재 운영중인 위험구역이 없습니다.',
  snapshotDetailFileEmpty: '파일이 없습니다.',
  snapshotDetailFile: '파일',
  snapshotDetailAddFileMessage: '파일이 없습니다. 새로 추가해보세요.',
  snapshotDetailViewButton: '${n} 상세',
  layerViewAll: '전체보기',
  layerOpacity: '불투명도',
  layerOrthophotoTitle: '정사사진',
  layerDrawingTitle: '도면',
  layerAnnotationTitle: '지도 도구',
  layerDSMTitle: 'DSM/DTM',
  layerPointCloudTitle: '3D 점',
  layerMeshTitle: '3D 면',
  layerMarkerTitle: '위치',
  layerLineTitle: '선분',
  layerAreaTitle: '면적',
  layerHeightTitle: '높이',
  layerChainTitle: '토공 횡단선',
  layerPlanLevelTitle: '계획고',
  layerBimTitle: 'BIM',
  layerStratumTitle: '지층고',
  layerEtcTitle: '기타 파일',
  layerAerialImageTitle: '원본 이미지',
  layerIssueTitle: '이슈',
  layerPolygonVector: '도면 면적',
  editingPolygonVectorMessage: '도면 면적을 수정하면 기존에 생성된 토공물량표는 삭제됩니다.',
  accordionGroupAnnotationTitle: '지도 도구',
  accordionGroupComparedDataTitle: '비교 데이터',
  comparedDataTooltipMessage: '현재 날짜와 비교할 수 있습니다.',
  layerCadastralTitle: '지적도',
  layerGCPTitle: 'GCP',
  snapshotDetailGcpEditorButton: '확인',
  comparedCurrentDsm: '현황고와 비교',
  comparedCurrentDsmTooltipMessage: '현재 날짜의 고도와 비교하여 절성토량을 알 수 있습니다.',
  accordionGroupCurrentDataTitle: '현황 데이터',
  currentDataTooltipMessage: '현재 날짜를 더 자세히 알 수 있는 데이터나 \n실시간 데이터를 볼 수 있습니다.',
  layerHazardAreaTitle: '위험구역',
  layerLaborerTitle: '근로자 위치',
  layerCctvTitle: 'CCTV',
  layerPanoramaTitle: '360° 파노라마',
  layerNdviTitle: '식생지수',
  layerNdmiTitle: 'NDMI',
  layerThermalTitle: '열화상',
  layerGreenTitle: '그린',
  layerCourseTitle: '코스',
  openSecondaryMenu: '자세히 보기',
  tooltipTitleWhat: '무슨 기능인가요?',
  tooltipTitleHow: '어떻게 사용하나요?',
  tooltipMoreButton: '더 알아보기',
  layerChainTooltipWhat: '많은 선분 도구를 한번에 추가해서, 토공 진척을 용이하게 확인하고 캐드파일(.dxf)로 일괄 다운로드 할 수 있습니다.',
  layerChainTooltipHow: 'csv 파일을 양식에 맞게 업로드합니다. 생성된 선분들의 단면도 그래프를 확인할 수 있습니다.',
  layerPlanLevelTooltipWhat: '계획된 지반의 tif 파일을 업로드 하시면 수치표면모델과 동일하게 계획고 고도를 확인할 수 있습니다.',
  layerPlanLevelTooltipHow: '[현황고와 비교] 토글을 통해 해당 날짜의 지반고와 계획고를 비교하여 계획고에 맞춰 필요한 절/성토 물량을 계산할 수 있습니다.',
  layerStratumTooltipWhat: '매립된 지층 별 고도를 확인 하실 수 있습니다.',
  layerStratumTooltipHow: '지층고를 업로드 하신 후 선분도구를 생성하시면, 지층 별 높이 값을 개별로 확인하거나 겹쳐서 확인할 수 있습니다.',
  layerCadastralTooltipWhat: '국토교통부에서 제공하는 지적 정보를 열람할 수 있습니다.',
  layerCadastralTooltipHow: '지도 내 구획된 공간을 클릭하시면 공시지가와 함께, 공사 중 토지보상 진행사항을 체크할 수 있습니다.',
  layerHazardAreaTooltipWhat: '예상 위험 작업의 내용, 시간, 위치를 지정할 수 있는 기능입니다. ',
  layerHazardAreaTooltipHow: 'Meissa Guard 앱과 함께 사용하시면 실제 작업자들의 진출입 내역을 확인하거나 휴대전화로 직접 위험 알림을 줄 수 있습니다. ',
  layerLaborerTooltipWhat: 'Meissa Guard 앱에서 체크인한 근로자의 위치 기록을 한 달간 기록하고 열람할 수 있습니다.',
  layerLaborerTooltipHow: '근로자의 휴대전화에 Meissa Guard 앱을 설치한 후, 체크인을 위해 [프로젝트 정보] 메뉴에서 QR코드를 제공해주세요.',
  layerIssueTooltipWhat: '공사 중 발견한 문제를 지도 위에 표시하고, 문제가 해결 될 때까지 소통하여 기록하기 위한 기능입니다.',
  layerIssueTooltipHow: '이름과 설명을 입력해서 이슈를 만들어보세요. 이슈 안에서 댓글로 팀원들과 소통할 수 있습니다. ',
  drawingTooltip: '도면을 자동으로 설정하면, 데이터 안의 좌표 데이터를 기반으로 지도에 표시합니다. 도면을 수동으로 설정하면, 지도 위에 이미지화된 도면의 위치를 직접 지정합니다.',
  snapshotDetailDownload: '다운로드',
  snapshotDetailDelete: '삭제',
  accordionGroupEtcFilesTitle: '기타',
  etcFilesTooltipMessage: '이 프로젝트에 속한 기타 파일을\n업로드, 다운로드 할 수 있습니다.',
  resourceAddButton: '새로 추가',
  resourceAddFolder: '새 폴더',
  resourceAddFile: '파일 업로드',
  resourceListLoadingMessage: '목록을 불러오는 중입니다.',
  snapshotItemUndefinedPosition: '위치 없음',
  snapshotCctvFullScreen: '전체화면',
  snapshotCctvIsEmpty: '등록된 CCTV가 없습니다.',
  snapshotCctvAngleLabel: '각도',
  snapshotCctvPreviewButton: '미리보기',
  snapshotCctvPreviewTitle: 'CCTV 미리보기',
  folderNameEnter: '폴더 이름을 입력해주세요',
  folderNameDuplicate: '이미 사용중인 폴더 이름입니다.',
  folderCreateButton: '만들기',
  folderEmptyMessage: '빈 폴더입니다. 새 파일을 추가하거나 기존 파일을 연동해주세요.',
  folderAddFile: '이 폴더에 파일 추가',
  folderAddAnnotation: '이 폴더에 ${n} 추가',
  folderMove: '폴더로 이동',
  folderMoveToAnother: '다른 폴더로 이동',
  folderMoveTitle: '이동할 위치 선택',
  folderMoveButton: '이 폴더로 이동',
  folderMoveButtonError: '이동할 폴더가 없습니다.\n폴더를 추가한 후 이동하세요.',
  folderMoveErrorMessage: '이미 이 폴더 안에 있습니다.',
  folderCurrentLocation: '현재 위치',
  rename: '이름 변경',

  /* cctv */
  cctvCreationPopupTitle: 'CCTV 추가',
  cctvCreationPopupLabelName: 'CCTV 이름',
  cctvCreationPopupLabelRtspUrl: 'RTSP URL',
  cctvCreationPopupLabelRtspUrlExample: '예) rtsp://0.0.0.0:554/live/cctv001.stream',
  cctvCreationPopupLabelRtspId: 'RTSP ID',
  cctvCreationPopupLabelRtspPw: 'RTSP 비밀번호',
  cctvCreationPopupCopyFieldPlaceholder: '입력하신 정보로 자동 조합됩니다.',
  cctvCreationPopupGuideButtonTitle: 'CCTV 추가 매뉴얼',
  cctvDetailSideAreaLabelLocation: '위치',
  cctvDetailSideAreaButtonAddLocation: '위치 지정',
  cctvDetailSideAreaLabelRtspUrl: 'RTSP URL',
  cctvDetailSideAreaLabelRtspId: 'RTSP ID',
  cctvDetailSideAreaLabelRtspPw: 'RTSP 비밀번호',
  cctvDeleteConfirmTitle: 'CCTV 삭제',
  cctvDeleteConfirmMessage: '해당 CCTV가 영구히 삭제됩니다. 정말로 삭제하시겠습니까?',
  cctvPlayerSelectPlaceholder: 'CCTV 선택',
  cctvPlayerButtonSplit4: '4분할 비교',
  cctvPlayerButtonSplit9: '9분할 비교',
  resetPositionLabel: '위치 초기화',
  resetPositionAlertTitle: '위치 초기화',
  resetPositionAlertMessage: '해당 ${n}의 위치가 삭제됩니다. \n정말 위치를 초기화하시겠습니까?',
  resetPositionAlertButton: '위치 초기화',
  drawingManual: '수동',
  drawingAutomatic: '자동',
  drawingPositioning: '위치 지정',
  drawingPositioningEmptyMessage: '위치가 지정되지 않아 볼 수 없습니다.',
  drawingConvertingErrorMessage: '변환할 수 없는 파일입니다.',
  drawingConvertingCancelMessage: '도면 변환이 취소되었습니다. 다시 시도해주세요.',
  QCAD1000: '변환할 수 없습니다. 파일을 다시 확인해주세요.',
  markerAnnotationImageSubtitle: '원본 이미지',
  annotationNameHint: '${n} 이름',
  positionAreaCoordinateSystem: '좌표',
  positionAreaTotal: '총',
  measureVolume: '부피 계산',
  measureVolumeTooltip: '선택한 면적 지점을 기준으로 부피를 계산해, \n절성토량을 알 수 있습니다.',
  totalVolume: '전체 면적',
  measureTypeTriangular: '삼각분할',
  measureTypeTriangularTooltip: '사용자가 지정한 포인트를 이어 삼각망을 형성해 밑면으로 생성합니다. 이를 기준으로 위는 절토량, 아래는 성토량입니다.',
  measureTypeTriangularTooltipImage: 'https://meissa-frontend-public.s3.ap-northeast-2.amazonaws.com/image/info-image_triangulation_ko.png',
  measureTypeTriangularTooltipLink: 'https://meissasaas.zendesk.com/hc/ko/articles/11042092378383-2-4-3-%EB%A9%B4%EC%A0%81%EB%8F%84%EA%B5%AC',
  measureTypeDate: '비교 날짜',
  measureTypeDateTooltip: '서로 다른 두 날짜 사이의 체적 변화량을 계산합니다. B 날짜 대비 A 날짜의 절/성토량을 알 수 있습니다.',
  measureTypeDateTooltipImage: 'https://meissa-frontend-public.s3.ap-northeast-2.amazonaws.com/image/info-image_comparison-date_ko.png',
  measureTypeDateTooltipLink: 'https://meissasaas.zendesk.com/hc/ko/articles/11042092378383-2-4-3-%EB%A9%B4%EC%A0%81%EB%8F%84%EA%B5%AC',
  measureTypeHeight: '높이 입력',
  measureTypeHeightTooltip: '직접 입력한 고도를 기준으로 생성한 수평면이 기준 밑면입니다. 이 수평면을 기준으로 위는 절토량, 아래는 성토량입니다.',
  measureTypeHeightTooltipImage: 'https://meissa-frontend-public.s3.ap-northeast-2.amazonaws.com/image/info-image_height_ko.png',
  measureTypeHeightTooltipLink: 'https://meissasaas.zendesk.com/hc/ko/articles/11042092378383-2-4-3-%EB%A9%B4%EC%A0%81%EB%8F%84%EA%B5%AC',
  measureTypePlan: '계획고',
  measureTypePlanTooltip: '계획고와 현재 날짜의 고도를 비교해 절성토량을 알 수 있습니다. 계획고를 기준으로 위는 절토량, 아래는 성토량입니다.',
  measureTypePlanTooltipImage: 'https://meissa-frontend-public.s3.ap-northeast-2.amazonaws.com/image/info-image_design-level_ko.png',
  measureTypePlanTooltipLink: 'https://meissasaas.zendesk.com/hc/ko/articles/11042092378383-2-4-3-%EB%A9%B4%EC%A0%81%EB%8F%84%EA%B5%AC',
  measure: '계산',
  measuring: '계산 중',
  insertReferenceHeight: '기준 높이 입력',
  insertReferenceHeightEmpty: '높이를 입력해주세요',
  uploadDsm: '수치표면모델을 업로드하면 계산할 수 있습니다.',
  volumeErrorMessage: '오류가 발생했습니다.',
  volumeCutSumLabel: '절토계',
  volumeCutSumDescription: '(절토-성토)',
  volumeFillSumLabel: '성토계',
  volumeFillSumDescription: '(성토-절토)',
  calculatingVolumetricComparedToDesignLevel: '물량 변환 중입니다. 20분 이상 소요될 수 있습니다.',
  volumeComparedToSnapshotInfoMessage: '${n1} 대비 ${n2}의 부피를 계산한 절성토량입니다.',
  annotationDescriptionEmpty: '설명을 입력해주세요',
  hazardAreaDescriptionEmpty: '${n}자 이내로 입력해주세요',
  markerImageEmpty: '이미지가 없습니다.',
  markerImageCanAdd: '이미지가 없습니다. 새로 추가해보세요.',
  hazardAreaDateSubtitle: '일시 설정',
  hazardAreaStartDate: '시작 일시',
  hazardAreaEndDate: '종료 일시',
  hazardAreaEndDateRequired: '종료 일시를 선택해주세요',
  hazardAreaEndDateBeforeStartDate: '시작 일시보다 이후의 시간을 선택해주세요',
  hazardAreaTooltip: '만료된 위험구역은 자동으로 삭제됩니다. \n근로자 위치에서 만료된 위험구역을 확인하세요',
  laborerLocationTooltip: 'Meissa Guard 앱에서 체크인한 근로자의 실시간 위치를 확인합니다.',
  memberLocationTooltip: 'Meissa Lite 앱에서 체크인한 관리자의 실시간 위치를 확인합니다.',
  hazardAreaTooltipStartDate: '시작 일시 : ${n}',
  hazardAreaTooltipEndDate: '종료 일시 : ${n}',
  hazardAreaTooltipDescription: '설명 : ${n}',
  hazardEventHistoryButton: '위험구역 히스토리',
  hazardEventHistoryTypeAllHistory: '전체',
  hazardEventHistoryTypeHazardIn: '위험',
  hazardEventHistoryTypeAlarmOff: '안전',
  hazardEventHistoryTypeStart: '생성',
  hazardEventHistoryTypeEnd: '만료',
  hazardEventHistoryAreaListOptionAll: '위험구역 전체',
  hazardEventHistoryTitleHazardIn: '근로자 위험',
  hazardEventHistoryTitleAlarmOff: '근로자 안전',
  hazardEventHistoryTitleStart: '위험구역 생성',
  hazardEventHistoryTitleEnd: '위험구역 만료',
  hazardEventHistoryDescriptionHazardIn: '${n1}님이 ${n2}에 진입했습니다.',
  hazardEventHistoryDescriptionAlarmOff: '${n1}님이 ${n2}에서 안전 구역으로 이동했습니다.',
  hazardEventHistoryDescriptionStart: '${n1}님이 ${n2}을 생성했습니다.',
  hazardEventHistoryDescriptionEnd: '${n}이 만료됐습니다.',
  hazardEventHistoryListEmptyMessage: '기록된 위험구역 히스토리가 없습니다.',
  calculateElevationOfChainListWithDsm: '현황고 계산',
  calculateElevationOfChainListWithPlanLevel: '계획고 계산',
  calculationStatusForChainList: '대기:${n1} 진행중:${n2} 오류:${n3} 완료:${n4}',
  calculationIsProgressingMessage: '계산 중입니다. 수 분 가량 소요될 수 있습니다. \n(페이지를 이탈해도 계산은 진행됩니다.)',
  calculationIsErrorMessage: '계산에 실패했습니다. 다시 시도해주세요',
  calculatingButton: '계산 중',
  retryCalculationButton: '계산 재시도',
  calculationButton: '계산하기',
  downloadChainListFileButton: '그래프 파일 다운로드',
  downloadChainListFileDescription: '계산된 토공횡단선만 DXF로 다운로드 합니다.',
  selectPlanLevelPlaceholder: '계획고를 선택하세요',
  selectEmptyPlanLevelPlaceholder: '파일이 없습니다.',
  selectNonePlanLevel: '선택 안함',
  downloadStatusForChainList: '오류:${n1} 완료:${n2}',
  downloadChainListAlertMessage: '계산 완료된 ${n}개의 토공횡단선이 다운로드 됩니다. \n취소를 누르면 지금까지 변환된 내용이 사라집니다. \n잠시만 기다려주세요.',
  downloadChainListAlertTitle: '그래프 파일을 생성하여 다운로드 중입니다.',
  downloadChainListCancelModalTitle: '그래프 파일 다운로드 취소',
  downloadChainListCancelMessage: '토공횡단선 DXF파일 생성중입니다. \n작업을 중단하시겠습니까?',
  downloadChainListKeepGoingButton: '계속하기',
  downloadChainListStopButton: '중단하기',
  downloadChainListWithStratum: '다운로드 시, 연동된 지층고 그래프가 포함됩니다.',
  polygonVectorLayerListTitle: '도면 면적 레이어',
  earthworkTableEntryButton: '토공물량표 생성',
  earthworkTableNoPlanLevelOption: '계획고 없음',
  earthworkTableCalculationButton: '반출량 계산',
  earthworkTableExportButton: '엑셀 파일로 내보내기',
  earthworkTableExportButtonCaption: ' 모든 물량 단위는 ${n}입니다.',
  earthworkTableExportingMessage: '엑셀 파일을 생성 중입니다. 잠시만 기다려주세요.',
  earthworkTableExportingErrorMessage: '파일이 생성되지 않았습니다. 다시 시도해주세요.',
  earthworkTableExportingSuccessMessage: '파일이 완성되었습니다.',
  earthworkTableLabelCut: '절토',
  earthworkTableLabelFill: '성토',
  earthworkTableLabelCutSum: '절토 소계',
  earthworkTableLabelFillSum: '성토 소계',
  earthworkTableLabelSoil: '토사',
  earthworkTableLabelTotal: '합계',
  earthworkTableHeadAnnotation: '면적',
  earthworkTableHeadIsCutOrFill: '구분',
  earthworkTableHeadStratumType: '성상 구분',
  earthworkTableHeadTotalAmount: '전체 수량(㎥)',
  earthworkTableHeadAmountComparedWithPastDate: '이전 날짜 대비 반출량 (m³)',
  earthworkTableHeadPlanedAmount: '계획 수량',
  earthworkTableHeadAmountLeft: '잔여토공량',
  earthworkTableHeadSumAmountDayByDay: '일자별 합계',
  earthworkTableEmptyValueWarning: '입력되지 않은 계획 수량은 0으로 계산됩니다.',
  earthworkTableNotTmsError: '2023년 6월 1일 이전에 분석한 수치표면모델, 계획고, 지층고로 반출량을 계산할 수 없습니다. 계산하시려면 고객센터나 help@meissa.ai로 문의해주세요.',
  earthworkTableNoPlanLevelError: '계획고가 없어 반출량을 계산할 수 없습니다.',
  earthworkTableVolumeListError: '표를 불러올 수 없어 반출량을 계산할 수 없습니다.',
  earthworkTableExcludeSnapshotHasNoDsmWarning: '수치표면모델이 없는 현장상황은 반출량을 계산할 수 없어 제외됩니다.',
  earthworkTableCalculating: '페이지를 이탈해도 계산이 진행됩니다.',
  earthworkTableCalculatingError: '오류가 발생했습니다. 관련 데이터를 다시 확인해주세요.',
  earthworkTableCalculatingSuccess: '모든 계산이 완료되었습니다.',
  earthworkTableLoadingView: '표 만드는 중',
  earthworkTablePlanedVolumeErrorView: '계획 수량을 불러오던 중 오류가 발생했습니다. \n페이지를 새로고침 해주세요.',
  earthworkTableCreatingErrorView: '표를 불러오던 중 오류가 발생했습니다. \n페이지를 새로고침 해주세요.',
  earthworkTableEmptyView: '계획고를 업로드해주세요.',
  earthworkTableResourceConversion: '계획고나 지층고가 변환 중이므로 반출량을 계산할 수 없습니다.',
  earthworkTableFileLinkingAlert: '반출량 계산에 필요한 파일이 연동되지 않은 현장상황이 있습니다. 계산을 위해 해당 파일을 자동으로 연동합니다.',
  earthworkTableCellPlaceholder: '입력',
  earthworkTableCellCalculating: '계산 중...',
  earthworkTableCellCalculatingError: '계산 오류',
  earthworkTableCellCalculatingRequired: '계산 필요',
  earthworkTableDateFormat: 'yy.MM.dd',
  adjustingBounds: '인쇄 영역 지정 중입니다',
  resetBounds: '영역 초기화',
  print: '인쇄',
  generatingPrintFile: '파일을 생성 중입니다. 잠시만 기다려주세요.',
  fileName: '파일 이름',
  printFormat: '파일 형식',
  printResolution: '해상도',
  printHighResolution: '고해상도',
  printLowResolution: '저해상도',
  downloadPrintFile: '파일 다운로드',
  generatePrintFile: '인쇄 파일 생성',
  printFileError: '파일이 생성되지 않았습니다. 다시 시도해주세요.',
  printFileSuccess: '파일이 완성되었습니다.',
  printHighResolutionInfo: '고해상도 파일은 현장 크기에 따라 5분 이상 소요될 수 있습니다.',
  controlPointSize: '점 크기 조절',
  togglePointToMesh: '면으로 보기',
  helperControlPointSize: '점이 클수록 사각형 타일 형태로 채워지며, 상태 \n굴곡면을 더 쉽게 확인할 수 있습니다.',
  helperTogglePointToMesh: '3D 모델을 Mesh 데이터로 봅니다.',
  tiffFileConverting: '파일을 변환하는 중입니다. 파일 용량과 현장 크기에 따라 20분 이상 소요될 수 있습니다.',
  tiffFileConversionError: '변환할 수 없는 파일입니다. 파일 삭제 후 다시 시도해주세요.',
  compareBim: 'BIM과 비교',
  compareBimTooltipMessage: '3D 점과 BIM을 동시에 확인합니다. BIM은 수동 위치 조정이 \n가능하며 우측 상단에 "BIM 위치 조작 가이드"를 참고바랍니다.',
  emptyBimFile: 'BIM 파일이 없습니다',
  bimOnPointCloudAlert: '좌표 정보가 없는 BIM 파일은 임의로 정가운데에 배치됩니다.',
  bimControlAlert: '수동 조작된 BIM의 위치는 자동으로 저장됩니다.',
  bimControlGuide: 'BIM 위치 조작 가이드',
  bimTranslateUnit: '이동 (${n}m)',
  bimUpDownUnit: '상하 이동 (${n}m)',
  bimRotateUnit: '좌우 회전 (${n}°)',
  bimDetailTranslateUnit: '세부 이동 (${n}cm)',
  bimDetailUpDownUnit: '세부 상하 이동 (${n}cm)',
  bimDetailRotateUnit: '세부 좌우 회전 (${n}°)',
  bimControlInfo: 'BIM 객체 선택 후 키보드로 이동 가능합니다.',
  bimManualControl: '수동',
  meshOnPointcloud: '3D 면과 함께 보기',
  meshOnPointcloudTooltip: '3D 점과 면을 동시에 확인합니다.',
  threeDTileLoadErrorMessage: '파일을 불러오는 도중 오류가 발생했습니다. 새로고침 후 다시 시도해주세요.',
  elevationSubTitle: '단면도 그래프',
  helperElevationGraph: '날짜별 고도, 계획고, 지층고를 그래프로 한눈에 비교합니다.',
  selectFile: '파일 선택',
  fileLinking: '연동 ${n}',
  stratumMaxLinkingCount: '(최대 ${n}개)',
  fileStorage: '파일 보관함',
  fileLinkingManagement: '연동 관리',
  fileLinkingPointManagement: '위치 연동 관리',
  fileLinkingPolylineManagement: '선분 연동 관리',
  fileLinkingPolygonManagement: '면적 연동 관리',
  fileLinkingDrawingManagement: '도면 연동 관리',
  hazardAreaHistory: '위험구역 히스토리',
  fileStorageEmptyDescription: '연동할 수 있는 파일이 없습니다.\n도구나 파일을 새로 추가해보세요.',
  unlinkRelationButton: '연동 해제',
  fileLinkingStratumMaxNumberErrorMessage: '지층고는 최대 5개까지 연동 가능합니다.',
  laborer: '실시간 위치',
  laborerLocation: '근로자 위치',
  laborerLocationListEmpty: '근무 중인 근로자가 없습니다.\nMeissa Guard 앱에 체크인한 근로자 위치를 볼 수 있습니다.',
  laborerLocationHistoryListEmpty: '근로자의 이전위치 기록이 없습니다.\n다른 일자를 확인해주세요.',
  laborerLocationHistoryListTimeEmpty: '근로자의 이전위치 기록이 없습니다.\n다른 시간을 확인해주세요.',
  laborerLocationListEmptyForPrimary: '근무 중인 근로자가 없습니다.',
  laborerLocationHistoryListEmptyForPrimary: '근로자의 이전위치 기록이 없습니다.',
  laborerVulnerable: '취약',
  laborerPaused: '위치 공유 중단됨',
  laborerTabLocationList: '실시간',
  laborerTabLocationHistoryList: '이전 위치 기록',
  laborerViewHazardArea: '이전 위험구역 함께보기',
  laborerViewHazardAreaTooltip: '이전 시점에 운영된 위험구역과 함께 봅니다.',
  laborerSliderAlert: '재생 가능한 구간이 아닙니다.',
  laborerDatePickerFormat: 'PPP',
  laborerGpsErrorSnackbarMessage: '현장 환경에 따라 지도 상의 위치와 실제 근로자 위치가 차이날 수 있습니다.',
  getFileFromStorage: '보관함에서 가져오기',
  showFileListInStorage: '보관함에서 확인',
  addUploadFileRow: '파일 행 추가',
  fileStorageDeleteResourceTitle: '파일 삭제',
  fileStorageDeleteResourceMessage: '${n}개의 파일 삭제 시, 전체 현장상황에서 파일이 영구히 삭제됩니다. 정말 삭제하시겠습니까?',
  fileStorageDeleteFileAlertMessage: '파일 삭제 시, 전체 현장상황에서 파일이 영구히 삭제됩니다. 정말 삭제하시겠습니까?',
  fileStorageDeleteResourceConfirmButton: '삭제',
  fileStorageDeleteResourceCancelButton: '취소',
  fileStorageDeleteAnnotationTitle: '지도 도구 삭제',
  fileStorageDeleteAnnotationMessage: "'${n}' 지도도구 삭제 시, 전체 현장상황에서 영구히 삭제됩니다. 정말로 삭제하시겠습니까?",
  fileStorageDeleteAnnotationConfirmButton: '삭제',
  fileStorageDeleteAnnotationCancelButton: '취소',
  hazardAreaDeleteTitle: '위험구역 삭제',
  hazardAreaDeleteMessage: '‘${n}’ 삭제 시, 이전 위치 기록에서도 삭제됩니다.\n정말로 삭제하시겠습니까?',
  uploadPopupStratumFiles: '지층고 파일 추가',
  uploadPopupEtcFiles: '기타 파일 추가',
  uploadPopupBimFiles: 'BIM 파일 추가',
  uploadPopupPlanLevelFiles: '계획고 파일 추가',
  uploadPopupChainFiles: '토공 횡단선 파일 추가',
  uploadPopupNdviSegmentationFiles: '식생지수(구역) 파일 추가',
  uploadPopupBimDescription: '좌표 정보가 없거나 지도 영역을 벗어난 파일은 임의로 정가운데에 배치됩니다.',
  uploadPopupSuperResolutionFiles: '초해상화(SR) 파일 추가',
  fileUploadExtensionPolicy: '파일 형식: ${n}',
  fileUploadSizePolicy: '최대 파일 용량: ${n}',
  fileUploadAllFileExtension: '모든 파일 형식',
  fileUploadSizeErrorMessage: '${n} 이하인 파일만 업로드 가능합니다.',
  fileUploadCountErrorMessage: '파일 업로드는 한번에 ${n}개까지 가능합니다.',
  fileUploadExtensionErrorMessage: '확장자가 ${n}인 파일만 업로드 가능합니다.',
  fileSizeNoLimit: '제한 없음',
  fileUploadFailMessage: '업로드에 실패한 파일이 있습니다. 다시 시도해주세요.',
  uploadPopupStratumAliasPlaceholder: '지층고 이름',
  uploadPopupStratumFilePlaceholder: '지층고 파일을 선택해주세요.',
  drawingUploadPopupTitle: '도면 파일 추가',
  drawingTabTypeManual: '자동 배치',
  drawingTabTypeAutomatic: '수동 배치',
  drawingSelectFileTitle: '도면 선택',
  drawingAutoTypePolicy: '파일 형식: dxf, 최대 파일 용량 : ${n} MB',
  drawingManualTypePolicy: '파일 형식: png, jpg, pdf, 최대 파일 용량 : ${n} MB',
  drawingManualInfo: '좌표 데이터가 없는 이미지를 사용자가 직접 배치합니다.\n수동 배치로 오차가 생길 수 있지만 초기 변환과 활용이 가볍고 빠릅니다.',
  drawingAutoRasterInfo: '도면을 이미지화하여 표시합니다. [벡터]에 비해 초기 변환이 느리지만, 활용할 때 가볍고 빠릅니다.',
  drawingAutoVectorInfo: '레이어를 그리듯이 표시합니다. [래스터]에 비해 초기 변환이 빠르지만, 성능이 좋지 않은 기기에서 다소 느릴 수 있습니다. Meissa Flight 앱에서는 표시되지 않습니다.',
  drawingSelectCRSTitle: '좌표계 설정',
  drawingSelectDisplayTitle: '표시 방법',
  drawingRasterRadioLabel: '자동 래스터',
  drawingVectorRadioLabel: '자동 벡터',
  drawingFileUploadButton: '업로드',
  autoRasterDrawingConverting: '도면을 변환하는 중입니다. 수 분 가량 소요될 수 있습니다. (페이지를 이탈해도 변환은 진행됩니다.)',
  autoRasterDrawingEnhancing: '고해상도로 변환 중입니다. (페이지를 이탈해도 변환은 진행됩니다.)',
  autoVectorDrawingRendering: '도면을 지도에 표시하는 중입니다. ${n1}/${n2}',
  drawingConvertButton: '변환',
  drawingAutoVectorChip: '자동 벡터',
  drawingAutoRasterChip: '자동 래스터',
  drawingManualChip: '수동 배치',
  drawingsUploadSuccess: '업로드가 완료되었습니다.',
  drawingsUploadError: '오류가 발생했습니다. 다시 시도해주세요.',
  drawingsUploadLoading: '업로드 중입니다. 잠시만 기다려주세요.',
  projectCRS: '프로젝트 좌표계',
  drawingFileMaxSizeAlert: '${n}MB 이하인 파일만 업로드 가능합니다.\n',
  manualDrawingFileFormateAlert: '수동 배치는 dxf, png, jpg, pdf 파일만 업로드 가능합니다.',
  autoDrawingFileFormateAlert: '자동 배치는 dxf 파일만 업로드 가능합니다.',
  snapshotPanoramaEmptyMessage: '파일이 없습니다. \n파일을 새로 추가해보세요.',
  snapshotPanoramaEmptyMessageForPrimary: '파일이 없습니다.',
  snapshotPanoramaErrorMessage: '오류가 있습니다.\n다시 시도해 주세요.',
  snapshotPanoramaViewerButton: '크게 보기',
  imageSnapshotEditTitle: '이미지 현장상황 수정',
  snapshotProcessingTypeLabel: '현장상황 유형',
  processingTypeLabel: '플랫폼에서 분석',
  preprocessedTypeLabel: '분석된 파일 업로드',
  snapshotEngineLabel: '분석 엔진',
  imageSnapshotEditChangeZoneConfirmTitle: '구역 변경',
  imageSnapshotEditChangeZoneConfirmMessage: '구역을 변경하면 기존 구역 데이터 연동은 모두 해제되고, 변경될 구역 데이터와 연동됩니다.\n정말 구역을 변경하시겠습니까?',
  snapshotFormPageTitle: '현장상황 추가',
  snapshotFormPageEditTitle: '현장상황 수정',
  snapshotFormPrevButton: '이전',
  snapshotFormNextButton: '다음',
  snapshotFormExitButton: '나가기',
  snapshotFormRequiredCaption: '(필수)',
  snapshotFormRecommendedCaption: '(권장)',
  snapshotFormInfoTitle: '현장상황 정보',
  snapshotFormInfoCaption: '필요한 정보를 입력합니다. (필수)',
  snapshotFormFileUploadTitle: '분석된 파일 업로드',
  snapshotFormFileUploadCaption: '엔진 분석된 파일을 업로드합니다. (필수)',
  snapshotFormImageUploadTitle: '원본 이미지 업로드',
  snapshotFormImageUploadCaption: '드론으로 촬영한 이미지를 선택하고 업로드합니다. ${n}',
  snapshotFormGcpUploadTitle: 'GCP 마킹',
  snapshotFormGcpUploadCaption: '원본 이미지와 매칭하여 3D 모델을 더욱 정확하게 합니다. ${n}',
  snapshotFormProcessTitle: '분석 시작',
  snapshotFormProcessCaption: '업로드한 파일로 분석을 시작합니다.',
  snapshotFormOrthophotoLabel: '정사사진',
  snapshotFormDsmLabel: '수치표면모델',
  snapshotFormPointCloudLabel: '3D 점 (Point Cloud)',
  snapshotFormMeshLabel: '3D 면 (Mesh)',
  snapshotFormFileFormatText: '파일 형식: ${n}',
  snapshotFormMeshFileFormatText: '파일 형식: ${n1}, 최대 파일 용량:${n2}MB',
  snapshotFormMeshMaxFileSizeError: '${n}MB 이하인 파일만 업로드 가능합니다.',
  snapshotFormLoadingViewText: '조회 중입니다. 잠시만 기다려주세요.',
  snapshotFormFileMinCountAlert: '최소 업로드 개수는 ${n}개 입니다.',
  snapshotFormFileMaxCountAlert: '최대 업로드 개수는 ${n}개 입니다.',
  snapshotFormImageUploadLabel: '원본 이미지 업로드',
  snapshotFormImageMinCountText: '업로드는 최소 30장 이상부터 가능합니다.',
  snapshotFormImageWithoutGpsAlert: '위치 정보가 없는 이미지는 업로드할 수 없습니다.',
  snapshotFormImageCompressError: '압축 중 오류가 발생했습니다. 다시 시도해주세요.',
  snapshotFormImageCompressSuccess: '이미지 압축이 완료되었습니다. 다운로드 버튼을 눌러주세요.',
  snapshotFormImageCompressLoading: '업로드된 이미지를 압축 중입니다. 잠시만 기다려주세요.',
  snapshotFormImageUploadSuccess: '업로드가 완료되었습니다.',
  snapshotFormImageUploadError: '업로드 실패한 파일이 있습니다. 다시 시도해주세요.',
  snapshotFormImageUploadMaxCountAlert: '최대 업로드 개수는 ${n}개 입니다. 파일 개수를 확인해주세요.',
  snapshotFormMapTitle: '미리보기',
  snapshotFormMapCaptionForImage: '선택된 이미지가 알맞은 파일인지 확인해주세요.',
  snapshotFormImageArchiveLoadingButton: '압축 중',
  snapshotFormImageArchiveDownloadButton: '압축 파일 다운로드',
  snapshotFormGcpUploadLabel: 'GCP 파일 업로드',
  snapshotFormGcpDownloadButton: '다운로드',
  snapshotFormGcpFileGuideText: '파일 형식: csv, txt\nGCP 지점: 최소 3개 이상 입력',
  snapshotFormFileFormatGuideButton: '업로드 가이드',
  snapshotFormGcpCoordinateLabel: '좌표계 선택',
  snapshotFormGcpDisabledCoordinate: '좌표계를 변경하시려면 GCP 파일을 삭제 후 다시 업로드해주세요.',
  snapshotFormGcpLocationWarningAlert: '${n}개의 GCP지점이 현장 범위를 벗어나 있습니다.',
  snapshotFormGcpCorrectionLabel: 'GCP 마킹',
  snapshotFormGcpCorrectionCaption: '3D 모델을 더욱 정확하게 하기 위해 GCP를 원본 이미지와 매칭합니다.',
  snapshotFormGcpCorrectionButton: 'GCP 마킹',
  snapshotFormProcessButton: '분석 시작',
  snapshotFormProcessingButton: '분석 중',
  snapshotFormProcessingText: '원본 이미지 매수와 현장 면적에 따라 최대 24시간이 소요됩니다. \n분석이 완료되면 메일로 알려드립니다.',
  snapshotFormPauseButton: '분석 중단',
  snapshotFormPauseConfirmTitle: '분석 중단',
  snapshotFormPauseConfirmMessage: '지금까지 분석된 내용이 사라집니다. \n분석을 중단하시겠습니까?',
  snapshotFormPauseConfirmPrimaryButton: '분석 중단',
  snapshotFormPauseConfirmSecondaryButton: '계속하기',
  snapshotFormPauseSuccessMessage: '분석을 취소했습니다.',
  snapshotFormProcessDoneMessage: '분석이 완료된 현장상황입니다.',
  snapshotFormResultInfoMessage: '분석 완료 후, 4가지 분석 결과 데이터를 활용할 수 있습니다.',
  snapshotFormImagesNeedToBeMatchedToGcpTitle: 'GCP마커 분석',
  snapshotFormImagesNeedToBeMatchedToGcp: 'GCP 마커가 추가되지 않았습니다.\n이대로 분석하시겠습니까?',
  snapshotFormImagesNeedToBeMatchedToGcpPrimaryButton: '마커 추가',
  snapshotFormImagesNeedToBeMatchedToGcpSecondaryButton: '분석하기',
  snapshotFormGoGcpMarkPage: 'GCP 마킹으로 이동',
  snapshotFormMapToolLinkTitle: '지도 도구 연동 선택',
  snapshotFormMapToolLinkContent: '가장 최근 분석된 ${n} 현장상황에 연동된 위치, 선분, 면적 도구를 똑같이 연동합니다.',
  snapshotFormMappedImageCountIsNotRecommendedModalTitle: '현장상황 분석',
  snapshotFormMappedImageCountIsNotRecommendedModalContent: '마킹한 이미지 수가 5장 이하일 경우 분석 퀄리티가 낮아질 수 있습니다. 이대로 분석을 시작하시겠습니까?',
  snapshotFormMappedImageCountIsNotRecommendedModalSecondaryButton: 'GCP 마킹으로 이동',
  snapshotFormMappedImageCountIsNotRecommendedModalPrimaryButton: '분석 시작',

  /* 현장 이슈 관리 */
  mediaViewerDetailWriter: '작성자',
  mediaViewerDetailUploadDate: '업로드',
  mediaViewerDetailFileCreationTime: '촬영일시',
  issueMediaFileExpand: '크게보기',
  doneIssueListTitle: '완료된 이슈 (${n})',
  issueEmptyMessage: '이슈가 없습니다.',
  issueAddButton: '이슈',
  issueFileUploadNumberIssue: '한번에 ${n}개의 파일만 업로드할 수 있습니다.',
  issueCommentPlaceholder: '내용을 입력하세요.',
  doneIssueCommentPlaceholder: '댓글을 입력하려면 이슈를 진행중으로 바꿔주세요.',
  deleteMainImageConfirmTitle: '대표 이미지 삭제',
  deleteMainImageConfirmMessage: '대표 이미지가 영구히 삭제됩니다. 정말로 삭제하시겠습니까?',
  issueRunning: '진행중',
  issueDone: '완료됨',
  issueNameHint: '이름을 입력하세요 (필수)',
  issueDescriptionHint: '설명을 입력하세요 (필수)',
  deleteCommentConfirmTitle: '댓글 삭제',
  deleteCommentConfirmMessage: '댓글이 영구히 삭제됩니다. 정말로 삭제하시겠습니까?',
  deleteIssueConfirmTitle: '이슈 삭제',
  deleteIssueConfirmMessage: "'${n}' 이슈 삭제 시, 전체 현장상황에서 영구히 삭제됩니다. 정말로 삭제하시겠습니까?",
  issueMainImageUploadButton: '대표 이미지 선택',
  issueUpdateCancelWarningTitle: '변경 내용 확인',
  issueUpdateCancelWarningMessage: '변경 내용을 저장하지 않고 페이지를 나가시겠습니까?',
  localeDateWithoutYearFormat: 'MMMM do',
  localeDateWithoutCommaFormat: 'PPP',
  issueCreateSystemMessage: '${n}님이 이슈를 생성했습니다.',
  issueDoneSystemMessage: '${n}님이 이슈를 완료했습니다.',
  issueReactivatedSystemMessage: '${n}님이 이슈를 진행중으로 변경했습니다.',
  issuePolygonNdviAnalysis: '이슈 영역 식생지수 분석',
  issuePolygonNdviAnalysisTooltip: 'NDVI가 있을 때는 NDVI를 기준으로 계산합니다.',
  issuePolygonNdviCalculationBtn: '식생지수 계산',
  issuePolygonNdviHealthyChip: '양호',
  issuePolygonNdviStressedChip: '주의',
  issuePolygonNdviCriticalChip: '위험',
  issuePolygonCalculationRequiredText: '계산 필요',
  issuePolygonSizeLabel: '면적',
  issuePolygonNdviCalculationWarningMessage: '식생지수가 있는 영역만 계산됩니다.',
  issuePolygonNdviViewCheckboxLabel: '식생지수 보기',
  issuePolygonComparedButton: '날짜별 비교',
  issuePolygonComparedSelectPlaceholder: '날짜 선택',
  issuePolygonComparedMessageTitle: '식생지수',
  issuePolygonComparedEmptyFile: '파일 없음',
  issuePolygonComparedConverting: '변환 중',
  issuePolygonComparedCalculating: '계산 중',
  issuePolygonComparedCalculationError: '계산 오류',
  issuePolygonComparedCalculationRequired: '계산 필요',
  viewPointButtonTitle: '빠른 이동',
  mapControlCompareButtonGroupTitle: '현장 비교',
  mapControlToolButtonGroupTitle: '도구 추가',
  mapRotationTooltipMessage: '연동된 지도 도구가 많으면 지도 회전 시 속도가 느려질 수 있습니다.\n사용하지 않는 지도 도구는 [연동 관리]에서 연동 해제해 주세요.',
  errorBoundaryMessage: '오류가 발생했습니다. \n페이지를 새로고침 해주세요.',
  errorBoundaryRefreshButton: '새로고침',

  /* Green */
  greenTooltipWhat: '그린을 등록해 지도 위에 표시하고, 그린 상태를 확인 및관리하기 위한 기능입니다.',
  greenTooltipHow: "'그린 이동'을 통해서 원하는 그린으로 빠르게 이동해보세요. 그린별/날짜별로 그린을 비교할 수 있습니다.",
  greenAddButtonTitle: '그린 추가',
  greenCompareButtonTitle: '그린 비교',
  greenCompareByGreenButtonTitle: '그린별 비교',
  greenCompareByDateButtonTitle: '날짜 비교',
  greenDeleteConfirmTitle: '그린 삭제',
  greenDeleteConfirmMessage: "'${n}'그린을 영구히 삭제합니다. 정말 삭제하시겠습니까?",
  greenAnalysis: '그린 분석',
  greenAnalysisInvalidMessage: '그린이 없거나 위치가 식생지수 범위를 벗어나 분석할 수 없습니다. 그린을 추가하거나 위치를 조정해 주세요.',
  greenAnalysisProgressMessage: '그린 분석 중입니다. 잠시만 기다려주세요. (페이지를 이탈해도 분석은 진행됩니다.)',
  greenAnalysisErrorMessage: '오류가 발생했습니다. 다시 시도해 주세요.',
  greenLabel: '그린',
  greenTypeGreen: '그린',
  greenTypeFW: '페어웨이',
  greenTypeTeeBox: '티박스',
  greenTypeRough: '러프',
  greenTypeBunker: '벙커',
  greenTypePond: '폰드',
  greenTypeEtc: '기타',
  greenTypeAll: '전체',
  greenTypePlaceholder: '타입을 선택해주세요.',
  greenTaskMowing: '잔디깎기',
  greenTaskIrrigation: '관수',
  greenTaskRenovation: '갱신작업',
  greenTaskFertilization: '시비',
  greenTaskPestControl: '병해충 방제',
  greenTaskWeedControl: '잡초 방제',
  greenTaskGardening: '조경',
  greenTaskEquipment: '장비',
  greenTaskSK: 'S/K',

  /* AI Tools */
  toolsAIaccordionGroupTitle: 'AI 도구',
  toolsAIDetectButtonTitle: '탐지',
  toolsAIColorPaletteTitle: '색상',
  toolsAIObjectDetectionAccordionTitle: '객체 탐지',
  toolsAIObjectDetectionSubtitleDetected: '탐지됨',
  toolsAIObjectDetectionGvienClassPlane: '비행기',
  toolsAIObjectDetectionGvienClassShip: '선박',
  toolsAIObjectDetectionGvienClassSmallVehicle: '작은 차량',
  toolsAIObjectDetectionGvienClassLargeVehicle: '큰 차량',
  toolsAIObjectDetectionGvienClassUncategorized: '미분류',
  toolsAIObjectDetectionUnProgressedMessage: '탐지된 객체가 없습니다.',
  toolsAIObjectDetectionHideObjectTooltip: '지도에서 숨기려면 누르세요',
  toolsAIObjectDetectionUnhideObjectTooltip: '지도에 다시 표시하려면 누르세요',
  toolsAIAnalyzeError: '오류가 발생했습니다. 다시 시도해주세요.',
  toolsAISegmentationAccordionTitle: '객체 분할',
  toolsAISegmentationBefore: '분할 전입니다.',
  toolsAISegmentationDo: '분할',
  toolsAISegmentationDone: '분할됨',
  toolsAISegmentationBuilding: '건물',
  toolsAISegmentationRoad: '도로',
  toolsAISegmentationWater: '물',
  toolsAISegmentationVegetation: '식생',
  toolsAISegmentationBarren: '나지',
  toolsAISegmentationOpacity: '불투명도',
  toolsAISegmentationErrorMessage: '오류가 발생했습니다. 다시 시도해주세요.',
  toolsAIChangeDetectionAccordionTitle: '변화 탐지',
  toolsAIChangeDetectionProgressingInformation: '변화 탐지는 10분에서 최대 30분 정도 소요될 수 있습니다. 다른 화면으로 이동해도 탐지가 계속됩니다.',
  toolsAIChangeDetectionSameSnapshotInvalidWarning: '같은 현장상황은 변화 탐지를 할 수 없습니다.',
  toolsAINdmiAccordionTitle: 'NDMI',
  toolsAINdmiAccordionUploadFileButtonTtile: '파일 추가',
  toolsAINdmiAccordionNoContents: '파일이 없습니다.',
  toolsAINdmiFileUploadPopupTitle: 'NDMI 파일 추가',
  toolsAINdmiFileUploadPopupDescription: '파일 형식: tif, tiff, 용량: 제한 없음',
  toolsAINdmiFileUploadErrorMessage: '변환할 수 없는 파일입니다. 파일 삭제 후 다시 시도해주세요.',

  /* landDisplacement */
  landDisplacement: '지반변위',
  landDisplacementAddPoint: '위치 추가',
  landDisplacementPointTitle: '위치 ${n}',
  landDisplacementControlPixelSize: '픽셀 사이즈(m)',
  landDisplacementPointListEmpty: '위치를 추가해 지반변위를 그래프로 확인해보세요.',
  landDisplacementGraphPositionInvalid: '그래프를 표시할 수 없는 위치입니다.',
  landDisplacementVelSliderTitle: '변위 속도 (mm/y)',
  landDisplacementGraphTitle: '위치 ${n} 지반변위',
  landDisplacementGraphXTick: 'yyyy년',
  landDisplacementGraphYTitle: '변위 (mm)',
  landDisplacementTooltipCompareToDate: 'PPP 대비',

  /* superResolution */
  superResolutionAccordionTitle: '초해상도(SR)',
  superResolutionCompareButtonTitle: '원본과 비교',
  superResolutionComparedOrthoPhotoInfoHeader: '정사사진',
  superResolutionCompareErrorDetail: '이 날짜에 초해상도(SR)가 없습니다.',

  /* buildingFormModal */
  buildingCreateButton: '건물 추가',
  buildingFormModalCreateTitle: '건물 추가',
  buildingFormModalTitle: '건물 수정',
  buildingFormModalEditConfirmTitle: '변경 내용 확인',
  buildingFormModalEditConfirmMessage: '변경 내용을 저장하지 않고 페이지를 나가시겠습니까?',
  buildingFormModalNameLabel: '건물명',
  buildingFormModalDescriptionLabel: '설명',
  buildingFormModalEditTitlePlaceholder: '건물명을 입력해주세요.',
  buildingFormModalEditDescriptionPlaceholder: '건물 설명을 입력해주세요.',

  /* building/sideMenu */
  indoorDataAccordionGroupSubtitle: '실내 데이터',
  indoorDataVideoAccordionTitle: '영상',
  indoorDataVideoManagementButton: '영상 관리',
  indoorDataItemStatusLoading: '영상을 분석하는 중입니다. 잠시만 기다려주세요.',
  indoorDataItemStatusError: '영상 분석 중 오류가 발생했습니다. 다시 시도해주세요.',
  indoorDataItemRetryButton: '재분석',
  indoorVideoFolderMoveTitle: '영상',
  indoorVideoFileUploadButton: '360 영상 파일',
  indoorVideoFileUploadTitle: '360 영상 파일 추가',
  indoorVideoFileUploadSelectFolder: '폴더 선택',
  indoorVideoFileUploadPolicyMessage: '파일 형식: mp4, 최대 파일 용량: 제한없음',
  indoorVideoFileUploadDisableTooltip: '업로드할 폴더가 없습니다. 폴더 추가 후 업로드하세요.',
  indoorVideoFileUploadDescription: '메이사 플랫폼은 1개로 정합된 Equirectangular / mp4 형식의 영상 업로드를 지원합니다.\n지원하지 않는 비디오 형식인 경우 변환 후 업로드가 가능하오니 자세한 방법은 [360 영상 파일 변환 가이드] 를 참고하세요.',
  indoorVideoFileUploadGuideButton: '360 영상 파일 변환 가이드',

  /* building/secondarySideMenu */
  indoorDataVideoSecondaryEditButton: '편집',
  indoorDataVideoSecondaryMenuTitle: '영상 관리',
  indoorDataVideoFileDeleteConfirmTitle: '파일 삭제',
  indoorDataVideoFileDeleteConfirmMessage: '${n}개의 파일이 영구히 삭제됩니다. 정말 삭제하시겠습니까?',
  indoorDataVideoFileDeleteConfirmCancel: '취소',
  indoorDataVideoFileDeleteConfirmDelete: '삭제',
  indoorDataVideoPlayButton: '원본 영상 보기',
  panoramaVideoHelpText: '마우스로 드래그하면\n영상을 360도로 둘러보실 수 있습니다.',

  /* building/indoorVideo/main */
  indoorVideoViewTypeToggleWalkthrough: '워크스루',
  indoorVideoViewTypeToggleVideo: '360 영상',
  indoorVideoEmptyMessage: '360 영상이 없습니다.\n새 폴더 추가 후 업로드하거나,\nMeissa Scan 앱을 이용해 업로드하실 수 있습니다.',

  /* building/comparePage */
  walkthroughComparePageTitle: '현장 비교',
  walkthroughCompareButton: '현장 비교',
  walkthroughCompareButtonTooltip: '미니맵을 생성하면 현장 비교가 가능합니다.',
  walkthroughCompareSelectLabelBase: '기준 영상',
  walkthroughCompareSelectLabelCompared: '비교 영상',
  walkthroughCompareGuidePopupTitle: '실내 현장 비교 가이드',
  walkthroughCompareArrowButtonSnackbarMessage: '영상을 개별적으로 동작할 수 있습니다. 공통 동작은 미니맵을 활용하세요.',
  walkthroughCompareMiniMapMarkerSnackbarMessage: '영상을 같이 동작할 수 있습니다. 개별 동작은 화살표를 활용하세요.',

  /* floorPlan */
  floorPlanAccordionTitle: '평면도 (미니맵)',
  floorPlanFileUploadButton: '업로드',
  floorPlanFileEmptyMessage: '파일이 없습니다.',
  floorPlanFileUploadTitle: '평면도 파일 추가',
  floorPlanFileUploadPolicyMessage: '파일 형식: png, jpg, pdf, dxf, 최대 파일 용량: 50MB',
  floorPlanFileUploadDescription: '평면도와 워크스루를 직접 배치하여 미니맵을 생성할 수 있습니다.\n파일은 1개만 업로드 가능하며, 전체 평면도 업로드를 권장합니다.',
  floorPlanFileDeleteTitle: '파일 삭제',
  floorPlanFileDeleteMessage: '평면도 파일 삭제 시, 모든 영상에서 생성된 미니맵들이 영구히 삭제됩니다. 정말 삭제하시겠습니까?',
  floorPlanFileConverting: '파일을 변환하는 중입니다. 잠시만 기다려주세요.',

  /* MiniMap */
  miniMapTitle: '미니맵',
  createMiniMapButton: '미니맵 생성',
  miniMapIsNotConnectedText: '생성된 미니맵이 없습니다.',
  miniMapSettingTitle: '미니맵 생성',
  miniMapSettingConfirmWithoutSavingTitle: '미니맵 생성 중단',
  miniMapSettingConfirmWithoutSavingMessage: '지금 종료하면 미니맵이 저장되지 않습니다.\n작업을 중단하시겠습니까?',
  miniMapSettingStepOneHelpMessage: '1. 배치하기 적합한 곳으로 마커를 옮겨주세요.',
  miniMapSettingStepTwoHelpMessage: '2. 마커를 드래그하여 평면도에 맞게 배치해 주세요.',
  miniMapResetConfirmTitle: '미니맵 초기화',
  miniMapResetConfirmMessage: '해당 워크스루와 평면도 배치가 삭제됩니다. 정말 미니맵을 초기화하시겠습니까?',
  miniMapResetConfirmCancel: '취소',
  miniMapResetConfirmRefresh: '초기화',
  miniMapResetButtonTooltip: '미니맵 초기화',
  miniMapShowSwitchLabel: '미니맵 보기',

  /* LaborerHistory */
  laborerHistoryTableCellDate: '날짜',
  laborerHistoryTableCellTime: '시간',
  laborerHistoryTableCellName: '이름',
  laborerHistoryTableCellWeak: '취약 유무',
  laborerHistoryTableCellStatus: '상태',
  laborerHistorySearchEmptyMessage: '해당 날짜의 데이터가 없습니다.\n다른 날짜를 선택해주세요.',
  laborerHistoryEmptyMessage: '검색 결과가 없습니다.',
  laborerHistoryFilterToggleButtonDate: '일',
  laborerHistoryFilterToggleButtonWeek: '주',
  laborerHistoryFilterToggleButtonMonth: '월',
  laborerHistoryFilterToggleButtonDuration: '기간',
  laborerHistoryFilterAll: '전체',
  laborerHistoryFilterWeakOrNot: '취약 유무',
  laborerHistoryFilterWeak: '취약',
  laborerHistoryFilterNotWeak: '취약 아님',
  laborerHistoryFilterStatus: '근로자 상태',
  laborerHistoryFilterStatusCheckIn: '체크인',
  laborerHistoryFilterStatusActive: '위치공유를 시작',
  laborerHistoryFilterStatusPause: '위치공유를 중단',
  laborerHistoryFilterStatusLogout: '체크아웃',
  laborerHistoryFilterStatusTimeLogout: '시간 체크아웃',
  laborerHistoryFilterStatusDistanceLogout: '거리 체크아웃',
  laborerHistoryFilterTextSearchPlaceHolder: '근로자 이름 검색',

  /* HTTP Problem */
  CLIENT_ERROR: '처리할 수 없는 요청입니다.',
  SERVER_ERROR: '요청하신 작업을 처리할 수 없습니다.',
  TIMEOUT_ERROR: '서버가 응답하지 않습니다.',
  CONNECTION_ERROR: '서버에 연결할 수 없습니다.',
  NETWORK_ERROR: '인터넷 연결 상태를 확인해주세요.',
  WAF_ERROR: '엑세스에 오류가 발생하였습니다. 지원팀에 문의해 주세요.',

  /* Server Error Message Code */
  ER1000: '현재 권한으로는\n해당 기능을 이용할 수 없습니다.',
  ER1001: '보안을 위해 로그아웃되었습니다.\n다시 로그인해주세요.',
  ER1002: '잘못된 요청입니다.',
  ER1003: '입력하신 이메일 혹은 비밀번호를 다시 확인해주세요.',
  ER1004: '초대 유효 기한이 만료되었습니다.\n프로젝트 담당자에게 다시 초대를 요청해 주세요.',
  ER1005: '해당 페이지는 만료되었습니다.\n로그인 화면으로 이동합니다.',
  ER1006: '이미 가입된 사용자입니다.\n로그인 화면으로 이동합니다.',
  ER1007: '해당 이메일로 가입된 계정이 없습니다.\n다시 확인해주세요.',
  ER1008: '비밀번호가 일치하지 않습니다.',
  ER1010: '비밀번호 오류 5회 초과. \n비밀번호 찾기를 이용해 주세요.',
  ER2000: '오류가 발생했습니다.\n다시 시도해주세요.',
  ER3000: '삭제되었거나 존재하지 않는 내용입니다.',
  ER3001: '처리할 수 없는 요청입니다.',
  ER3002: '이미 초대된 계정입니다.',
  ER3003: '분석을 취소할 수 없습니다.\n현장상황이 분석중인지 확인해 주세요. ',
  ER3004: 'Pix4D 엔진으로 분석 중인 현장상황은\n분석 취소할 수 없습니다.',
  ER3005: '이미 분석 중입니다.',
  ER3006: '분석 완료된 현장상황은\n다시 분석할 수 없습니다.',
  ER3007: '모든 GCP 지점이 현장 범위를 벗어나 있습니다.',
  ER3008: '유효하지 않은 데이터입니다. 확인 후 다시 시도해주세요.',
  ER3009: '분석할 이미지가 없습니다.\n이미지 업로드 후 다시 분석 요청해주세요.',
  ER3010: '현장상황 분석이 완료되지 않았습니다.\n분석 완료 후 다시 시도해주세요.',
  ER3012: '압축에 필요한 이미지가 없습니다. 다시 확인해주세요.',
  ER3013: '업로드한 파일 형식이 적절하지 않습니다.\n확인 후 다시 업로드 해주세요.',
  ER3014: '1분 이내 재발송은 불가합니다.\n잠시 후 다시 시도해주세요. ',
  ER3015: '이미지를 30장 이상 업로드해주세요.',
  ER3016: '지층 파일의 최대 업로드 수를 초과했습니다. 새로고침 후 확인해주세요.',
  ER3017: '도면 변환 상태가 변경되었습니다.\n새로고침 후 다시 확인해주세요.',
  ER3018: '도면 변환 상태가 변경되었습니다.\n새로고침 후 다시 확인해주세요.',
  ER3019: '양식에 맞지 않은 데이터 입니다. 양식을 확인하고 다시 시도해주세요.',
  ER3020: 'GCP 지점이 최소 3개 이상 입력된 파일을 업로드해주세요.',
  ER3021: '현장 범위 안의 GCP가 4개 미만입니다.',
  ER3022: '변환할 수 없습니다. \n파일을 다시 확인해주세요.',
  ER3023: '정사사진을 업로드해주세요.',
  ER3024: '매칭된 이미지 수가 3장 이상인 GCP가 3개 이상이어야 합니다.',
  ER3025: '한 번에 10개의 파일만 업로드할 수 있습니다.',
  ER3026: '파일이 너무 커서 업로드할 수 없습니다. 한도는 1 GB입니다.',
  ER3027: '고화질 지원 가능한 최대 면적을 초과했습니다. 특정 구간에서 화질이 낮아 보일 수 있습니다.',
  ER3028: '완료된 이슈는 댓글을 입력하거나 수정할 수 없습니다.',
  ER3029: '첨부 파일이 제한 용량을 초과하였습니다.',
  ER3030: '토공횡단선 연동 정보가 이미 존재합니다.',
  ER3031: '수치표고면모델이 없어 계산할 수 없습니다.',
  ER3032: '파일의 머리 글 행(헤더)가 비어있습니다.',
  ER3033: "머리 글 행(헤더) 작성 규칙을 지켜주세요. '| GCP 이름 | x | y | x | y |' 또는 '| x | y | x | y |'",
  ER3034: '좌표값에 소수값이 입력되지 않았거나, 빈 값이 존재합니다.',
  ER3035: '분석된 NDVI 파일이 있으면 계산할 수 있습니다.',
  ER3036: '전체 식생지수 변환 후에 계산할 수 있습니다.',
  ER3037: '중복된 이름은 사용할 수 없습니다.',
  ER3038: '이 날짜에 수치표면모델이 없습니다.',
  ER3041: '마킹한 이미지가 1장 이상이어야 자동 추천 기능을 이용할 수 있습니다.',
  ER3042: '이미 연동되어있는 항목입니다',
  ER3043: '꼭짓점이 300개가 넘는 면적은 수정할 수 없습니다.',

  /* 공통 에러 */
  already_referenced_error: '해당 값은 사용중이므로 삭제할 수 없습니다.',
  not_found_error: '오류가 발생했습니다. 다시 시도해 주세요.',
  invalid_input_parameter_error: '입력하신 정보가 확인되지 않았습니다. 다시 시도해 주세요.',
  value_already_exist_error: '동일한 이름의 앨범이 있습니다',
  annotationColorPickerTitle: '색상 선택',
  dtmAddButtonTitle: 'DTM 추가',
  dtmAddPopupTitle: 'DTM 추가',
  dtmCreateButtonTitle: '직접 생성',
  dtmFileUploadButtonDescriptionMessage: '다른 플랫폼에서 생성한 DTM,\ntif 파일을 여러개 업로드할 수 있습니다.',
  dtmCreateButtonDescriptionMessage: '메이사의 DSM을 기준으로 원하는\n영역을 제거해 DTM을 만들 수 있습니다.',
  demAnalyzedAccordionSubtitle: '분석된 파일',
  demUploadAccordionSubtitle: '업로드 파일',
  demAccordionTitle: 'DEM (수치표고모델)',
  dsmAccordionItemTitle: 'DSM (수치표면모델)',
  dtmAccordionItemTitle: 'DTM (수치지형모델)',
  basicWorking: '작업중',
  dtmCreateTopBarButtonTitle: 'DTM 생성',
  dtmCreateConfirmTitle: 'DTM 생성',
  dtmCreateConfirmMessage: 'DTM 파일이 생성되는데 최대 3시간이 소요될 수 있습니다. 이대로 생성하시겠습니까?',
  dtmPolygonCreateTopBarMessage: "'영역 지정하기' 버튼을 클릭해 지우고 싶은 영역을 그려보세요.",
  dtmPolygonCreateButtonTitle: '영역 지정하기',
  dtmPolygonDraftButtonTitle: '임시 저장',
  dtmEditorCloseConfirmTitle: 'DTM 저장 확인',
  dtmEditorCloseConfirmMessage: '이대로 나가면 작업 내용이 저장되지 않습니다. 정말 에디터를 나가시겠습니까?',
  basicExit: '나가기',
  dtmDraftSuccessSnackbarMessage: '임시 저장되었습니다.',
  dtmEditorCloseWithoutCreateConfirmTitle: 'DTM 생성 취소',
  dtmEditorCloseWithoutCreateConfirmMessage: '아직 DTM 파일이 생성되지 않았습니다. 정말 에디터를 나가시겠습니까?',
  basicMoreInfo: '상세정보',
  basicDeleteFile: '파일 삭제',
  dtmDeleteConfirmMessage: '${n} 삭제 시, 전체 현장상황에서 파일이 영구히 삭제됩니다. 정말 삭제하시겠습니까?',
  demCompareRightSideMenuToggleTitle: '한번에 비교',
  demComplareRightSideMenuToggleTooltip: 'DSM/DTM의 히스토그램을 한번에 비교할 수 있습니다.',
  dtmPolygonSnackbarMessage: '지우고 싶은 영역보다 크게 지정해 주세요.\n지정한 영역의 꼭짓점을 기준으로 평탄화합니다.\n(단, 높이를 일괄로 조정할 때는 포인트에 딱 맞춰 지정해주세요.)',
  dtmPolygonListTitle: '생성한 영역',
  dtmPolygonRefreshButtonTitle: '전체 삭제',
  dtmPolygonRefreshConfirmTitle: '영역 초기화',
  dtmPolygonRefreshConfirmMessage: '지정한 모든 영역을 초기화하시겠습니까?',
  dtmEditButtonTitle: 'DTM 수정',
  earthworkBaseSurfaceSubtitle: '기준 표면',
  earthworkBaseSurfaceToggleLabel: 'DTM',
  earthworkBaseSurfaceTooltipMessage: 'DTM 파일이 있는 날짜의 부피는 DTM을 기준으로 계산됩니다.',
  earthworkPlanLevelSubtitle: '계획고 선택',
  dtmBulkPolygonName: '영역',
  dtmCreateFailErrorMessage: '변환할 수 없는 파일입니다. 파일 삭제 후 다시 시도해주세요.',
  dtmUploadSuccessSnackbarMessage: '업로드 완료! 분석이 완료되는데는 좀 더 소요됩니다.',
  dtmGuideCaptionBadSnackbar: '객체의 꼭짓점',
  dtmGuideCaptionGoodSnackbar: '객체보다 크게 지정',
  dtmCopyObjectDetectionRunButtonTitle: 'AI 객체 탐지',
  dtmCopyObjectDetectionCopyButtonTitle: 'AI 객체 복사하기',
  dtmCopyObjectDetectionResultSnackbarTitle: 'AI 객체 탐지',
  dtmCopyObjectDetectionResultSnackbarMessage: 'DTM 생성에 필요한 객체들을 자동으로 식별합니다.',
  dtmNotSpecifiedAreaMessage: '아직 지정된 영역이 없어요.\n 영역을 새로 지정하거나 객체를 탐지해 가져올 수 있어요.',
  dtmCopyObjectDetectionResultModalTitle: '객체 탐지 복사',
  dtmCopyObjectDetectionModalRunningMessage: '탐지 이후에 탐지된 객체를 복사할 수 있습니다.\n탐지에는 10분 이상 소요될 수 있습니다.',
  dtmCopyObjectDetectionModalCopyingMessage: 'DTM이 필요한 영역을 지정해주세요.\n지정된 영역에서 탐지된 객체를 복사합니다.',
  dtmObjectDetectionModalTitle: 'AI 객체 탐지',
  dtmObjectDetectionRunConfirmButtonTitle: '탐지',
  dtmObjectDetectionRunningButtonTitle: '탐지',
  dtmCopyObjectDetectionResultConfirmButton: '복사',
  dtmObjectDetectionInProgressMessage: '객체 탐지 중입니다. 화면을 벗어나도 탐지가 계속됩니다.',
  dtmCopyObjectDetectionResultInProgressMessage: '객체 탐지 결과를 복사 중입니다.',
  dtmLeftSideBarObjectDetectionListBoxTitle: 'AI 탐지 영역',
  dtmLeftSideBarListBoxTitle: '직접 생성 영역',
  snapshotMapLoadingMessage: '지도를 생성하는 중입니다.\n잠시만 기다려주세요.',
  zoneDrawingCompletionDateSelectButtonTitle: '완료일 선택',
  zoneDrawingCompletionDateTooltipMessage: '면적별로 완료일 선택시 해당 날짜 이후로\n토공물량표에서 계산이 제외됩니다.',
  basicReset: '초기화',
  basicApply: '적용',
  basicCompleted: '완료',
  zoneDrawingCompletionDateConfirmTitle: '완료일 확인',
  zoneDrawingCompletionDateConfirmMessage: '${n} 이후로 토공 집계표에서 계산이 제외됩니다.',
  zoneDrawingCompletionDateResetConfirmTitle: '완료일 초기화',
  zoneDrawingCompletionDateResetConfirmMessage: '적용한 완료일을 초기화하시겠습니까?',
  issueShareWithKakao: '카카오톡 공유',
  issueShareWithClipboard: '링크 복사',
  issueShareDone: '링크가 복사되었습니다.',

  /* 차량계 위치 */
  vehicleLocation: '차량계 위치',
  layerVehicleTitle: '차량계 위치',
  vehicleTabLocationList: '실시간',
  vehicleTabLocationHistoryList: '이전 위치 기록',
  vehicleLocationListEmpty: '근무 중인 차량계가 없습니다.\nMeissa Guard 앱에 체크인한 차량계 위치를 볼 수 있습니다.',
  vehicleSliderAlert: '재생 가능한 구간이 아닙니다.',
  vehicleDatePickerFormat: 'PPP',
  vehicleGpsErrorSnackbarMessage: '현장 환경에 따라 지도 상의 위치와 실제 근로자 위치가 차이날 수 있습니다.',
  vehicleLocationHistoryListEmpty: '차량계의 이전위치 기록이 없습니다.\n다른 일자를 확인해주세요.',
  vehicleLocationHistoryListTimeEmpty: '차량계의 이전위치 기록이 없습니다.\n다른 시간을 확인해주세요.',
  vehicleHistory: '차량계 운행기록',
  vehicleHistoryFilterStatus: '차량 종류',
  vehicleHistoryFilterAll: '전체',
  vehicleHistoryFilterStatusDumpTruck: '덤프트럭',
  vehicleHistoryFilterStatusExcavator: '굴삭기',
  vehicleHistoryFilterStatusCrane: '크레인',
  vehicleHistoryFilterStatusDozer: '도저',
  vehicleHistoryFilterStatusLoader: '로더',
  vehicleHistoryFilterStatusGrader: '그레이더',
  vehicleHistoryFilterStatusScraper: '스크레이퍼',
  vehicleHistoryFilterStatusRoller: '롤러',
  vehicleHistoryFilterStatusOther: '기타',
  vehicleHistoryFilterTextSearchPlaceHolder: '근로자 이름 및 차량 번호 검색',

  /* Vehicle History Table */
  vehicleHistoryTableCellStartDate: '근로 시작 일시',
  vehicleHistoryTableCellEndDate: '근로 종료 일시',
  vehicleHistoryTableCellTotalDriveTime: '총 주행 시간',
  vehicleHistoryTableCellVehicleType: '차량 종류',
  vehicleHistoryTableCellVehicleNumber: '차량 번호',
  vehicleHistoryTableCellName: '이름',
  vehicleHistoryTableCellRoute: '경로',
  vehicleHistorySearchEmptyMessage: '해당 날짜의 데이터가 없습니다.\n다른 날짜를 선택해주세요.',
  vehicleHistoryEmptyMessage: '검색 결과가 없습니다.',
  layerVehicleTooltipHow: '차량계 기사의 휴대전화에 Meissa Guard 앱을 설치한 후, 체크인을 위해 [프로젝트 정보] 메뉴에서 QR코드 또는 URL을 공유해주세요.',
  layerVehicleTooltipWhat: 'Meissa Guard 앱에서 체크인한 차량계의 위치 기록을 한 달간 기록하고 열람할 수 있습니다.',
  vehicleHistoryFilterToggleButtonDate: '일',
  vehicleHistoryFilterToggleButtonWeek: '주',
  vehicleHistoryFilterToggleButtonMonth: '월',
  vehicleHistoryFilterToggleButtonDuration: '기간',
  vehicleHistoryFilterDateRangeAlertMessage: '시작일보다 이후의 날짜를 선택해 주세요',
  vehicleMarkerPopupName: '이름 : ${n}',
  vehicleMarkerPopupPhone: '휴대폰 번호 : ${n}',
  vehicleMarkerPopupLocation: '마지막 위치 : ${n}',
  vehicleMarkerPopupLocationHistory: '이전 위치 시간 : ${n}',
  vehicleHistoryTableCellViewRouteButton: '경로보기',
  vehicleDrivingPopupInformationStartDate: '근로 시작일',
  vehicleDrivingPopupTitle: '차량계 위치 경로',
  vehicleDrivingPopupDescription: '경로 위를 클릭하거나 마우스를 올리면 시간이 표시되며 한번 더 클릭 시 해제됩니다. 캡처 시 경로 클릭 후 캡처해주세요',
  vehicleDrivingPopupMapCaptureButton: '이미지 캡처',
  vehicleDrivingPopupMapCaptureSnackbarComment: '이미지가 캡처되었습니다.',
  deeplinkGuardServiceMessage: '실시간 안전관리로 더 안전하게!',
  deeplinkMobileServiceMessage: '모바일로 시작하는 현장 커뮤니케이션',
  deeplinkRedirectButtonMessage: '앱에서 보기',
  laborerHistorySearchEndDateSelect: '종료일 선택',
  annotationEditSnackbarMessage: '완료 버튼 클릭시 수정사항이 반영됩니다.',
  dtmOffsetModificationBatchOffset: '일괄 조정',
  dtmOffsetModificationTriangulated: '자동 평탄화',
  dtmOffsetModificationFixedOffset: '사용자 높이 지정',
  dtmOffsetModificationValuePlaceholder: '조정할 높이 입력',
  dtmCreationGuideButtonTitle: '생성 가이드',
  dtmCreationModalTitle: 'DTM 생성 가이드',
  dtmBulkPolygonHeaderTitle: '높이 (Z)',
  dtmOffsetModificationDescriptionMessage: "높이 미입력시 '자동 평탄화'로 계산됩니다.",
  polygonMaxVertexErrorMessage: '꼭짓점은 최대 300개까지 생성할 수 있습니다.',

  /* 그린 작업일지 */
  dailySchedule: '작업일지',
  dailyScheduleArchiveTabName: '보관함',
  dailyScheduleSearchTabName: '검색',
  dailyScheduleMaintenanceEmptyScheduleMessage: '작업일지가 없습니다.',
  dailyScheduleMaintenanceCreateScheduleMessage: '새 작업일지를 생성해 보세요.',
  dailyScheduleMaintenanceCreateScheduleButton: '새 작업일지 생성',
  dailyScheduleMaintenanceCopyScheduleButton: '이전 작업일지 복사',
  dailyScheduleMaintenanceCreateScheduleLoadMessage: '새 작업일지를 생성 중입니다. 잠시만 기다려 주세요.',
  dailyScheduleMaintenanceCreateScheduleRetryButton: '작업일지가 생성되지 않았습니다. 다시 시도해 주세요.',
  dailyScheduleMaintenanceRetryButton: '재시도',
  dailyScheduleMaintenancePositionFullCourseButton: '전체 코스',
  dailyScheduleMaintenancePositionFullHallButton: '전체 홀',
  dailyScheduleMaintenanceCategorySelectMessage: '선택 또는 생성',
  dailyScheduleMaintenanceCreateButton: '생성',
  dailyScheduleMaintenanceSaveButton: '저장',
  dailyScheduleMaintenanceEditButton: '수정',
  dailyScheduleMaintenanceDeleteButton: '삭제',
  dailyScheduleMaintenanceConfirmButton: '확인',
  dailyScheduleMaintenanceCancelButton: '취소',
  dailyScheduleMaintenanceAddRowButton: '추가',
  dailyScheduleReagentFertilizerSelectButton: '시약 카테고리 선택',
  dailyScheduleLocationSelectorPlaceholder: '위치 선택(코스/홀)',
  dailySchedulePositionCategorySelectMessage: '위치 선택',
  dailySchedulePositionCategoryEtcMessage: '기타',
  dailyScheduleMaintenanceCreateErrorMessage: '오류가 발생했습니다. 다시 시도해주세요.',
  dailyScheduleCategoryDuplicateNameErrorMessage: '중복된 이름은 사용할 수 없습니다.',
  dailyScheduleCategoryDeleteConfirmMessage: '해당 값이 영구히 삭제됩니다. 정말 삭제하시겠습니까?',
  dailyScheduleCategoryDeleteErrorMessage: '해당 값은 사용 중이므로 삭제할 수 없습니다.',
  dailyScheduleMaintenanceEditBreakAwayButton: '나가기',
  dailyScheduleMaintenanceEditBreakAwayMessage: '이대로 나가면 작업 내용이 저장되지 않습니다. 정말 페이지를 나가시겠습니까?',
  dailyScheduleMaintenanceDeleteConfirmMessage: '해당 작업일지가 영구히 삭제됩니다. 정말 삭제하시겠습니까?',
  dailyScheduleSearchDateColumnName: '작업일지 날짜',
  dailyScheduleSearchButtonText: '검색',
  dailyScheduleResetSearchFilterButtonText: '필터 초기화',
  dailyScheduleSearchTaskPlaceholder: '작업 내용 검색',
  dailyScheduleSearchFertilizerPlaceholder: '시약/시비 검색',
  dailyScheduleSearchKeywordPlaceholderName: '작업, 시약/시비 검색',
  dailyScheduleSearchEmptyScheduleMessage: '검색 결과가 없습니다.',
  dailyScheduleMaintenanceLoadingMessage: '작업일지를 확인 중입니다. 잠시만 기다려주세요.',
  dailyScheduleMaintenanceCommentLabel: '비고',
  dailyScheduleMaintenanceWeatherLabel: '날씨',
  dailyScheduleMaintenanceWeatherLowestTemperatureLabel: '최저 온도',
  dailyScheduleMaintenanceWeatherHighestTemperatureLabel: '최고 온도',
  dailyScheduleMaintenanceWeatherHumidityLabel: '습도',
  dailyScheduleMaintenanceWeatherPrecipitationLabel: '강우(설)량',
  dailyScheduleMaintenanceMowingHeightLabel: '예고',
  dailyScheduleMaintenanceMowingHeightGreenLabel: '그린',
  dailyScheduleMaintenanceMowingHeightGreenCollarLabel: '그린 칼라',
  dailyScheduleMaintenanceMowingHeightTeeLabel: '티',
  dailyScheduleMaintenanceMowingHeightFairwayLabel: '페어웨이',
  dailyScheduleMaintenanceMowingHeightRoughLabel: '러프',
  dailyScheduleMaintenanceMowingHeightGreenSpeedLabel: '그린 스피드',
  dailyScheduleMaintenanceCategoryLabel: '구분',
  dailyScheduleMaintenanceTypeLabel: '타입',
  dailyScheduleMaintenanceTypeFieldPlaceholder: '타입 선택',
  dailyScheduleMaintenanceTypeFieldErrorText: '타입을 선택해주세요.',
  dailyScheduleMaintenanceReagentFertilizerLabel: '시약/시비',
  dailyScheduleMaintenanceReagentNameLabel: '시약명',
  dailyScheduleMaintenanceFertilizerNameLabel: '시비명',
  dailyScheduleMaintenanceReagentFertilizingUsageLabel: '사용량',
  dailyScheduleMaintenanceLocationLabel: '위치',
  dailyScheduleMaintenanceLocationFieldErrorText: '위치를 선택해주세요.',
  dailyScheduleMaintenanceTaskAssigneeLabel: '작업자',
  dailyScheduleMaintenanceTaskLabel: '작업',
  dailyScheduleMaintenanceTaskContentLabel: '작업 내용',
  dailyScheduleMaintenanceTaskNameLabel: '작업명',
  dailyScheduleMaintenanceTaskNameFieldPlaceholder: '작업명 선택',
  dailyScheduleMaintenanceTaskNameFieldErrorText: '작업명을 선택해주세요.',
  dailyScheduleMaintenanceDescriptionLabel: '작업 내용',
  dailyScheduleMaintenanceDescriptionFieldPlaceholder: '상세 내용을 입력하세요.',
  dailyScheduleMaintenanceAttendanceLabel: '근태',
  dailyScheduleMaintenanceAttendanceEmployeeLabel: '직원',
  dailyScheduleMaintenanceAttendanceEmployeeCountLabel: '직원 수',
  dailyScheduleMaintenanceAttendanceMaleLabel: '일용(남)',
  dailyScheduleMaintenanceAttendanceMaleCountLabel: '일용(남) 수',
  dailyScheduleMaintenanceAttendanceFemaleLabel: '일용(여)',
  dailyScheduleMaintenanceAttendanceFemaleCountLabel: '일용(여) 수',
  dailyScheduleMaintenanceAttendanceOffDutyLabel: '휴무',
  dailyScheduleMaintenanceAttendanceOffDutyCountLabel: '휴무자 수',
  dailyScheduleMaintenanceAttendanceTotalPersonnelLabel: '총 인원',
  dailyScheduleMaintenanceAttendanceTotalPersonnelCountLabel: '총 인원 수',
  dailyScheduleMaintenanceReagentTypeGermicideLabel: '살균제',
  dailyScheduleMaintenanceReagentTypeInsecticideLabel: '살충제',
  dailyScheduleMaintenanceReagentTypeHerbicideSelectiveLabel: '제초제 (선택성)',
  dailyScheduleMaintenanceReagentTypeHerbicideNoneSelectiveLabel: '제초제 (비선택성)',
  dailyScheduleMaintenanceReagentTypeGrowthRegulatorLabel: '생장 조정제',
  dailyScheduleMaintenanceReagentTypeOtherLabel: '기타',
  planLevelFileUploadDescriptionMessage: '• 적용일이 겹치는 경우 가장 최근에 업로드한 파일이 적용됩니다.',
  planLevelFileUploadDescriptionMessageByFeatureFlag: '• 적용일이 겹치는 경우 가장 최근에 업로드한 파일이 적용됩니다.\n• 솔리드로 작성된 dxf 파일만 업로드 할 수 있습니다.\n• dxf파일은 자동으로 tif파일로 변환됩니다.',
  planLevelFileUploadInputPlaceholderMessage: '계획고 파일을 선택해주세요.',
  planLevelSubgradeType: '계획고 구분',
  basicType: '구분',
  basicDateOfApplication: '적용일',
  planLevelDatePickerPlaceholderMessage: '적용할 날짜를 선택해주세요.',
  planLevelSubgradeTypeProjectApproval: '사업 승인',
  planLevelSubgradeTypeDetailDesign: '실시 설계',
  basicUploadInformation: '업로드 정보',
  stratumSubgradeType: '지층고 구분',
  stratumSubgradeTypeRockClassification: '암 판정',
  stratumSubgradeTypeGeotechnicalInvestigation: '지반 조사',
  basicAddNewItem: '새로 추가',
  basicDetailInfo: '상세 정보',
  basicTitle: '제목',
  earthwork: '토공',
  earthworkArea: '토공 구역',
  earthworkAreaPlan: '토공 구역계',
  drawingVectorEditButtonTitle: '구역 수정',
  drawingVectorVersionRightSideMenuTitle: '버전 관리',
  drawingVectorVersionButtonTitle: '버전 관리',
  drawingVectorEditorLeftSideTitle: '생성 구역',
  addPolygonVectorButtonTitle: '토공 구역 추가하기',
  basicDescription: '설명',
  drawingVectorEditorDescriptionPlaceholderMessage: '어떤게 변경 됐는지 기록해보세요.',
  creatingInformation: '생성 정보',
  basicLatest: '최신',
  drawingVectorVersionDifferenceInformationLabel: '수정 정보',
  drawingVectorVersionInformationEditButtonTitle: '버전 정보 수정',
  drawingVectorVersionDeleteButtonTitle: '버전 삭제',
  drawingVectorVersionDeleteConfirmContentMessage: '${n} 삭제 시, 토공집계표 계산에서도 제외됩니다. 정말 삭제하시겠습니까?',
  earthworkAreaPlanEditorHeaderMessage: '토공 구역계 수정중입니다.',
  earthworkAreaPlanVesionSaveDialogTitle: '토공 구역계 버전 저장',
  earthworkAreaPlanVersionSelectDatePlaceholder: '적용할 날짜 선택',
  earthworkDetailTable: '상세 내역서',
  earthworkDetailTableOptionDialogTitle: '상세 내역서 날짜 지정',
  earthworkDetailTableOptionDialogDescriptionMessage: 'DSM, GCP가 없거나 계획고, 토공구역계가 적용되지 않은 날짜는 선택할 수 없습니다.',
  earthworkDetailTableOptionSelectDate: '날짜 지정',
  earthworkDetailTableCompleteSnackbarMessage: '모든 계산이 완료되었습니다.',
  basicNoData: '없음',
  earthworkSummaryTableDescriptionMessage: '집계표는 내역서에서 추출한 당시의 데이터입니다. 추후에 내역서가 변경되어도 집계표에는 반영되지 않습니다.',
  earthworkSummaryTableSuccessToastMessage: '집계표 생성이 완료되었습니다.',
  earthworkSummaryTableSuccessMessage: '수량 합계와 일자별 합계가 일치합니다.',
  earthworkSummaryTableWarningMessage: '항목을 추가해 규격별 수량을 정확하게 입력하세요. 수량의 합계와 일자별 합계가 일치해야합니다.',
  earthworkTableAccordionTitle: '토공 집계표',
  earthworkTableGuideDialogDescirption: '토공 집계표는 토공구역계, 계획고가 모두 있어야 생성됩니다.',
  earthworkTableGuideTitle: '토공 집계표 생성 안내',
  basicHasData: '있음',
  basicDate: '날짜',
  earthworkSummaryTable: '집계표',
  earthworkSummaryTableDeleteConfirmTitle: '집계표 삭제',
  earthworkSummaryTableCellAddButtonTitle: '항목 추가',
  earthworkSummaryTableHeaderStratum: '지층',
  earthworkSummaryTableHeaderMaterial: '규격',
  earthworkSummaryTableHeaderQuantity: '수량',
  earthworkDetailTableResourceStratumCell: '${n1} 외 ${n2}개',
  earthworkSummaryTableCreationButtonTitle: '집계표로 추출',
  earthworkSummaryTableCreationConfirmTitle: '집계표로 추출',
  earthworkSummaryTableCreationConfirmPlaceholderMessage: '집계표 이름을 입력해주세요.',
  backToMapPageTooltipMessage: '지도로 돌아가기',
  earthworkDetailTableInformationQuantityTitle: '계획 수량',
  earthworkDetailTableInformationQuantityContent: '입력되지 않은 계획 수량은 0으로 표시됩니다.',
  earthworkDetailTableInformationDataTitle: '데이터 없음',
  earthworkDetailTableInformationDataContent: "해당 날짜에 적용된 '지층고', '계획고', '토공구역'이 없거나 삭제됐을 경우 표에서 '없음'으로 표시됩니다.",
  fileConversionErrorMessage: '파일 변환 중 문제가 발생하였습니다. 채널톡으로 문의해주세요.',
  dailyScheduleExcelExportTitle: '엑셀 파일로 내보내기',
  dailyScheduleExcelExportBeforeStartMonthErrorMessage: '시작일보다 이후의 날짜를 선택해 주세요.',
  dailyScheduleExcelExportThirteenMonthDiffErrorMessage: '최대 1년 단위로 선택 가능합니다.',
  dailyScheduleExcelExportBreakAwayTitle: '엑셀 파일 내보내기 취소',
  dailyScheduleExcelExportCalloutMessage: '작업일지가 없는 날짜가 있는 경우, 엑셀 파일로 내보낼 때도 해당 날짜는 빈 상태로 내보내집니다.',
  dailyScheduleExcelExportSelectDateLabel: '기간 선택',
  dailyScheduleExcelExportMaxSelectDateMessage: '최대 선택 기간 : 1년',
  dailyScheduleExcelExportErrorMessage: '오류가 발생하여 입력하신 정보가 확인되지 않았습니다. 다시 시도해 주세요.',
  stratumSubgradeTypeIsEmpty: '구분을 선택해주세요.',
  stratumEffectiveDateIsEmpty: '적용일을 선택해주세요.',
  quantityVolumeOptionEmptyDsm: 'DSM 없음',
  earthworkAreaDetailSubtitle: '토공 구역 상세',
  earthworkSummaryTableEditConfirmTitle: '집계표로 이름 수정',
  basicDownloadOriginalFile: '원본 파일 다운로드',
  copyPreviousScheduleSelectDateMessage: '날짜 선택',
  copyPreviousSchedulePreviewTitle: '작업일지 미리보기',
  copyPreviousScheduleButtonLabel: '복사하여 생성',
  copyPreviousScheduleEmptyScheduleMessage: '선택한 날짜의 데이터가 존재하지 않습니다.',
  copyPreviousScheduleSuccessMessage: '작업일지가 생성되었습니다.',
  basicDuplicateNameErrorMessage: '이미 사용중인 이름입니다.',
  earthworkResourceLegacyInfoTooltipMessage: '2024-08-30 이전 데이터는 \n업로드 정보가 없습니다.',
  earthworkAreaPlanFileUploadPopupTitle: '토공 구역계 파일 추가',
  basicCalculatingStatusMessage: '계산중입니다. 잠시만 기다려주세요.',

  /* 포토박스 */
  photoBox: '포토박스',
  photoBoxPhoto: '사진',
  photoBoxVideo: '동영상',
  photoBoxSnapshotMediaListMessage: '최근 업로드 된 ${n}개의 사진/동영상만 표시됩니다.',
  photoBoxSnapshotMediaListEmptyContentsBoxMessage: '저장된 사진/동영상이 없습니다.\n포토박스 관리에서 사진/동영상을 업로드하세요.',
  photoBoxManagement: '포토박스 관리',
  photoBoxAlbum: '앨범',
  photoBoxMediaEmptyContentsBoxMessage: '저장된 사진/동영상이 없습니다.\n사진 및 동영상을 드래그하거나 버튼을 눌러 업로드하세요.',
  photoBoxAlbumDetailEmptyContentsBoxMessage: '앨범에 사진/동영상이 없습니다.',
  photoBoxMediaFileLabel: '사진/동영상',
  photoBoxMediaFileUploadLabel: '사진/동영상 업로드',
  photoBoxMemoLabel: '메모',
  photoBoxMemoPlaceholderMessage: '메모를 입력해주세요',
  photoBoxEditMediaSectionCalloutMessage: '사진/동영상 다중 선택 시 메모가 일괄로 적용됩니다.',
  photoBoxMediaFileUploadStopMessage: '업로드가 완료되지 않았습니다. 완료된 파일은 취소되지 않습니다. 업로드를 정말 취소하시겠습까?',
  photoBoxMediaFileUploadRetryMessage: '업로드 실패한 사진/동영상이 있습니다.',
  photoBoxMediaFileUploadLimitCountMessage: '사진/동영상 업로드는 ${n}개까지 가능합니다.',
  photoBoxAddToAlbumLabel: '앨범에 추가',
  photoBoxUploadDateLabel: '업로드일시',
  photoBoxDateOfShootingLabel: '촬영일시',
  photoBoxDeleteMediaCallbackMessage: '사진/동영상을 삭제하시겠습니까?\n앨범에 저장된 사진/동영상도 함께 삭제됩니다.',
  photoBoxAddToNewAlbumLabel: '새 앨범 추가',
  photoBoxAddToAlbumTitle: '이 앨범으로 추가',
  photoBoxNewAlbumLabel: '새 앨범 ${n}',
  photoBoxAlbumEmptyContentBoxMessage: '앨범을 추가하여\n사진/동영상을 관리하세요',
  photoBoxCreateNewAlbumTitle: '새 앨범 생성',
  photoBoxAddNewAlbumMessage: '앨범 생성 후 사진/동영상을 넣어주세요',
  photoBoxDeleteAlbumCallbackTitle: '앨범 삭제',
  photoBoxDeleteAlbumCallbackContentMessage: '선택한 앨범을 삭제하겠습니까?\n앨범 내 사진은 제거되지 않습니다.',
  photoBoxUpdateAlbumNameLabel: '앨범 이름 변경',
  photoBoxDeleteFromAlbumCallbackTitle: '앨범에서 제거',
  photoBoxDeleteFromAlbumCallbackContent: '${n}개의 사진/동영상을 이 앨범에서 제거하겠습니까?',
  photoBoxDeleteMediaCallbackContent: '${n}개의 사진/동영상을 삭제하시겠습니까?\n앨범에 저장된 사진/동영상도 함께 삭제됩니다.',
  photoBoxDropzoneGuideSnackbarContentMessage: '파일을 여기다 끌어다 놓으면 업로드가 시작됩니다.',
  photoBoxAddMediaToAlbumDuplicatedMessage: '선택한 사진/동영상이 중복되어 가져올 수 없습니다.',
  photoBoxAddMediaToAlbumSuccessMessage: '‘${n1}’앨범에 사진/동영상이 ${n2}장 추가되었습니다.',
  photoBoxMediaLoadMoreLabel: '더 불러오기',
  earthworkFileSettingTitle: '토공 파일 관리',
  pileFileSettingTitle: '말뚝 파일 관리',
  basicPile: '말뚝(Pile)',
  pileAIdetectionAccordionTitle: 'AI 말뚝 탐지',
  basicInDev: '개발중',
  basicNewFolder: '새 폴더',
  basicFileUpload: '파일 업로드',
  earthworkFileSettingEmptyMessage: '암 판정이나 토공에 관련된\n근거 자료를 업로드하세요.',
  pileFileSettingEmptyMessage: '말뚝(Pile)에 관련된\n근거 자료를 업로드하세요.',
  fileSettingEmptyMessage: '파일이 없습니다.',
  fileSettingUploadNumberLimitMessage: '파일 업로드는 한번에 20개까지 가능합니다.',
  fileSettingUploadSizeLimitMessage: '50MB 이하인 파일만 업로드 가능합니다.',
  earthworkFileSettingUploadPopupTitle: '토공 파일 추가',
  basicEditFileInformation: '파일 정보 수정',
  basicMove: '이동',
  basicDownload: '다운로드',
  basicFileName: '파일명',
  fileSettingFolderMoveNoFolderErrorMessage: '이동할 폴더가 없습니다. 폴더를 추가한 후 이동하세요.',
  fileUploadToFolderButtonTitle: '이 폴더에 파일 추가',
  basicEditName: '이름 수정',
  fileSettingFolderNameDuplicateErrorMessage: '이미 사용중인 폴더 이름입니다.',
  fileSettingDeleteFolderMessage: '${n} 삭제 시, 폴더 내 파일이 모두 삭제됩니다.\n정말 삭제하시겠습니까?',
  fileArchiveTypeEarthwork: '토공 파일',
  fileArchiveTypePile: '말뚝 파일',
  fileSettingFolderMoveDialogTitle: '이동할 위치 선택',
  basicCurrentLocation: '현재 위치',
  fileSettingFolderMoveConfirmButton: '이 폴더로 이동',
  fileSettingDeleteFileMessage: '${n} 삭제 시, 전체 현장상황에서 파일이 영구히 삭제됩니다.\n정말로 삭제하시겠습니까?',
  basicMore: '더보기',

  /* 파노라마 스티칭 */
  panoramaTypeSelectModalTitle: '360° 파노라마 현장상황 추가',
  panoramaImageUploadTitle: '파노라마 이미지 업로드',
  panoramaImageUploadDescription: '파노라마 이미지를 업로드하면 360° 뷰로 볼 수 있습니다.',
  panoramaStitchingTitle: '파노라마 이미지로 병합',
  panoramaStitchingDescription: '드론의 파노라마 모드로 촬영한 이미지를 업로드하면 파노라마로 병합해줍니다.',
  panoramaStitchingImageUploadPopupTitle: '병합할 이미지 추가',
  panoramaStitchingImageUploadDescription: '파일 형식: jpg, jpeg, png (전체 파일 용량 제한: ${n}MB)',
  panoramaStitchingImageUploadMinDescription: '업로드는 최소 ${n}장 이상부터 가능합니다.',
  panoramaStitchingImageDownloadFileName: '[병합된 파노라마] ${n1}(${n2}).확장자',
  panoramaStitchingSelectImageTitle: '이미지 선택 (${n1}/${n2})',
  panoramaStitchingUploadLimitWarningMessage: '최대 파일 업로드 개수를 초과하였습니다. ',
  panoramaStitchingUploadTotalFileSizeLimitWarningMessage: '최대 파일 업로드 지원 용량을 초과했습니다.(${n}MB)\n용량을 줄여서 다시 업로드하세요.',
  panoramaStitchingUploadSingleFileSizeLimitWarningMessage: '${n}MB가 넘는 이미지는 업로드가 불가능 합니다.',
  fileArchivePhotoBoxFileImageType: '포토박스 사진',
  fileArchivePhotoBoxFileVideoType: '포토박스 동영상',
  photoBoxFileSelectLimitAlertMessage: '한번에 ${n}개까지 선택 가능합니다.',
  photoBoxSnapshotPositionDeleteAlertMessage: '해당 사진/동영상의 위치가 삭제됩니다.\n정말 위치를 초기화하시겠습니까?',
  panoramaImageDownloadButtonTitle: '파노라마 이미지 다운로드',
  panoramaOriginalImageDownloadButtonTitle: '원본 이미지 다운로드',
  basicSharingEmail: '메일로 공유',
  recipeintUserBoxPlaceholder: '선택된 사용자 표시',
  projectUserSearchPlaceholder: '이름 검색 (프로젝트 참여자만 표시)',
  sharingSuccessSnackbarMessage: '공유가 완료되었습니다.',
  basicDeletedFileErrorMessage: '삭제된 파일입니다. 다시 확인해주세요.',

  /* 2FA */
  twoFactorAuthenticationNumberDescription: '${n}로 인증번호가 전송되었습니다.\n인증번호를 입력해주세요.',
  twoFactorAuthenticationInputPlaceholderLabel: '인증번호 입력',
  twoFactorAuthenticationRetryLabel: '인증번호 재발송',
  twoFactorAuthenticationSuccessButtonLabel: '인증 완료',
  twoFactorAuthenticationErrorMessage: '인증번호를 확인해주세요',
  twoFactorAuthenticationRetryMessage: '인증번호를 재발송하세요',
  photoBoxSearchParamsMemoSearchLabel: '메모 검색',
  photoBoxSearchParamsOrderByDescLabel: '촬영 날짜 최신순',
  photoBoxSearchParamsOrderByAscLabel: '촬영 날짜 오래된순',
  photoBoxMediaInfoDeletedUserLabel: '삭제된 사용자',
  photoBoxMediaInfoDeletedZoneLabel: '삭제된 구역',
  photoBoxMediaSearchEmptyLabel: '검색된 사진/동영상이 없습니다.',
  baseDemAnnotationFormLabel: '기준 표면',
  standardElevationLabel: '기준',
  referSnapshotElevationLabel: '비교',
  elevationGraphDownloadButtonTitle: '단면도 그래프 다운로드',
  tiffFileEffectiveDateTag: '${n} 적용',
  elevationReferSnapshotNoFileErrorMessage: '비교할 날짜/표면이 없습니다.',
  elevationPlanLevelNoFileErrorMessage: '적용된 파일이 없습니다.',
  volumeMeasureGuideModalTitle: '부피 계산 가이드',
  volumeMeasureGuideImageUrl: 'https://meissa-frontend-public.s3.ap-northeast-2.amazonaws.com/image/volume_measure_guide_ko.png',
  volumeMeasureGuideButtonTitle: '계산 가이드',
  appInducementBannerMessage: '모바일에서 더 편리하게',
  appInducementBannerButtonLabel: '앱으로 이동',
  appInducementDrawerMessage: '메이사 플랫폼 모바일에서\n최적의 환경으로 기능을 이용해보세요',
  appInducementDrawerDeepLinkButtonLabel: '편리한 앱에서 보기',
  appInducementDrawerCloseButtonLabel: '30일간 보지 않기',
  planLevelFileConvertingMessage: '파일을 변환하는 중입니다. 파일 용량에 따라 최소 3시간에서 최대 12시간 이상 소요될 수 있습니다.',
  planLevelFileSelectionDescription: '파일 형식: tif, dxf | tif 최대 파일 용량: ${n1}, dxf 최대 파일 용량: ${n2}MB',

  /* 그린 바로가기 */
  greenShortcutAnalyzeDroneDataTitle: '드론 데이터 분석',
  greenShortcutCreateDailyScheduleTitle: '작업일지 작성',
  greenShortcutCourseOverviewTitle: '코스 현황 확인',
  greenShortcutCompareCourseTitle: '다른 날짜와 코스 비교',
  greenShortcutCourseListTitle: '코스 현황 목록',
  greenShortcutGreenMonitoringTitle: '그린 날짜별 비교',
  greenShortcutPhotoBoxTitle: '포토박스',
  greenShortcutWorkTitle: '이슈',
  greenShortcutAnalyzeDroneDataDescription: '드론으로 촬영한 이미지를 업로드하면 코스 현황이 만들어져요.',
  greenShortcutCreateDailyScheduleDescription: '어떤 코스에서 누가 어떤 작업을 했는지 기록할 수 있어요.',
  greenShortcutCourseOverviewDescription: '최신 날짜의 코스 현황을 확인할 수 있어요.',
  greenShortcutCompareCourseDescription: '날짜별로 코스를 한눈에 비교할 수 있어요.',
  greenShortcutCourseListDescription: '코스 현황을 날짜별로 확인할 수 있어요.',
  greenShortcutGreenMonitoringDescription: '1개의 그린을 날짜별로 비교해 볼 수 있어요.',
  greenShortcutPhotoBoxDescription: '포토박스에서 코스별로 사진을 관리할 수 있어요.',
  greenShortcutWorkDescription: '코스 관련 이슈를 지도에 표시하고 관리할 수 있어요.',
  greenShortcutAnalysisStatusTooltipText: '가장 최신 현장의 분석 상태입니다.',
  greenLatestCourseAnalysisErrorText: '가장 최근에 추가한 현장에서 오류가 발생했습니다.\n재분석 또는 채널톡으로 문의하세요.',
  greenCompareEmptyText: '비교할 그린이 없습니다.\n새 그린을 추가해보세요.',
  greenCompareMoveToGreenAccordion: '그린으로 이동',
  elevationDonwloadSelectBaseErrorMessage: '기준 표면 선택 후 다운로드가 가능합니다.',

  /* point cloud resolution */
  pointCloudResolutionLasResolutionLabel: 'las 저해상도',
  pointCloudResolutionLasResolutionDescription: '점 개수를 줄여 해상도를 조절한 데이터',
  pointCloudResolutionLasLargeFileSizeDescription: '큰 파일 용량',
  pointCloudResolutionLasMediumFileSizeDescription: '중간 파일 용량',
  pointCloudResolutionLasSmallFileSizeDescription: '작은 파일 용량',
  pointCloudResolutionLasCreateButtonLabel: '파일 생성',
  pointCloudResolutionLasResolutionConvertingButtonLabel: '변환 중',
  pointCloudResolutionLasResolutionFailMessage: '파일을 생성에 실패했습니다. 다시 시도해주세요.',
  pointCloudResolutionLasResolutionPenddingMessage: '파일을 생성중입니다. 수 분 가량 소요될 수 있습니다. (페이지를 이탈해도 변환은 진행됩니다.)',

  /* 프로젝트 폴더 */
  projectListSearchPlaceholder: '프로젝트 검색',
  projectCreateButtonTitle: '새 프로젝트',
  projectItemNumberInfo: '프로젝트 개수',
  projectFolderUngroupMenuTitle: '폴더 해제',
  projectCreateFolderModalTitle: '새 폴더 추가',
  projectEditFolderModalTitle: '폴더 이름 수정',
  projectEmptyViewMessage: '프로젝트가 없습니다.\n새 프로젝트를 추가해보세요.',
  projectFolderUngroupModalContentMessage: '‘${n}’ 폴더 해제 시, 개별 프로젝트로 분리됩니다. 정말 해제하시겠습니까?',
  basicUngroup: '해제',
  basicDeleteFolder: '폴더 삭제',
  projectFolderDeleteModalContentMessage: '‘${n}’ 폴더를 정말 삭제하시겠습니까?',
  projectFolderMoveSuccessMessage: '‘${n}’ 폴더로 이동했습니다.',

  /* 그린지정 */
  greenMarkerEditLocationInfoText: '지도 내에서 마커를 드래그 하여 위치를 변경 할 수 있습니다.',
  greenMarkerEditLocation: '위치 수정',
  greenMarkerNoLocation: '위치 없음',

  /* 신속촬영 */
  quickShootingTitle: '그린 신속촬영',
  quickShootingDescription: '드론으로 그린구역만 빠르게 촬영하여 그린상태를 선명하게 파악할 수 있어요.',
  quickShootingDetailTitle: '신속촬영 상세',
  quickShootingDetailDescription: '업로드한 신속 촬영물을 날짜별로 확인할 수 있어요.',
  quickShootingUploadEmptyText: '파일을 여기에 드래그하거나 파일추가 버튼을 클릭하여 추가해주세요.',
  quickShootingFileExtensionDescription: '업로드는 코스 단위로 등록할 수 있습니다.',
  quickShootingNoLocationErrorText: '위치정보가 잘못된 이미지가 있습니다. 삭제 후 다시 시도해주세요.',
  quickShootingInvalidLocationErrorText: '골프장이 아닌 곳에서 찍힌 이미지가 있습니다. 삭제 후 다시 시도해주세요.',
  quickShootingDeleteText: '신속촬영 삭제',
  quickShootingDeleteDescription: 'yyyy년 MM월 dd일 HH:mm에 촬영한 신속촬영물 ${n}장이 모두 삭제됩니다. 삭제하시겠습니까?',
  quickShootingCreateText: '신속촬영 추가',
  quickShootingDetailEmptyText: '신속촬영물이 없습니다.\n신속촬영을 추가해보세요.',
  quickShootingLoadingText: '이미지 로딩중입니다...\n잠시만 기다려주세요.',
  quickShootingErrorText: '알 수 없는 오류가 발생했습니다.\n화면을 새로고침 해주세요.',
  quickShootingImageEmpty: '이미지가 없습니다.',
  quickShootingAddFile: '파일 추가',
  quickShootingImage: '이미지',
  moveToQuickShootingDetail: '신속촬영 상세로 이동',
  quickShootingFailureHistory: '최근 업로드 실패 내역',
  externalLoadingMessage: '메이사 플랫폼으로 이동 중입니다.\n잠시만 기다려주세요.',
  externalErrorMessage: '오류가 발생했습니다.\n문제가 계속될 경우 담당자에게 문의해 주세요.',
  quickShootingRequiredAllGreensErrorText: '코스에 속한 모든 그린을 업로드 해주세요.',
  quickShootingUploadedImageErrorText: '이미지 오류가 있습니다. 관리자에게 문의해주세요.',
  quickShootingUploadFailText: '업로드를 실패했습니다. 다시 시도해주세요.',
  greenShortcutSearchDailyScheduleTitle: '작업일지 검색',
  greenShortcutSearchDailyScheduleDescription: '작업 및 시약시비 내용을 상세하게 확인할 수 있어요.',
  quickShootingSaveCompletedText: '이미지 저장 완료! 페이지를 이탈해도 코스 분류는 진행됩니다. ',
  quickShootingExitConfirmText: '이대로 나가면 작업 내용이 저장되지 않습니다. 정말 페이지를 나가시겠습니까?',
  quickShootingHistoryCount: '(${n}장)',
  quickShootingHistoryDateFormat: 'yyyy년 MM월 dd일 HH:mm',
  quickShootingCalendarDateFormat: 'yyyy년 MM월',
  quickShootingList: '신속촬영 목록',
  quickShootingTakeDateTime: '촬영 일시',
  quickShootingDetailCompareTooltipTitle: '해당 그린을 여러 날짜와 비교',
  greenMarkerDetailQuickShooting: '신속촬영',
  greenShortcutDisabledQuickShootingInfo: '신속촬영을 사용하시려면 관리자에게 문의해주세요.',

  /* 프로젝트 관리 */
  projectManagementTemporaryTooltipTitle: '‘프로젝트/참여자 관리’, ‘내 정보’의 위치가 변경됐어요.',
  projectManagementTemporaryTooltipContent: '- 여러 프로젝트에 참여자를 한번에 초대해 보세요.\n- 프로젝트별로 참여자를 한눈에 확인할 수 있어요.',
  basicProjectName: '프로젝트 이름',
  basicPermission: '권한',
  basicParticipant: '참여자',
  basicPeopleUnit: '명',
  basicSnapshot: '현장',
  basicDetails: '상세 정보',
  analyzedMailProjectListHeader: '분석 완료 메일',
  projectDetailViewButtonTitle: '자세히 보기',
  inviteParticipantButtonTooltipMessage: '프로젝트를 선택한 후 초대할 수 있습니다.',
  targetProjectInvitationModalSubtitle: '초대할 프로젝트',
  targetUserInvitationModalSubtitle: '초대할 참여자',
  invitationEmailInputPlaceholderMessage: '초대할 이메일을 입력해주세요.',
  invitationPermissionSelectPlaceholderMessage: '권한 선택',
  invitationModalEmptyMessage: '추가 버튼을 눌러 참여자를 한번에 초대하세요.',
  invitationSuccessSnackbarMessage: '발송이 완료되었습니다.',
  projectConstructionDuration: '공사 기간',
  basicStartDate: '시작일',
  basicEndDate: '종료일',
  volumeCalculationUnit: '부피 계산 단위',
  workerCheckInQRButtonTitle: '근로자 체크인 QR',
  fieldBoundaryButtonTitle: '현장 경계',
  analyzeMailTooltipMessage: '알림 설정은 내 계정에만 적용됩니다.',

  /* 사진대지 */
  photographicReport: '사진대지',
  photographicReportButtonTooltipMessage: '사진을 선택 후 사진 대지를 생성할 수 있습니다.',
  photographicReportImagePreviewTitle: '이미지 미리보기',
  photographicReportLayoutTitle: '사진대지 레이아웃',
  photographicReportDragAndDropMessage: '드래그로 이미지 순서를 바꿀 수 있으며, 순서대로 사진대지에 정렬됩니다.',
  photographicReportLayoutConstructionTwoPage: '2장',
  photographicReportLayoutConstructionSixPage: '6장',
  photographicReportCreateButtonLabel: '사진대지 생성',
  photographicReportCreateLoadingMessage: '파일을 생성 중입니다. 잠시만 기다려주세요.',
  photographicReportCreateLoadingLabel: '사진대지 생성 중',
  photographicReportCreateSuccessMessage: '사진대지 생성이 완료되었습니다.',
  photographicReportExcelFileDownloadButtonLabel: '엑셀 파일 다운로드',
  photographicReportFilterAlertMessage: '동영상은 사진 대지 생성에서 제외됩니다.',
  photographicReportFilterAlertRetryMessage: '동영상은 사진대지 생성에서 제외됩니다.\n다시 선택해주세요.',
  photographicReportPromotionBannerUrl: 'https://meissa.oopy.io/1ac2973c-1a5f-80a5-8c29-c11652c25f26',
  photographicReportPromotionSideMenuBannerImageUrl: 'https://meissa-frontend-public.s3.ap-northeast-2.amazonaws.com/image/photographic_report_side_menu_banner@2x.png',
  photographicReportPromotionAccordionBannerImageUrl: 'https://meissa-frontend-public.s3.ap-northeast-2.amazonaws.com/image/photographic_report_accordion_banner@2x.png',

  /* 차량계 */
  vehicleCount: '${n}대',

  /* 도면 가이드 */
  drawingUploadGuideLabel: '도면 업로드 가이드',
  drawingUploadGuideHelperTextButtonLabel: '도움이 필요하신가요?',
  drawingUploadGuideUrl: 'https://meissasaas.zendesk.com/hc/ko/articles/11438056913679-%EB%8F%84%EB%A9%B4-%EC%97%85%EB%A1%9C%EB%93%9C-%EA%B0%80%EC%9D%B4%EB%93%9C-%EC%9E%90%EB%8F%99',
  planLevelThreeDHelpMessage: '파일을 변환해야 3D로 확인할수 있습니다. ',
  planLevelThreeDConversionMessage: '파일을 변환하는 중입니다. 파일 용량과 현장 크기에 따라 20분 이상 소요될 수 있습니다.',
  planLevelThreeDErrorMessage: '변환할 수 없는 파일입니다. 채널톡으로 문의해주세요.',
  planLevelThreeDConversionButtonTitle: '변환하기',
  earthworkTuningValue: '변경 보정값',
  earthworkTuningValueDescriptionMessage: '선택한 날짜 사이에 ‘지층고’, ‘계획고’, ‘토공구역’이 하나라도 변경됐을 경우 발생한 내역입니다. 이 값은 일자별 합계에 포함되지 않습니다.',
  earthworkTuningValueCellTooltipMessage: '${n1} 기준 ${n2}의 변경에 따라 보정된 값입니다.\n변경 보정값은 일자별 합계에 포함되지 않습니다.',
  earthworkDetailCanNotCreateAlertMessage: "아직 분석중인 '지층고' 혹은 '계획고' 파일이 있습니다.\n분석은 최대 1시간 정도 소요되며, 완료된 후에 상세내역서를 확인할 수 있습니다.",
  fileUploadAndConversionDescriptionOneHourMessage: '• 분석이 완료되는데 최대 1시간 정도 소요됩니다.',
  datepickerPlaceholderMessage: '날짜를 선택하세요'
      };