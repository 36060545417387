import InventoryIcon from '@mui/icons-material/Inventory';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { SvgIcon, SvgIconProps } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { getNewChip } from 'features/common/Chip';
import routes from 'pages/routes';
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { isGreen } from 'shared/common/customize';
import { sideMenuWidth } from 'shared/common/policies/sizes';
import GA from 'shared/ga';
import {
  PROJECT_FILE_ARCHIVE_MENU,
  PROJECT_VEHICLE_HISTORY_MENU,
  PROJECT_WORKER_HISTORY_MENU,
} from 'shared/ga/actions';
import { PROJECT } from 'shared/ga/category';
import { nls } from 'shared/locale/language';
import theme from 'shared/styles/mui/theme';
import { Project } from 'stores/data/types';
import styled from 'styled-components';

export type TabType =
  | 'PROJECT_SETTINGS'
  | 'PROJECT_USER_SETTINGS'
  | 'FILE_ARCHIVE'
  | 'LABORER_HISTORY'
  | 'VEHICLE_HISTORY';

interface Props {
  project: Project;
  selectedTab: TabType;
  className?: string;
}

export default function SideMenu({ project, selectedTab, className }: Props) {
  const history = useHistory();
  const match = useRouteMatch();
  const [tab, setTab] = useState<TabType>(selectedTab);

  useEffect(() => {
    switch (match.path) {
      case routes.project.archive.path:
        setTab('FILE_ARCHIVE');
        break;
      case routes.project.laborerHistory.path:
        setTab('LABORER_HISTORY');
        break;
      case routes.project.vehicleHistory.path:
        setTab('VEHICLE_HISTORY');
        break;
      default:
        setTab('PROJECT_USER_SETTINGS');
    }
  }, [match]);

  function navigateIfNeeded(dest: string) {
    // 현재 위치와 목표지가 같다면 이동할 필요 없음
    if (match.url === dest) {
      return;
    }
    history.push(dest);
  }

  function goToFileArchive() {
    navigateIfNeeded(routes.project.archive.of({ projectId: project.id }));
    GA.event(PROJECT, PROJECT_FILE_ARCHIVE_MENU, 'setting');
  }
  function goToLaborerHistory() {
    navigateIfNeeded(routes.project.laborerHistory.of({ projectId: project.id }));
    GA.event(PROJECT, PROJECT_WORKER_HISTORY_MENU, 'setting');
  }
  function goToVehicleHistory() {
    navigateIfNeeded(routes.project.vehicleHistory.of({ projectId: project.id }));
    GA.event(PROJECT, PROJECT_VEHICLE_HISTORY_MENU, 'setting');
  }

  return (
    <Layout className={className}>
      <StyledTabs value={tab} onChange={(_, x) => setTab(x)}>
        <StyledTab
          value="FILE_ARCHIVE"
          icon={<ArchiveSvgIcon component={InventoryIcon} />}
          label={nls.fileStorage()}
          onClick={goToFileArchive}
        />
        {!isGreen() && (
          <StyledTab
            value="LABORER_HISTORY"
            icon={<LaborerHistoryIcon />}
            label={labelWithNew(nls.laborerHistory())}
            onClick={goToLaborerHistory}
          />
        )}
        {!isGreen() && (
          <StyledTab
            value="VEHICLE_HISTORY"
            icon={<VehicleHistoryIcon />}
            label={labelWithNew(nls.vehicleHistory())}
            onClick={goToVehicleHistory}
          />
        )}
      </StyledTabs>
    </Layout>
  );
}

const labelWithNew = (label: string) => {
  return (
    <div>
      {label}
      <span className="new" />
    </div>
  );
};

const StyledTabs = styled(Tabs).attrs({
  textColor: 'primary',
  orientation: 'vertical',
  variant: 'fullWidth',
  indicatorColor: 'primary',
})`
  .MuiTabs-indicator {
    transition: none;
  }
`;
const Layout = styled.div`
  background-color: ${theme.palette.background.paper};
  height: 100%;
  width: ${sideMenuWidth};
  border-right: 1px solid ${theme.palette.divider};
`;

const StyledTab = styled(Tab).attrs((props) => ({ variant: 'button', ...props }))<{
  $disabled?: boolean;
}>`
  justify-content: start;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  min-height: 3rem;
  visibility: visible;
  &:first-child {
    margin-top: 2.1rem;
  }

  &:not(.Mui-selected) svg {
    color: ${theme.palette.text.secondary};
  }

  &:focus {
    outline: none;
  }

  ${getNewChip()}
  ${({ $disabled }) => $disabled && 'display: none;'}
`;

const ArchiveSvgIcon = styled(SvgIcon)<{ component: React.ElementType }>`
  color: ${theme.palette.primary.main};
  margin-right: 0.6rem;
`;

const LaborerHistoryIcon = styled(SvgIcon).attrs<SvgIconProps>({ component: WatchLaterIcon })`
  color: ${theme.palette.primary.main};
  margin-right: 0.6rem;
`;

const VehicleHistoryIcon = styled(SvgIcon).attrs<SvgIconProps>({ component: LocalShippingIcon })`
  color: ${theme.palette.primary.main};
  margin-right: 0.6rem;
`;
