import { lazy } from 'react';
const UploadQuickShootingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "UploadQuickShootingPage" */
      'features/quickShooting/upload/components/uploadPage/UploadQuickShootingPage'
    ),
);

function UploadQuickShooting() {
  return <UploadQuickShootingPage />;
}

export default UploadQuickShooting;
