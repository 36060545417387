import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps,
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { ChangeEvent, useEffect, useState } from 'react';
import CustomTextField from './textfield/CustomTextField';

interface Props {
  onTextChange: (e: ChangeEvent<any>) => void;
  onNoMatchError?: () => void;
  error?: string;
  maxLength: number;
  optionsUpToDate?: boolean;
  name?: string;
  prefix?: string;
  placeholder?: string;
}
export type AutocompleteProps = Props &
  Omit<MuiAutocompleteProps<any, false, false, false>, 'renderInput'>;
export default function Autocomplete({
  options,
  value,
  placeholder = '',
  onTextChange,
  onChange: onOptionChange,
  filterOptions,
  optionsUpToDate = value.length > 0,
  maxLength,
  error,
  onNoMatchError,
  name = '',
  prefix,
  ...rest
}: AutocompleteProps) {
  useEffect(() => {
    const hasNoMatches = optionsUpToDate && options.length === 0;
    if (hasNoMatches && onNoMatchError) {
      onNoMatchError();
    }
  }, [optionsUpToDate, options]);

  const filter = filterOptions || createFilterOptions();
  const onTextFocus = () => setOpen(true);
  const [open, setOpen] = useState(false);

  return (
    <MuiAutocomplete
      options={filter(options, { inputValue: value, getOptionLabel: rest.getOptionLabel })}
      renderInput={(params) => (
        <div ref={params.InputProps.ref}>
          <CustomTextField
            {...params.inputProps}
            value={value}
            onChange={onTextChange}
            onFocus={onTextFocus}
            placeholder={placeholder}
            error={error}
            maxLength={maxLength}
            name={name}
            prefix={prefix}
          />
        </div>
      )}
      onChange={onOptionChange}
      freeSolo
      open={open}
      onOpen={(_) => setOpen(true)}
      onClose={(_, reason) => {
        // 텍스트 인풋을 그대로 누를 경우, 자동완성 리스트가 닫히며
        // 다시 텍스트 인풋을 누르기 전까지 리스트가 열리지 않음
        // 이 동작이 다소 어색해 강제로 막는 분기를 아래에 삽입
        if (reason === 'toggleInput') {
          setOpen(true);
          return;
        }
        setOpen(false);
      }}
      {...rest}
    />
  );
}
