import { ListItemIcon } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

interface Props {
  color?: string;
  width?: string;
  height?: string;
  children: React.JSX.Element;
}

export default function ListStartIcon({ children, color, width, height }: Props) {
  return (
    <StyledListItemIcon $color={color} $width={width} $height={height}>
      {children}
    </StyledListItemIcon>
  );
}

const StyledListItemIcon = styled(ListItemIcon)<{
  $color?: string;
  $width?: string;
  $height?: string;
}>`
  min-width: auto;
  transition: opacity 0.1s;

  & svg {
    width: ${({ $width }) => $width || '1.25rem'};
    height: ${({ $height }) => $height || '1.25rem'};
    ${({ $color }) =>
      $color &&
      `
      color: ${$color};
      path {
        fill: ${$color};
        fill-opacity: 1;
      }
    `}
  }
`;
