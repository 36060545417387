import { useMutation, useQueryClient } from 'react-query';
import { _NotificationUpdateApiRequestPayload } from '../types';
import api from './api';
import projectManagementQueryKeys from './queryKeys';

export default function useNotificationMutation() {
  const queryClient = useQueryClient();

  const updateNotificationMutation = useMutation(
    (payload: _NotificationUpdateApiRequestPayload) => api.notification.update(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(projectManagementQueryKeys.list());
      },
    },
  );

  return { updateNotificationMutation };
}
