import { useMatchParams } from 'features/common/hooks';
import useGlobalDialog from 'features/common/hooks/useGlobalDialog';
import useProjectQuery from 'features/general/project/query/useProjectQuery';
import routes from 'pages/routes';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { canManipulateOtherUser } from 'shared/common/policies/permission';
import { nls } from 'shared/locale/language';
import { usePermissionQuery } from 'shared/query';
import {
  useParticipantListQuery,
  useParticipantMutation,
} from 'shared/query/participant/useParticipantQuery';

export default function useProjectParticipantManagementViewModel() {
  const history = useHistory();
  const { showAlert, showConfirm } = useGlobalDialog();

  const { projectId } = useMatchParams();
  const { data } = useProjectQuery(projectId);
  const projectName = data?.name ?? '';

  const [deleteMode, setDeleteMode] = useState(false);
  const [checked, setChecked] = useState({} as { [id: number]: boolean });
  const [allChecked, setAllChecked] = useState(false);
  const [anyChecked, setAnyChecked] = useState(false);
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const [deletionResponseDisplayed, setDeletionResponseDisplayed] = useState(false);

  const { data: permission } = usePermissionQuery(projectId);
  const { data: participants } = useParticipantListQuery(projectId);
  const { deleteParticipant } = useParticipantMutation();
  const projectUsers = participants?.projectUsers ?? [];
  const invitees = participants?.invites ?? [];
  const deletionStatus = deleteParticipant.status;

  useEffect(() => {
    if (!invitees?.length && !projectUsers?.length) return;
    initializeChecked();
  }, [invitees, projectUsers]);

  useEffect(() => {
    !deleteMode && initializeChecked();
  }, [deleteMode]);

  useEffect(() => {
    if (!data) return;
    const items = Object.values(checked);
    const allItemsChecked = items.length > 0 && items.every((x) => x);
    setAllChecked(allItemsChecked);
    setAnyChecked(Object.values(checked).some((x) => x));
  }, [checked]);

  useEffect(() => {
    if (!deletionResponseDisplayed && deletionStatus === 'error') {
      showAlert({ content: nls.failedToDeleteUser() });
      setDeletionResponseDisplayed(true);
    }
  }, [deletionStatus]);

  function initializeChecked() {
    const newChecked = {};
    [...invitees, ...projectUsers]
      .filter((x) => canManipulateOtherUser(x))
      .forEach((x) => {
        newChecked[x.id] = false;
      });
    setChecked(newChecked);
  }

  function onClickTitle() {
    history.push(routes.setting.project.path);
  }

  function onClickDelete() {
    if (!deleteMode) {
      setDeleteMode(true);
      return;
    }

    if (Object.values(checked).every((x) => !x)) {
      showAlert({ content: nls.noMemberSelected() });
      return;
    }

    const count = Object.values(checked).filter((x) => x).length;
    showConfirm({
      title: nls.deleteUserTitle(),
      content: nls.deleteUser(count),
      primaryButtonProps: {
        title: nls.delete(),
        onClick: onDeleteConfirm,
        color: 'secondary',
      },
    });
  }

  function onDeleteConfirm() {
    deleteParticipant.mutate({
      projectId,
      data: {
        projectUsers: projectUsers.map((x) => x.id).filter((x) => checked[x]),
        invites: invitees.map((x) => x.id).filter((x) => checked[x]),
      },
    });
    setDeletionResponseDisplayed(false);
  }

  function onClickCancelDelete() {
    if (anyChecked) {
      showConfirm({
        title: nls.titleExitWithoutSaving(),
        content: nls.exitWithoutSaving(),
        primaryButtonProps: {
          title: nls.confirm(),
          onClick: () => setDeleteMode(false),
        },
      });
      return;
    }
    setDeleteMode(false);
  }

  function onClickInvite() {
    setShowInvitationModal(true);
  }
  function onCloseInvitationModal() {
    setShowInvitationModal(false);
  }

  function onToggleCheckbox(id: number, x: boolean) {
    setChecked((prev) => ({ ...prev, [id]: x }));
  }

  function onAllCheckedChange(x: boolean) {
    setChecked((prev) => {
      const newChecked = {};
      Object.keys(prev).forEach((id) => {
        newChecked[id] = x;
      });
      return newChecked;
    });
  }

  const hasSendMailPermission = permission && permission.userPost;
  const showDeleteButton = permission && permission.userDelete;
  const showInviteButton = !deleteMode && permission && permission.userPost;

  return {
    projectId,
    projectName,
    onClickTitle,
    onClickDelete,
    onClickInvite,
    onClickCancelDelete,

    projectUsers,
    invitees,
    deleteMode,
    allChecked,
    checked,
    onToggleCheckbox,
    onAllCheckedChange,

    hasSendMailPermission,
    showDeleteButton,
    showInviteButton,

    showInvitationModal,
    onCloseInvitationModal,
  };
}
