import Check from '@mui/icons-material/Check';
import { TextButtonWithBackground } from 'features/common/button/TextButton';
import { useMatchParams } from 'features/common/hooks';
import useGlobalDialog from 'features/common/hooks/useGlobalDialog';
import EditorPageLayout from 'features/styleComponents/editorPageLayout';
import { useAtomValue } from 'jotai';
import routes from 'pages/routes';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { sendTwoDEarthworkZoneEditorSaveBtn } from 'shared/ga/actions/twoDEarthworkZone';
import { nls } from 'shared/locale/language';
import theme from 'shared/styles/mui/theme';
import { drawingVectorEditingAtom } from 'stores/v3/snapshotDetail/twoD/annotation/drawingVector';
import styled from 'styled-components';
import DrawingVectorEditorLeftSideBar from './DrawingVectorEditorLeftSideBar';
import DrawingVectorEditorMap from './DrawingVectorEditorMap';
import DrawingVectorEditorRightSideBar from './DrawingVectorEditorRightSideBar';
import SaveConfirm from './SaveConfirm';
import useDrawingVectorEdit from './useDrawingVectorEdit';

export default function DrawingVectorEditorPage() {
  const history = useHistory();
  const { projectId, zoneId, snapshotId, resourceId } = useMatchParams();
  const { showConfirm } = useGlobalDialog();
  const selectedPolygonVectorTempId = useAtomValue(
    drawingVectorEditingAtom.selectedBulkPolygonTempId,
  );

  const { reset, dirty, save } = useDrawingVectorEdit();

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const [openSaveConfirm, setOpenSaveConfirm] = useState(false);

  function onClickBack() {
    if (dirty) {
      showConfirm({
        title: nls.titleExitWithoutSaving(),
        content: nls.exitWithoutSaving(),
        primaryButtonProps: {
          onClick: () => goToDrawingVectorDetail(),
        },
      });
    } else {
      goToDrawingVectorDetail();
    }
  }
  function goToDrawingVectorDetail() {
    const url = routes.drawingVector.show.of({
      projectId,
      zoneId,
      snapshotId,
      resourceId,
    });
    history.replace(url, { save: true });
  }

  function onClickSave() {
    setOpenSaveConfirm(true);
  }

  function handleSave(data: { title: string; description: string; effectiveDate: string }) {
    save(data);
    setOpenSaveConfirm(false);
    goToDrawingVectorDetail();
    sendTwoDEarthworkZoneEditorSaveBtn();
  }

  return (
    <EditorPageLayout>
      <EditorPageLayout.TopBar
        onBack={onClickBack}
        centerMessage={nls.earthworkAreaPlanEditorHeaderMessage()}
      >
        <SaveButton onClick={onClickSave} disabled={!dirty} startIcon={<Check />}>
          {nls.save()}
        </SaveButton>
      </EditorPageLayout.TopBar>
      <DrawingVectorEditorLeftSideBar />
      {selectedPolygonVectorTempId && <DrawingVectorEditorRightSideBar />}
      <DrawingVectorEditorMap />
      <SaveConfirm
        open={openSaveConfirm}
        onConfirm={handleSave}
        onCancel={() => setOpenSaveConfirm(false)}
      />
    </EditorPageLayout>
  );
}

const SaveButton = styled(TextButtonWithBackground).attrs({
  size: 'medium',
  color: 'primary',
})`
  &.MuiButtonBase-root {
    background-color: ${theme.palette.primary.dark};
    color: ${theme.palette.primary.contrastText};
  }
  &.MuiButtonBase-root.Mui-disabled {
    background-color: ${theme.palette.grey[600]};
    color: ${theme.palette.text.disabled};
  }
  .MuiButton-label {
    color: inherit;
  }

  &:nth-child(n + 2) {
    margin-top: 0.5rem;
  }
  &.mt-0 {
    margin-top: 0;
  }
`;
