import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@mui/icons-material/GetApp';
import QRCodeLogo from 'assets/images/qrCodeLogo.svg';
import { TextButtonWithBackground } from 'features/common/button/TextButton';
import WidePopup from 'features/common/modal/WidePopup';
import CopyField from 'features/common/textfield/CopyField';
import { QRCodeCanvas } from 'qrcode.react';
import { QR_CODE_DOWNLOAD_FILE_TYPE } from 'shared/common/policies/file';
import {
  QRCodeCanvasSize,
  QRCodeLogoHeight,
  QRCodeLogoWidth,
  QRCodeSize,
} from 'shared/common/policies/sizes';
import { downloadFile } from 'shared/common/utils/file';
import { nls } from 'shared/locale/language';
import { others } from 'shared/styles/colors/others';
import { Project } from 'stores/data/types';
import styled from 'styled-components';

interface Props {
  data: Project;
  onClose: () => void;
}

export default function QRCodeDownloadModal({ data, onClose }: Props) {
  const QRCodeWrapperClassName = 'QRCodeWrapper';
  const QRCodeCanvasStyle = {
    width: QRCodeCanvasSize,
    height: QRCodeCanvasSize,
  };
  const QRCodeImageSetttings = {
    src: QRCodeLogo,
    width: QRCodeLogoWidth,
    height: QRCodeLogoHeight,
    excavate: false,
  };

  const QRCodeValue = `${window.location.origin}/guard/${data?.otp}`;

  const downloadQRCode = () => {
    let QRCodeCanvasElement = document.querySelector(
      `.${QRCodeWrapperClassName} canvas`,
    ) as HTMLCanvasElement;
    const QRCodeDataURL = QRCodeCanvasElement.toDataURL(QR_CODE_DOWNLOAD_FILE_TYPE);

    const name = `${data.name}.${QR_CODE_DOWNLOAD_FILE_TYPE.split('/').pop()}`;
    downloadFile(QRCodeDataURL, name);
    QRCodeCanvasElement = null;
  };

  return (
    <WidePopup isShowing closeWidePopup={onClose} title={nls.projectQRCodeDownload()}>
      <PopupBody>
        <Typography align="center" variant="h5">
          {nls.projectQRCodeForMeiday()}
        </Typography>
        <QRCodeWrapper className={QRCodeWrapperClassName}>
          <QRCodeCanvas
            includeMargin
            size={QRCodeSize}
            style={QRCodeCanvasStyle}
            value={QRCodeValue}
            imageSettings={QRCodeImageSetttings}
          />
        </QRCodeWrapper>
        <PopupBodyText>{nls.projectQRCodeDescription()}</PopupBodyText>
        <CopyFieldWrapper>
          <CopyField text={QRCodeValue} />
        </CopyFieldWrapper>
      </PopupBody>
      <PopupFooter>
        <ButtonWithBackground startIcon={<GetAppIcon />} onClick={downloadQRCode}>
          {nls.projectQRCodeImageDownload()}
        </ButtonWithBackground>
      </PopupFooter>
    </WidePopup>
  );
}

const ButtonWithBackground = styled(TextButtonWithBackground).attrs({
  color: 'primary',
  size: 'large',
})``;

const PopupBody = styled.div`
  padding: 3.75rem 2.5rem;
`;

const PopupBodyText = styled(Typography).attrs({ align: 'center', variant: 'body2' })`
  white-space: pre-line;
`;

const PopupFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  padding: 2rem 4rem 2.5rem;
  border-top: 1px solid ${others.DIVIDER};
`;

const QRCodeWrapper = styled.div`
  canvas {
    display: block;
    margin: 0.125rem auto;
  }
`;

const CopyFieldWrapper = styled.div`
  padding: 0 4.5rem;
`;
