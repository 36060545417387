import Dialog from 'features/styleComponents/dialog';
import { DialogProps } from 'features/styleComponents/dialog/types';
import { useAtomValue } from 'jotai';
import { globalDialogAtom } from 'stores/v3/dialog';

/** 최상위 컴포넌트에 추가되어 있는 전역 다이얼로그 컴포넌트
 *  - 전역 다이얼로그 상태(jotai)를 조회하여 Dialog 컴포넌트를 렌더링합니다.
 *  - 사용을 위해서는 useGlobalDialog 커스텀 훅을 사용해야 합니다.
 */

export default function GlobalDialog() {
  const dialog = useAtomValue(globalDialogAtom);
  if (!dialog?.open) return null;

  return <Dialog {...(dialog as DialogProps)} />; // XXX 16->18
}
