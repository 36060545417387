import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from 'features/common/button/IconButton';
import React from 'react';
import styled from 'styled-components';
import { hexToRgba } from 'shared/styles/colors/utils';
import theme from 'shared/styles/mui/theme';

interface Props {
  open: boolean;
  title?: string;
  onDelete?: () => void;
  onClose?: () => void;
  children: React.ReactNode;
}

export default function RightSideBar({ open, title, onDelete, onClose, children }: Props) {
  if (!open) return null;
  return (
    <Container>
      {title && (
        <Header>
          <HeaderTitle color="inherit" variant="subtitle1">
            {title}
          </HeaderTitle>
          {onDelete && (
            <HeaderButton onClick={onDelete}>
              <DeleteIcon />
            </HeaderButton>
          )}
          {onClose && (
            <HeaderButton onClick={onClose}>
              <CloseIcon />
            </HeaderButton>
          )}
        </Header>
      )}
      <ContentArea>{children}</ContentArea>
    </Container>
  );
}

const Container = styled.aside`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 4rem;
  width: 17.125rem;
  height: calc(100% - 4rem);
  max-height: calc(100% - 4rem);
  overflow-y: hidden;
  background-color: ${hexToRgba(theme.palette.grey[900], 0.9)};
  color: ${theme.palette.primary.contrastText};
  z-index: ${theme.zIndex.appBar};
`;

const Header = styled.div`
  display: flex;
  padding: 1rem 1.25rem;
  align-items: center;
  gap: 0.25rem;
  align-self: stretch;
  border-bottom: 1px solid ${theme.palette.grey[800]};
`;

const HeaderTitle = styled(Typography)`
  margin-right: auto;
`;

const HeaderButton = styled(IconButton).attrs({ size: 'small' })`
  color: ${theme.palette.primary.contrastText};
`;

const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: ${theme.palette.grey[600]} transparent;
  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: ${theme.palette.grey[600]};
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${theme.palette.grey[700]};
  }
`;
