import { isGreenDevMode } from 'shared/common/utils/env';

// AWS 폴더명이자 URL에 들어가는 도메인명 - meissa 제외
export enum CustomizingType {
  POSCO = 'poscoenc',
  KOLON = 'kolon',
  SNGIS = 'sngis',
  KCC = 'kccworld',
  HDC = 'hdc',
  LOTTE = 'lotte',
  NAMI = 'nami',
  DONGBU = 'dongbu',
  GREEN = 'green',
  MEISSA = 'cloud',
}

export function isPosco() {
  const organization = import.meta.env.VITE_ORGANIZATION;
  return organization === 'POSCO';
}

export const getCustomizingType = () => {
  const { hostname } = window.location;
  const cType = Object.values(CustomizingType).find((type) => hostname.includes(`${type}.`));
  return cType || CustomizingType.MEISSA;
};

export function isGreen() {
  return getCustomizingType() === CustomizingType.GREEN || isGreenDevMode();
}

// TODO: Remove this function later - 프로젝트에서 일본 프로젝트인지 아닌지 떨어트려주어야 함
export function isJapan() {
  return window.location.hostname.includes('jp-cloud.');
}
