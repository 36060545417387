import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'array.prototype.fill';
import { createBrowserHistory } from 'history';
import Cookies from 'js-cookie';
import 'leaflet';
import 'leaflet/dist/leaflet.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGa from 'react-ga4';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Router } from 'react-router';
import 'sanitize.css/sanitize.css';
import { isDev } from 'shared/common/utils/env';
import GA from 'shared/ga';
import { welcomeToMeissa } from 'shared/services/versionInformation.service';
import App from './App';

const history = createBrowserHistory();

if (import.meta.env.VITE_SENTRY_KEY) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_KEY,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    release: import.meta.env.RELEASE,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 0.01, // performance 측정할 sampling data 전송비율
  });
}

const queryClient = new QueryClient();
const GAEnabled = GA.init();

const encryptedUserId = Cookies.get('userId');
ReactGa.set({ user_id: encryptedUserId });
welcomeToMeissa();

const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        {GAEnabled && <GA />}
        <App />
      </Router>
      {isDev && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  </React.StrictMode>,
);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/sw.js', { type: 'module' });
}
