import { apiAxios } from 'shared/common/api/common';
import {
  _NotificationUpdateApiRequestPayload,
  _ParticipantCreateApiRequestPayload,
} from '../types';

export default {
  async list() {
    const response = await apiAxios.get(`/api/v4/project/management`);
    return response.data.result?.projectList ?? [];
  },
  participant: {
    async create(payload: _ParticipantCreateApiRequestPayload) {
      const response = await apiAxios.post(`/api/v4/project/participant`, payload);
      return response.data.result;
    },
  },
  notification: {
    async update(payload: _NotificationUpdateApiRequestPayload) {
      const response = await apiAxios.post(
        `/api/v4/notification/setting/project/${payload.projectId}`,
        payload,
      );
      return response.data.result;
    },
  },
};
