import { styled } from '@mui/material/styles';
import MuiTooltip from '@mui/material/Tooltip';
import { others } from 'shared/styles/colors/others';

const StyledTooltip = styled(MuiTooltip)(({ theme }) => ({
  tooltip: {
    backgroundColor: others.TOOLTIP_BACKGROUND,
    color: theme.palette.primary.contrastText,
    borderRadius: '0.25rem',
    fontSize: '0.875rem',
    fontWeight: 500,
    padding: '0.25rem 0.5rem',
  },
  arrow: {
    color: others.TOOLTIP_BACKGROUND,
  },
}));

export default StyledTooltip;
