import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useState } from 'react';
import { PASSWORD_MAX_LENGTH } from 'shared/common/policies/limit';
import { nls } from 'shared/locale/language';
import OutlinedTextField from './OutlinedTextField';

export default function PasswordTextField(props) {
  const [isPasswordType, setPasswordType] = useState(true);

  const handleClickShowPassword = () => {
    setPasswordType((type) => !type);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const passwordVisibilityElem = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge="end"
      >
        {isPasswordType ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );

  const passwordInputProps = { endAdornment: passwordVisibilityElem };
  const passwordInputType = isPasswordType ? 'password' : 'text';
  const inputProps = { maxLength: PASSWORD_MAX_LENGTH };

  return (
    <OutlinedTextField
      inputProps={inputProps}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={passwordInputProps}
      id="outlined-password"
      className="password"
      name="password"
      label={nls.password()}
      type={passwordInputType}
      {...props}
    />
  );
}
