import CircularProgress from '@mui/material/CircularProgress';

import ButtonMUI, { ButtonProps } from '@mui/material/Button';
import { ButtonPropsColorOverrides } from '@mui/material/Button/Button';
import { OverridableStringUnion } from '@mui/types';
import { LinkProps } from 'react-router-dom';
import { hexToRgba } from 'shared/styles/colors/utils';
import palette from 'shared/styles/mui/palette';
import styled, { css } from 'styled-components';

const DEFAULT_OPACITY = 0.08;
const HOVER_OPACITY = 0.22;
const SECONDARY_HOVER_OPACITY = 0.05;
const ACTIVE_OPACITY = 0.35;
const LOADING_OPACITY = 0.4;

export default function TextButton(props: ButtonProps & { warning?: boolean }) {
  const { onClick, children, ...restProps } = props;

  return (
    <TextButtonEx onClick={onClick} variant="text" {...restProps}>
      {children}
    </TextButtonEx>
  );
}

export const getDialogButton = (
  onClick,
  btnTxt,
  color: OverridableStringUnion<
    'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    ButtonPropsColorOverrides
  > = 'primary',
  disabled = false,
) => {
  return (
    <TextButtonEx
      onClick={onClick}
      key={btnTxt}
      color={color}
      disabled={disabled}
      variant="text"
      size="medium"
    >
      {btnTxt}
    </TextButtonEx>
  );
};

const TextButtonEx = styled(ButtonMUI)<ButtonProps>`
  ${({ color, disabled }) =>
    !disabled &&
    (color === 'inherit' || !color) &&
    css`
      && {
        color: ${palette.text.primary};
        &:hover {
          background-color: ${hexToRgba(palette.secondary.main, SECONDARY_HOVER_OPACITY)};
        }
      }
    `}

  min-width: auto;
`;

/**
 * 기본일 때도 배경색 존재하는 TextButton
 * @link https://www.figma.com/file/PP9uWWFob8FJNr7H21Hkbx/Platform-3.0-Redesign-Web?node-id=4576%3A2200
 */
type TextButtonWithBackgroundType = ButtonProps & { $loading?: boolean; warning?: boolean };
type LinkPropsType = TextButtonWithBackgroundType & LinkProps;

const getCircularProgressSize = (size: string): string => {
  switch (size) {
    case 'large':
      return '1.5rem';
    case 'small':
      return '1.125rem';
    default:
      return '1.25rem';
  }
};

export const TextButtonWithBackground = styled(ButtonMUI).attrs<
  TextButtonWithBackgroundType | LinkPropsType
>(({ $loading, size, startIcon, disabled, warning }) => ({
  variant: 'text',
  startIcon: $loading ? <CircularProgress size={getCircularProgressSize(size)} /> : startIcon,
  disabled: disabled || $loading,
  warning,
}))<TextButtonWithBackgroundType | LinkPropsType>`
  ${({ warning }) =>
    warning &&
    css`
      background-color: ${palette.error.main};
      color: ${palette.common.white};
      &:hover {
        background-color: ${palette.error.dark};
      }
    `}
  ${({ color }) =>
    color === 'inherit' || !color
      ? css`
          && {
            color: ${palette.text.primary};
            background-color: ${hexToRgba(palette.text.primary, DEFAULT_OPACITY)};
            &:hover {
              background-color: ${hexToRgba(palette.text.primary, HOVER_OPACITY)};
            }
            &:active {
              background-color: ${hexToRgba(palette.text.primary, ACTIVE_OPACITY)};
            }
          }
        `
      : css`
          && {
            color: ${palette[color].main};
            background-color: ${hexToRgba(palette[color].main, DEFAULT_OPACITY)};
            &:hover {
              background-color: ${hexToRgba(palette[color].main, HOVER_OPACITY)};
            }
            &:active {
              background-color: ${hexToRgba(palette[color].main, ACTIVE_OPACITY)};
            }
          }
        `}

  ${({ $loading }) =>
    $loading
      ? css`
          &&.Mui-disabled {
            opacity: ${LOADING_OPACITY};
          }
        `
      : css`
          &&.Mui-disabled {
            background-color: ${palette.action.disabledBackground};
            color: ${palette.text.disabled};
          }
        `}

  .MuiButton-iconSizeSmall .MuiCircularProgress-root {
    width: 1.125rem !important;
    height: 1.125rem !important;
    font-size: 1.125rem !important;
  }
  .MuiButton-iconSizeLarge .MuiCircularProgress-root {
    width: 1.5rem !important;
    height: 1.5rem !important;
    font-size: 1.5rem !important;
  }
`;
