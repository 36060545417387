import { createTheme } from '@mui/material';
import { action } from 'shared/styles/colors/action';
import { background } from 'shared/styles/colors/background';
import { error } from 'shared/styles/colors/error';
import { grey } from 'shared/styles/colors/grey';
import { info } from 'shared/styles/colors/info';
import { others } from 'shared/styles/colors/others';
import { primary } from 'shared/styles/colors/primary';
import { secondary } from 'shared/styles/colors/secondary';
import { success } from 'shared/styles/colors/success';
import { text } from 'shared/styles/colors/text';
import { warning } from 'shared/styles/colors/warning';
import palette from 'shared/styles/mui/palette';
import globalComponentStyles from './globalComponentStyles';
import typography from './typograpy';

/**
 * MUI v6 기본 테마 설정을 한눈에 볼 수 있습니다.
 * default-theme 참조 : https://mui.com/material-ui/customization/default-theme/
 */

export const globalTheme = createTheme({
  palette: {
    primary: {
      main: primary.MAIN,
      dark: primary.DARK,
      light: primary.LIGHT,
      contrastText: primary.CONTRAST_TEXT,
    },
    secondary: {
      main: secondary.MAIN,
      dark: secondary.DARK,
      light: secondary.LIGHT,
      contrastText: secondary.CONTRAST_TEXT,
    },
    error: {
      main: error.MAIN,
      dark: error.DARK,
      light: error.LIGHT,
      contrastText: error.CONTRAST_TEXT,
    },
    warning: {
      main: warning.MAIN,
      dark: warning.DARK,
      light: warning.LIGHT,
      contrastText: warning.CONTRAST_TEXT,
    },
    info: {
      main: info.MAIN,
      dark: info.DARK,
      light: info.LIGHT,
      contrastText: info.CONTRAST_TEXT,
    },
    success: {
      main: success.MAIN,
      dark: success.DARK,
      light: success.LIGHT,
      contrastText: success.CONTRAST_TEXT,
    },
    grey: {
      50: grey.GREY_50,
      100: grey.GREY_100,
      200: grey.GREY_200,
      300: grey.GREY_300,
      400: grey.GREY_400,
      500: grey.GREY_500,
      600: grey.GREY_600,
      700: grey.GREY_700,
      800: grey.GREY_800,
      900: grey.GREY_900,
      A100: grey.GREY_A100,
      A200: grey.GREY_A200,
      A400: grey.GREY_A400,
      A700: grey.GREY_A700,
    },
    text: {
      primary: text.PRIMARY,
      secondary: text.SECONDARY,
      disabled: text.DISABLED,
    },
    background: {
      paper: background.PAPER,
      default: background.DEFAULT,
    },
    action: {
      hover: action.HOVER,
      selected: action.SELECTED,
      disabledBackground: action.DISABLED_BACKGROUND,
      // active, hoverOpacity, selectedOpacity, disabled, disabledOpacity, focus, focusOpacity, activatedOpacity
    },
    divider: others.DIVIDER,
  },
  /** @todo typography 타입 관련 확인 필요 */
  typography: typography as any,
  zIndex: {
    mobileStepper: 1000,
    fab: 1050,
    speedDial: 1050,
    appBar: 1200, // header, gnb
    drawer: 1100, // side menu
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  components: {
    ...globalComponentStyles,
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0.25rem',
          boxShadow: 'none',
          padding: '0.5rem 1rem',
          wordBreak: 'keep-all',
          '&:focus': {
            outline: 'none',
          },
        },
        startIcon: {
          display: 'inline-block',
          marginLeft: '-0.25rem',
          marginRight: '0.25rem',
          '.MuiButton-iconSizeMedium > *:first-child': {
            fontSize: '1.25rem',
          },
        },
        endIcon: {
          display: 'inline-block',
          marginLeft: '0.5rem',
          marginRight: '-0.25rem',
          '.MuiButton-iconSizeMedium > *:first-child': {
            fontSize: '1.25rem',
          },
        },
        sizeLarge: {
          padding: '0.5rem 1.375rem',
          '.MuiButton-iconSizeLarge > *:first-child': {
            fontSize: '1.375rem',
          },
        },
        sizeSmall: {
          padding: '0.25rem 0.625rem',
          fontSize: '0.8125rem',
          lineHeight: '1.3rem',
          '&.MuiButton-iconSizeSmall': {
            '&.MuiButton-startIcon': {
              marginLeft: '-0.125rem',
            },
            '&.MuiButton-endIcon': {
              marginRight: '-0.125rem',
            },
            '> *:first-child': {
              fontSize: '1.125rem',
            },
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {},
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& > .MuiOutlinedInput-notchedOutline': {
            borderColor: others.BORDER,
          },
          '.MuiInputBase-sizeSmall': {
            height: '2.5rem',
          },
        },
        outlined: {
          borderColor: others.BORDER,
        },
        filled: {
          padding: '0.5rem 1.38rem',
          backgroundColor: palette.grey[200],
          borderRadius: '0.25rem',
        },
        select: {
          borderColor: others.BORDER,
          padding: '0.594rem 1.5rem 0.594rem 0.875rem',
          lineHeight: 1.75,
          backgroundColor: palette.common.white,
          '&:focus': {
            backgroundColor: 'inherit',
          },
        },
        icon: {
          marginRight: 6,
          marginTop: 2,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${palette.divider}`,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {},
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
          '.MuiTypography-root': {
            marginLeft: '0.5rem',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          flexDirection: 'column',
          padding: '0.5rem 0 0.75rem',
          position: 'relative',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {},
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          flexDirection: 'row',
          alignItems: 'center',
          '*:first-child': {
            marginBottom: 0,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          '.MuiAutocomplete-option': {
            '&[aria-selected="true"]': {
              backgroundColor: action.SELECTED,
            },
            '&.Mui-focused[aria-selected="true"]': {
              backgroundColor: action.HOVER,
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected.Mui-focusVisible': {
            backgroundColor: action.SELECTED,
          },
          '&.Mui-selected': {
            backgroundColor: action.SELECTED,
          },
          '&.Mui-selected:hover': {
            backgroundColor: action.HOVER,
          },
        },
      },
    },
  },
});
