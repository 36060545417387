import { grey } from './grey';
import { text } from './text';
import { hexToRgba } from './utils';

const DIVIDER = '#E2E2E3';
/** rgba(29, 33, 37, 0.5) */
const BACKDROP = hexToRgba(text.PRIMARY, 0.5);
const SNACKBAR_BACKGROUND = '#1D2125';
const TRANSPARENT = 'transparent';
const BORDER = '#DDDEDE';
const TOOLTIP_BACKGROUND = grey.GREY_900;
/** @deprecated
 * src/shared/styles/muiV6/colors.ts 또는 Mui theme의 palette 값으로 대체해주세요.
 */
export const others = {
  DIVIDER,
  BACKDROP,
  SNACKBAR_BACKGROUND,
  TRANSPARENT,
  BORDER,
  TOOLTIP_BACKGROUND,
};
