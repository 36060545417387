import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useDefaultEventBlocker } from 'features/common/hooks';
import NavigationBar from 'features/common/NavigationBar';
import Snackbar from 'features/styleComponents/snackbar/Snackbar';
import routes from 'pages/routes';
import { Route, Switch } from 'react-router';
import { nls } from 'shared/locale/language';
import theme from 'shared/styles/mui/theme';
import styled from 'styled-components';
import MyInformationManagement from './myInformationManagement/MyInformationManagement';
import ProjectDetailManagement from './projectDetailManagement/ProjectDetailManagement';
import ProjectListManagement from './projectListManagement/ProjectListManagement';
import ProjectParticipantManagement from './projectParticipantManagement/ProjectParticipantManagement';
import SettingLeftPanel from './SettingLeftPanel';

export default function SettingPage() {
  useDefaultEventBlocker(window, 'contextmenu');

  return (
    <ProjectSettingPageRoot>
      <NavigationBar />
      <Container>
        <Header>
          <Box sx={{ position: 'absolute', left: '4.5rem' }}>
            <Button
              variant="text"
              onClick={() => window.history.back()}
              startIcon={<ArrowBackIcon />}
              color="secondary"
              sx={{
                '& .MuiButton-startIcon': {
                  color: theme.palette.text.secondary,
                },
              }}
            >
              {nls.back()}
            </Button>
          </Box>
          <Typography variant="h5">{nls.projectSettingButton()}</Typography>
        </Header>
        <Body>
          <SettingLeftPanel />
          <Switch>
            <Route exact path={routes.setting.myInfo.path} component={MyInformationManagement} />
            <Route exact path={routes.setting.project.path} component={ProjectListManagement} />
            <Route path={routes.setting.project.detail.path} component={ProjectDetailManagement} />
            <Route
              path={routes.setting.project.participant.path}
              component={ProjectParticipantManagement}
            />
            <Snackbar />
          </Switch>
        </Body>
      </Container>
    </ProjectSettingPageRoot>
  );
}

const ProjectSettingPageRoot = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${theme.palette.background.paper};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 4rem;
  min-height: 4rem;
  border-bottom: 1px solid ${theme.palette.divider};
  box-sizing: border-box;
  padding: 0 0.5rem;
`;

const Body = styled.div`
  display: flex;
  height: 100%;
  max-height: 100%;
`;
