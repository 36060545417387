import { useMutation, useQueryClient } from 'react-query';
import api from './api';
import { keys } from './queryKeys';

export default function useProjectMutation() {
  const queryClient = useQueryClient();
  const updateProject = useMutation((payload: any) => api.update(payload), {
    onSuccess: (_, v) => {
      queryClient.invalidateQueries(keys.detail(v?.projectId));
    },
  });
  const createProject = useMutation((payload: any) => api.create(payload), {
    onSuccess: (_, v) => queryClient.invalidateQueries(keys.update()),
  });
  const deleteProject = useMutation((payload: any) => api.delete(payload), {
    onSuccess: (_, v) => {
      queryClient.invalidateQueries(keys.update());
    },
  });

  return { updateProject, createProject, deleteProject };
}
