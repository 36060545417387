import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import { nls } from 'shared/locale/language';
import { ArchiveFileTableRow } from 'shared/query/fileArchive/types';
import * as React from 'react';
import styled from 'styled-components';
import { HeaderRow, Row } from './FileArchiveTableRow';

interface Props {
  rows: ArchiveFileTableRow[];
  isLoading: boolean;
  isDirty: boolean;
  canDownloadFile: boolean;
  canDeleteFile: boolean;
  canDeleteAnnotation: boolean;
}

export default function FileArchiveTable({
  rows,
  isLoading,
  isDirty,
  canDownloadFile,
  canDeleteFile,
  canDeleteAnnotation,
}: Props) {
  const isEmpty = rows?.length === 0;
  const downloadDisabled = !canDownloadFile;
  const deleteDisabled = !canDeleteFile && !canDeleteAnnotation;

  return (
    <TableWrapper>
      <Table>
        <TableHead>
          <HeaderRow downloadDisabled={downloadDisabled} deleteDisabled={deleteDisabled} />
        </TableHead>
        {!isEmpty && !isLoading && (
          <TableBody>
            {rows?.map((row) => (
              <Row key={row.id} data={row} />
            ))}
          </TableBody>
        )}
      </Table>
      {isEmpty && (
        <EmptyBox>
          <Typography variant="body1" color="textSecondary" align="center">
            {isDirty ? nls.fileArchiveSearchEmptyMessage() : nls.fileArchiveEmptyMessage()}
          </Typography>
        </EmptyBox>
      )}
      {isLoading && (
        <EmptyBox>
          <CircularProgress />
        </EmptyBox>
      )}
    </TableWrapper>
  );
}

const TableWrapper = styled.div`
  width: 100%;
  margin: 2rem 0;
  min-height: 34.375rem;
`;

const EmptyBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 33.875rem;
  white-space: pre-line;
`;
