import { PLACEHOLDER_VALUE } from 'features/common/select/Select';
import { verifyEmail } from 'shared/common/utils';
import { nls } from 'shared/locale/language';
import { ProjectUser } from 'stores/data/types';

export function validateEmail(email: string, tentativeInvitees: ProjectUser[]): string {
  if (!email.trim()) {
    return nls.emailEmptyError();
  }
  if (!verifyEmail(email)) {
    return nls.emailFormatError();
  }
  if (tentativeInvitees.find((x) => (x.email || x.user?.email) === email)) {
    return nls.emailAlreadyInvited();
  }
  return '';
}

export function validateGrade(grade: string): string {
  if (!grade || grade === PLACEHOLDER_VALUE) {
    return nls.gradeEmptyError();
  }
  return '';
}
