import { lazy } from 'react';
const QuickShootingDetailPage = lazy(
  () =>
    import(
      /* webpackChunkName: "QuickShootingDetailPage" */
      'features/quickShooting/detail/components/detailPage/QuickShootingDetailPage'
    ),
);

function QuickShootingDetail() {
  return <QuickShootingDetailPage />;
}

export default QuickShootingDetail;
