import CheckIcon from '@mui/icons-material/Check';
import LinkIcon from '@mui/icons-material/Link';
import InputBase from '@mui/material/InputBase';
import { ContainedButton } from 'features/common/button/ContainedButton';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { nls } from 'shared/locale/language';
import { others } from 'shared/styles/colors/others';
import theme from 'shared/styles/mui/theme';
import styled from 'styled-components';

interface Props {
  text: string;
  placeholder?: string;
}

export default function CopyField({ text, placeholder }: Props) {
  const [copied, setCopied] = useState(false);

  const handleAfterCopied = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <Item>
      <Input value={text} placeholder={placeholder} />
      <CopyToClipboard text={text} onCopy={handleAfterCopied}>
        {copied ? (
          <CopyButton startIcon={<CheckIcon />}>{nls.sharePopoverCopyCompleteButton()}</CopyButton>
        ) : (
          <CopyButton startIcon={<LinkIcon />}>{nls.sharePopoverCopyButton()}</CopyButton>
        )}
      </CopyToClipboard>
    </Item>
  );
}

const Item = styled.div`
  display: flex;
  align-items: stretch;
  margin-top: 1rem;
  height: 2rem;
`;

const Input = styled(InputBase)`
  border: 1px solid ${others.BORDER};
  border-radius: 0.25rem 0 0 0.25rem;
  background-color: ${theme.palette.action.hover};
  color: ${theme.palette.text.secondary};
  flex: 1;
  padding: 0 0.5rem;
`;
const CopyButton = styled(ContainedButton)`
  border-radius: 0 0.25rem 0.25rem 0;
`;
