import React from 'react';
import styled from 'styled-components';
import { ProjectPathNavigationItemType } from '../../types';
import ProjectListActionGroup from './ProjectListActionGroup';
import ProjectPathNavigation from './ProjectPathNavigation';

interface Props {
  hideFolderActions: boolean;
  showCreateButton: boolean;
  mode: 'select' | 'normal';
  rootPathItem: ProjectPathNavigationItemType;
  hiddenFolderList: ProjectPathNavigationItemType[];
  visibleFolderList: ProjectPathNavigationItemType[];
  disabledChangeMode?: boolean;
  disabledMoveButton?: boolean;
  onChangeMode: (mode: 'select' | 'normal') => void;
  onClickMove: () => void;
  onClickNewFolder: () => void;
  onClickNewProject: () => void;
}

export default function ProjectListFolderPathArea({
  hideFolderActions,
  rootPathItem,
  hiddenFolderList,
  visibleFolderList,
  ...rest
}: Props) {
  return (
    <Container>
      <PathNavigation>
        <ProjectPathNavigation
          showOnlyRoot={hideFolderActions}
          rootPathItem={rootPathItem}
          hiddenFolderList={hiddenFolderList}
          visibleFolderList={visibleFolderList}
        />
      </PathNavigation>
      <ProjectListActionGroup {...rest} hideFolderActions={hideFolderActions} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 3rem;
  gap: 0.75rem;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
`;

const PathNavigation = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-right: auto;
`;
