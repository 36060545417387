import { Project } from 'features/general/project/types';
import { useQuery } from 'react-query';
import { DEFAULT_QUERY_STALE_TIME } from 'shared/common/policies/request';
import api from './api';
import { keys } from './queryKeys';

export default function useProjectQuery(projectId: number) {
  return useQuery<Project>(keys.detail(projectId), () => api.read({ projectId }), {
    enabled: !!projectId,
    staleTime: DEFAULT_QUERY_STALE_TIME,
  });
}
