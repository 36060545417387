import Typography from '@mui/material/Typography';
import { memberPermission } from 'shared/common/types';
import { nls } from 'shared/locale/language';
import React from 'react';
import styled from 'styled-components';
import theme from 'shared/styles/mui/theme';

export default function TooltipContent() {
  return (
    <Layout>
      <Header>{nls.participantGradeTooltipHeader()}</Header>
      {Object.values(memberPermission).map((x) => (
        <Row key={x.value}>
          <Grade>{x.title}</Grade>
          <Description>{x.desc}</Description>
        </Row>
      ))}
    </Layout>
  );
}

const Layout = styled.div`
  padding: 2.5rem;
`;

const Header = styled(Typography).attrs({ variant: 'h6' })`
  color: ${theme.palette.text.primary};
  margin-bottom: 2.25rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }
`;

const Grade = styled(Typography).attrs({ variant: 'subtitle2', color: 'textSecondary' })`
  display: inline-block;
  width: 5.5rem;
  min-width: 5.5rem;
`;

const Description = styled(Typography).attrs({ variant: 'body2', color: 'textSecondary' })`
  display: inline-block;
  width: 16.25rem;
`;
