import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import styled from 'styled-components';

export default function LazyFallbackComponent() {
  return (
    <Wrapper>
      <CircularProgress />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
