import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Box, Button, CircularProgress, Tooltip } from '@mui/material';
import { nls } from 'shared/locale/language';
import InvitationModal from '../invitationModal/InvitationModal';
import SettingContentLayout from '../SettingContentLayout';
import ProjectInformationTable from './ProjectInformationTable';
import useProjectListManagementViewModel from './useProjectListManagementViewModel';

export default function ProjectListManagement() {
  const {
    data,
    checkedProjectList,
    loading,

    disabledInviteButton,
    onClickInvite,

    searchKeyword,
    onChangeSearchKeyword,

    headerCheckboxStatus,
    onToggleHeaderCheckbox,

    isCheckedItem,
    onToggleItemCheckbox,

    showInvitationModal,
    onCloseInvitationModal,
    clear,
  } = useProjectListManagementViewModel();

  return (
    <SettingContentLayout
      title={nls.projectInfo()}
      headerActions={
        <ProjectListViewHeaderActions disabled={disabledInviteButton} onClick={onClickInvite} />
      }
    >
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: 'calc(100vh - 16.5rem)',
          }}
        >
          <CircularProgress size={40} />
        </Box>
      ) : (
        <ProjectInformationTable
          data={data}
          searchKeyword={searchKeyword}
          onSearchKeywordChange={onChangeSearchKeyword}
          headerCheckboxStatus={headerCheckboxStatus}
          onToggleHeaderCheckbox={onToggleHeaderCheckbox}
          isCheckedItem={isCheckedItem}
          onToggleItemCheckbox={onToggleItemCheckbox}
        />
      )}
      {showInvitationModal && (
        <InvitationModal
          open={showInvitationModal}
          onClose={onCloseInvitationModal}
          projectList={checkedProjectList}
          clear={clear}
        />
      )}
    </SettingContentLayout>
  );
}

function ProjectListViewHeaderActions({ disabled = false, onClick }) {
  const button = (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<PersonAddIcon />}
      onClick={onClick}
      disabled={disabled}
      sx={{ height: '2.5rem' }}
    >
      {nls.inviteParticipant()}
    </Button>
  );

  if (disabled) {
    return (
      <Tooltip arrow placement="top" title={nls.inviteParticipantButtonTooltipMessage()}>
        <div>{button}</div>
      </Tooltip>
    );
  }

  return button;
}
