import { useAtom } from 'jotai';
import routes from 'pages/routes';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { isGreen } from 'shared/common/customize';
import { nls } from 'shared/locale/language';
import useNotificationMutation from '../../query/useNoticationMutation';
import useProjectManagementQuery from '../../query/useProjectManagementQuery';
import { projectManagementStore } from '../../store/projectManagementStore';
import { ProjectManagementTableItem } from '../../types';

const PERMISSION_TITLE = {
  OWNER: nls.ownerTitle(),
  MANAGER: nls.managerTitle(),
  USER: nls.userTitle(),
  GUEST: nls.guestTitle(),
};

export default function useProjectListManagementViewModel() {
  const history = useHistory();

  const { data: projectList, status } = useProjectManagementQuery();
  const { updateNotificationMutation } = useNotificationMutation();

  const [searchKeyword, setSearchKeyword] = useAtom(projectManagementStore.searchKeyword);
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const [checkedProjectIdList, setCheckedProjectIdList] = useState<number[]>([]);

  const projectManagementTableItemList: ProjectManagementTableItem[] =
    projectList?.map((project) => ({
      id: project.id,
      name: project.name,
      numberOfParticipants: project.userIdList.length,
      numberOfSnapshots: project.snapshotIdList.length,
      permission: PERMISSION_TITLE[project.permissionInfo.permission],
      checkedSubscribedMail: project?.notificationSetting?.find(
        (setting) => setting.type === 'ANALYSIS_COMPLETE',
      )?.isActive,
      onClickProjectName: () => {
        const href = isGreen()
          ? routes.green.shortcut.of({ projectId: project.id, zoneId: project.oldestZone?.id })
          : routes.snapshot.list.of({ projectId: project.id, zoneId: project.oldestZone?.id });
        history.push(href, isGreen() ? 'projectListPage' : null);
      },
      onClickDetail: () => {
        history.push(routes.setting.project.detail.of({ projectId: project.id }));
      },
      onClickParticipant: () => {
        history.push(routes.setting.project.participant.of({ projectId: project.id }));
      },
      onToggleSubscribeMail: () => {
        updateNotificationMutation.mutate({
          type: 'ANALYSIS_COMPLETE',
          projectId: project.id,
          isActive: !project?.notificationSetting?.find(
            (setting) => setting.type === 'ANALYSIS_COMPLETE',
          )?.isActive,
        });
      },
      disabledCheckbox: !canParticipantEdit(project.permissionInfo.permission),
      disabledParticipant: !canShowParticipantList(project.permissionInfo.permission),
      disabledDetail: !canShowProjectDetail(project.permissionInfo.permission),
      disabledSubscribeMail: !canControlSubscribeMail(project.permissionInfo.permission),
      done: project.state,
    })) ?? [];

  const filteredProjectList = projectManagementTableItemList?.filter((x) =>
    Boolean(searchKeyword) ? x.name.toLowerCase().includes(searchKeyword.toLowerCase()) : true,
  );
  const checkedProjectList = projectManagementTableItemList
    ?.filter((x) => isCheckedItem(x.id))
    ?.map((x) => ({
      id: x.id,
      name: x.name,
    }));

  function canParticipantEdit(permission: string) {
    return permission === 'OWNER' || permission === 'MANAGER';
  }
  function canShowProjectDetail(permission: string) {
    return permission === 'OWNER' || permission === 'MANAGER' || permission === 'USER';
  }
  function canControlSubscribeMail(permission: string) {
    return permission === 'OWNER' || permission === 'MANAGER';
  }
  function canShowParticipantList(permission: string) {
    return permission === 'OWNER' || permission === 'MANAGER' || permission === 'USER';
  }

  const headerCheckboxStatus: 'all' | 'some' | 'none' = (() => {
    if (checkedProjectIdList.length === 0) {
      return 'none';
    }
    if (
      checkedProjectIdList.length === filteredProjectList?.filter((x) => !x.disabledCheckbox).length
    ) {
      return 'all';
    }
    return 'some';
  })();

  function onChangeSearchKeyword(keyword: string) {
    setSearchKeyword(keyword);
  }

  function isCheckedItem(id: number) {
    return checkedProjectIdList.includes(id);
  }
  function onToggleItemCheckbox(id: number, checked: boolean) {
    if (checked) {
      setCheckedProjectIdList([...checkedProjectIdList, id]);
    } else {
      setCheckedProjectIdList(checkedProjectIdList.filter((checkedId) => checkedId !== id));
    }
  }
  function onToggleHeaderCheckbox() {
    if (headerCheckboxStatus === 'all') {
      setCheckedProjectIdList([]);
    } else {
      setCheckedProjectIdList(
        filteredProjectList?.filter((x) => !x.disabledCheckbox)?.map((x) => x.id) ?? [],
      );
    }
  }

  function onClickInvite() {
    setShowInvitationModal(true);
  }

  function onCloseInvitationModal() {
    setShowInvitationModal(false);
  }

  function clear() {
    setCheckedProjectIdList([]);
    setSearchKeyword('');
  }

  const loading = status === 'loading';
  const disabledInviteButton = checkedProjectIdList.length === 0;

  return {
    data: filteredProjectList,
    checkedProjectList,
    loading,

    searchKeyword,
    onChangeSearchKeyword,

    disabledInviteButton,
    onClickInvite,

    headerCheckboxStatus,
    isCheckedItem,
    onToggleItemCheckbox,
    onToggleHeaderCheckbox,

    showInvitationModal,
    onCloseInvitationModal,
    clear,
  };
}
