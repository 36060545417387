import { Box, FormHelperText, TextField } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Autocomplete from 'features/common/Autocomplete';
import { LabelRadio } from 'features/common/radio/Radio';
import Select, { PLACEHOLDER_VALUE } from 'features/common/select/Select';
import CustomTextField from 'features/common/textfield/CustomTextField';
import { ChangeEvent, useEffect, useState } from 'react';
import { isJapan, isPosco } from 'shared/common/customize';
import {
  COORDINATE_SYSTEM_MAX_LENGTH,
  PROJECT_DESCRIPTION_MAX_LENGTH,
  PROJECT_NAME_MAX_LENGTH,
} from 'shared/common/policies/limit';
import { DEFAULT_MAP_PROVIDER, mapTypes, mapTypesJp } from 'shared/common/policies/map';
import {
  DEFAULT_LOGOUT_BY_DISTANCE,
  LOGOUT_BY_DISTANCE_MAXIMUM_INPUT_VALUE,
  LOGOUT_BY_DISTANCE_MINIMUM_INPUT_VALUE,
} from 'shared/common/policies/project';
import { CoordinateSystem, datumList, datumListJp } from 'shared/common/types';
import { stringToDate } from 'shared/common/utils/date';
import { nls } from 'shared/locale/language';
import { useListCoordinatesQuery } from 'shared/query';
import { MapProvider } from 'shared/query/project/types';
import palette from 'shared/styles/mui/palette';
import theme from 'shared/styles/mui/theme';
import { Project } from 'stores/data/types';
import styled from 'styled-components';
import FormUnitMeasurement from './FormUnitMeasurement';
import { filterCoordSystemText } from './filters';
import { validateCoordSystemOption, validateCoordSystemText, validateTitle } from './validators';
import { scrollStyle } from 'shared/styles/colors/scrollbar';

interface Props {
  existing: Project;
  validating: boolean;
  onFormUpdate: () => void;
  onValidated: (result: Result) => void;
}

export type Result = {
  title: string;
  description: string;
  coordinateSystem: string;
  startDate: Date;
  endDate: Date;
  complete: boolean;
  mapProvider: MapProvider;
  volumeUnitId: number;
  logoutByDistance: number;
};

const YEAR = 1000 * 60 * 60 * 24 * 365;
const STATUS_IN_PROGRESS = 'in_progress';
const STATUS_COMPLETE = 'complete';

export default function Form({ existing, validating, onFormUpdate, onValidated }: Props) {
  const defaultCoordSystemOption = PLACEHOLDER_VALUE;
  const defaultStartDate = new Date();
  const defaultEndDate = new Date(new Date().valueOf() + YEAR);
  const defaultStatus = STATUS_IN_PROGRESS;
  const coordSystemOptions = isJapan() ? datumListJp : datumList;
  const defaultLaborerLogoutByDistance = DEFAULT_LOGOUT_BY_DISTANCE;
  [defaultStartDate, defaultEndDate].forEach((x) => normalizeDate(x));
  const localizedmapTypes = isJapan() ? mapTypesJp : mapTypes;

  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const [description, setDescription] = useState('');
  const [coordSystemOption, setCoordSystemOption] = useState(defaultCoordSystemOption);
  const [coordSystemOptionError, setCoordSystemOptionError] = useState('');
  const [coordSystemText, setCoordSystemText] = useState('');
  const [coordSystemTextError, setCoordSystemTextError] = useState('');
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [status, setStatus] = useState(defaultStatus);
  const [mapProvider, setMapProvider] = useState<MapProvider>(DEFAULT_MAP_PROVIDER);
  const [volumeUnitId, setVolumeUnitId] = useState(3);
  const [coordSystemCandidates, setCoordSystemCandidates] = useState({ query: '', data: [] });
  const [laborerLogoutByDistance, setLaborerLogoutByDistance] = useState(
    defaultLaborerLogoutByDistance,
  );
  useListCoordinatesQuery(
    { search: coordSystemText },
    {
      onSuccess: (data) => {
        setCoordSystemCandidates({
          data,
          query: coordSystemText,
        });
      },
    },
  );

  const coordSystemDirectInputIsActive = coordSystemOption === CoordinateSystem.etc;

  useEffect(() => {
    setTitle(existing.name || '');
    setDescription(existing.description || '');
    existing.constructionDate && setStartDate(stringToDate(existing.constructionDate));
    existing.completedDate && setEndDate(stringToDate(existing.completedDate));
    if (existing.coordinate) {
      const usingOption = coordSystemOptions.find(({ value }) => existing.coordinate === value);
      if (usingOption) {
        setCoordSystemOption(existing.coordinate);
      } else {
        setCoordSystemOption(CoordinateSystem.etc);
        setCoordSystemText(existing.coordinate);
      }
    }
    setStatus(existing.state ? STATUS_COMPLETE : STATUS_IN_PROGRESS);
    setMapProvider(existing?.mapProvider || DEFAULT_MAP_PROVIDER);
    setVolumeUnitId(existing?.volumeUnit?.id || 3);
    setLaborerLogoutByDistance(existing?.data?.logoutByDistance || defaultLaborerLogoutByDistance);
  }, [existing]);

  function createInputChangeEventHandler(f) {
    return (e: ChangeEvent<HTMLInputElement>) => {
      onFormUpdate();
      f(e.target.value);
    };
  }
  function createInputChangeDistanceLogoutEventHandler(f) {
    return (e: ChangeEvent<HTMLInputElement>) => {
      onFormUpdate();
      const value = Number(e.target.value);
      if (value <= LOGOUT_BY_DISTANCE_MINIMUM_INPUT_VALUE) {
        f(LOGOUT_BY_DISTANCE_MINIMUM_INPUT_VALUE);
      } else if (value >= LOGOUT_BY_DISTANCE_MAXIMUM_INPUT_VALUE) {
        f(LOGOUT_BY_DISTANCE_MAXIMUM_INPUT_VALUE);
      } else {
        f(value);
      }
    };
  }
  const updateTitle = createInputChangeEventHandler((x) => {
    setTitle(x);
    setTitleError('');
  });
  const updateDescription = createInputChangeEventHandler(setDescription);
  const updateCoordSystemOption = createInputChangeEventHandler(setCoordSystemOption);
  const updateCoordSystemText = createInputChangeEventHandler(setCoordSystemText);
  const updateStatus = createInputChangeEventHandler(setStatus);
  const updateLaborerLogoutDistance = createInputChangeDistanceLogoutEventHandler(
    setLaborerLogoutByDistance,
  );
  const updateStartDate = (x: Date) => {
    onFormUpdate();
    setStartDate(x);
  };
  const updateEndDate = (x: Date) => {
    onFormUpdate();
    setEndDate(x);
  };
  const updateMapProvider = createInputChangeEventHandler(setMapProvider);
  const updateVolumeUnitId = (value) => {
    onFormUpdate();
    setVolumeUnitId(value);
  };

  useEffect(() => {
    if (!validating) {
      return;
    }
    let ok = true;

    const titleErrorMessage = validateTitle(title);
    setTitleError(titleErrorMessage);
    ok = ok && !titleErrorMessage;

    const coordSystemOptionErrorMessage = validateCoordSystemOption(coordSystemOption);
    setCoordSystemOptionError(coordSystemOptionErrorMessage);
    ok = ok && !coordSystemOptionErrorMessage;

    // 제목 등 다른 필드는 입력 값 변경 시 오류가 초기화되지만
    // 좌표계 직접 입력은 입력에 따라 오류가 발생할 수 있음 ("지원하지 않는 좌표계")
    // 따라서 validate 함수로 입력 값을 점검함과 동시에 || 연산으로 이미 발생해 있는 오류를 점검함
    const coordSystemTextErrorMessage =
      validateCoordSystemText(coordSystemText) || coordSystemTextError;
    setCoordSystemTextError(coordSystemTextErrorMessage);
    ok = ok && !(coordSystemDirectInputIsActive && coordSystemTextErrorMessage);

    if (!ok) {
      return;
    }
    onValidated({
      title,
      description,
      coordinateSystem: getCoordSystemValue(),
      startDate,
      endDate,
      complete: status === STATUS_COMPLETE,
      mapProvider,
      volumeUnitId,
      logoutByDistance: laborerLogoutByDistance,
    });
  }, [validating]);

  useEffect(() => {
    setCoordSystemOptionError('');
  }, [coordSystemOption]);

  useEffect(() => {
    setCoordSystemTextError('');
    const filtered = filterCoordSystemText(coordSystemText);
    setCoordSystemText(filtered);
  }, [coordSystemText]);

  const coordSystemTextInput = coordSystemDirectInputIsActive && (
    <AlignedAsyncAutocomplete
      options={coordSystemCandidates.data}
      optionsUpToDate={coordSystemText && coordSystemCandidates.query === coordSystemText}
      getOptionLabel={({ code }) => `EPSG ${code}`}
      onTextChange={updateCoordSystemText}
      onChange={(_, { code }) => setCoordSystemText(code)}
      onFocus={() => setCoordSystemText(coordSystemText)}
      value={coordSystemText}
      placeholder={nls.projectFormCoordTextHint()}
      error={coordSystemTextError}
      onNoMatchError={() => setCoordSystemTextError(nls.projectFormCoordTextNoMatches())}
      maxLength={COORDINATE_SYSTEM_MAX_LENGTH}
      prefix={coordSystemText ? 'EPSG ' : ''}
    />
  );
  function getCoordSystemValue() {
    return coordSystemDirectInputIsActive ? coordSystemText : coordSystemOption;
  }

  return (
    <StyledForm>
      <Subheading>{nls.projectFormSubheadingInfo()}</Subheading>
      <InputRow required label={nls.projectFieldName()}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <TextField
            variant="outlined"
            value={title}
            onChange={updateTitle}
            error={Boolean(titleError)}
            placeholder={nls.projectFormTitleHint()}
            fullWidth
            sx={{ maxHeight: '3.5rem' }}
            slotProps={{
              htmlInput: { maxLength: PROJECT_NAME_MAX_LENGTH },
            }}
          />
          {Boolean(titleError) && <FormHelperText error>{titleError}</FormHelperText>}
        </Box>
      </InputRow>
      <InputRow label={nls.projectFieldDescription()}>
        <TextField
          variant="outlined"
          value={description}
          onChange={updateDescription}
          placeholder={nls.projectFormDescriptionHint()}
          fullWidth
          sx={{ maxHeight: '3.5rem' }}
          slotProps={{
            htmlInput: { maxLength: PROJECT_DESCRIPTION_MAX_LENGTH },
          }}
        />
      </InputRow>
      <InputRow required label={nls.projectFieldPeriod()}>
        <DatePicker
          label={nls.basicStartDate()}
          value={startDate}
          maxDate={endDate}
          onChange={updateStartDate}
          format="yyyy-MM-dd"
          slotProps={{
            textField: {
              inputProps: {
                readOnly: true,
              },
              onClick: (e) => {
                (e.currentTarget.querySelector('.MuiInputAdornment-root button') as any)?.click();
              },
              sx: {
                cursor: 'pointer',
                '.MuiInputBase-input': {
                  cursor: 'pointer',
                },
                '.MuiOutlinedInput-root': {
                  pointerEvents: 'none',
                },
                '.MuiInputAdornment-root': {
                  pointerEvents: 'all',
                },
              },
            },
          }}
        />
        <InlineDivider />
        <DatePicker
          label={nls.basicEndDate()}
          value={endDate}
          minDate={startDate}
          onChange={updateEndDate}
          format="yyyy-MM-dd"
          slotProps={{
            textField: {
              inputProps: {
                readOnly: true,
              },
              onClick: (e) => {
                (e.currentTarget.querySelector('.MuiInputAdornment-root button') as any)?.click();
              },
              sx: {
                cursor: 'pointer',
                '.MuiInputBase-input': {
                  cursor: 'pointer',
                },
                '.MuiOutlinedInput-root': {
                  pointerEvents: 'none',
                },
                '.MuiInputAdornment-root': {
                  pointerEvents: 'all',
                },
              },
            },
          }}
        />
      </InputRow>
      <InputRow required label={nls.projectFieldStatus()}>
        <Select
          onChange={updateStatus}
          value={status}
          options={[
            { value: STATUS_IN_PROGRESS, name: nls.projectStatusInProgress() },
            { value: STATUS_COMPLETE, name: nls.projectStatusCompleted() },
          ]}
          sx={{ minWidth: '17.5rem', height: '3.5rem' }}
        />
      </InputRow>
      <Subheading>{nls.projectFormSubheadingInfoDetail()}</Subheading>
      <InputRow required label={nls.projectFieldCoord()}>
        <Select
          value={coordSystemOption}
          onChange={updateCoordSystemOption}
          itemsPerPage={6}
          placeholder={nls.projectFormCoordSelectHint()}
          options={coordSystemOptions.map(({ desc, value }) => ({
            name: desc as string,
            value,
          }))}
          error={coordSystemOptionError}
          sx={{
            minWidth: '17.5rem',
            height: '3.5rem',
            '.MuiSelect-select': {
              color:
                coordSystemOption === defaultCoordSystemOption ? palette.text.disabled : undefined,
            },
          }}
        />
        {coordSystemTextInput}
      </InputRow>
      <InputRow required label={nls.projectMapProvider()}>
        <RadioGroup row onChange={updateMapProvider}>
          {localizedmapTypes.map(({ label, value }) => (
            <LabelRadio
              label={label}
              value={value}
              checked={mapProvider === value}
              key={value}
              fullWidth={false}
            />
          ))}
        </RadioGroup>
      </InputRow>
      <FormUnitMeasurement
        projectId={existing?.id}
        existingVolumeUnitId={existing?.volumeUnit?.id}
        updateVolumeUnitId={updateVolumeUnitId}
      />
      {!isPosco() && (
        <>
          <Subheading>{nls.projectFormSubheadingLaborerLogoutDistance()}</Subheading>
          <InputRow required label={nls.projectFormDistance()}>
            <FixedWidthCustomTextFieldWrapper>
              <CustomTextField
                value={laborerLogoutByDistance}
                onChange={updateLaborerLogoutDistance}
                type="number"
              />
              <DistanceUnit>m</DistanceUnit>
            </FixedWidthCustomTextFieldWrapper>
          </InputRow>
        </>
      )}
    </StyledForm>
  );
}

function normalizeDate(date: Date) {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
}

function InputRow({ label, children, required = false }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: '22.5rem',
        marginBottom: '3rem',
      }}
    >
      <Typography
        variant="subtitle1"
        color="textSecondary"
        sx={{
          position: 'relative',
          width: '5rem',
          minWidth: '5rem',
          marginRight: '1rem',
          '&:lang(en)': {
            width: '5.5rem',
          },
          '&::after': {
            position: 'absolute',
            content: required ? '"*"' : '""',
            color: palette.error.main,
            marginLeft: '0.25rem',
          },
        }}
      >
        {label}
      </Typography>
      {children}
    </Box>
  );
}

const StyledForm = styled.form`
  display: block;
  max-height: calc(100% - 9rem - 4rem); // 100% - 위 아래 여백
  min-height: 15rem;
  width: 100%;
  overflow: auto;
  ${scrollStyle}
`;

const InputRowWrapper = styled.div<{ $flexStart: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: ${({ $flexStart }) => ($flexStart ? 'flex-start' : 'center')};
  min-width: 22.5rem;
  margin-bottom: 3rem;
`;
const Subheading = styled(Typography).attrs({ variant: 'h6' })`
  display: block;
  margin-top: 3rem;
  margin-bottom: 2rem;

  ${InputRowWrapper} + & {
    margin-top: 4rem;
  }
`;

const AlignedAsyncAutocomplete = styled(Autocomplete)`
  width: 17.5rem;
`;

const FixedWidthCustomTextFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 17.5rem;
`;

const DistanceUnit = styled(Typography).attrs((props) => ({
  variant: 'subtitle1',
  color: 'textSecondary',
  component: 'span',
  ...props,
}))`
  margin-left: 1rem;
`;

const InlineDivider = styled.div`
  width: 1.5rem;
  height: 2px;
  margin: 0.75rem;
  background-color: ${theme.palette.divider};
`;
