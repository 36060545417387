import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import theme from 'shared/styles/mui/theme';
import styled, { css } from 'styled-components';
import { buttonTextDefault, buttonTextPrimary } from '../style/TextStyles';

const getSize = (size) =>
  (size === 'small' && '1.125rem') || (size === 'large' && '1.5rem') || '1.25rem';

/**
 * 버튼 여백(padding) 고정 : 기본값
 */

export type ContainedButtonProps = ButtonProps & { $loading?: boolean; warning?: boolean };
export const ContainedButton = styled(Button).attrs<ContainedButtonProps>((props) => ({
  type: 'button',
  variant: 'contained',
  color: props?.color || 'primary',
  startIcon:
    (props?.$loading && <CircularProgress size={getSize(props?.size)} />) || props.startIcon,
  disabled: props?.disabled || props?.$loading,
  warning: props?.warning,
}))<ContainedButtonProps>`
  line-height: 1.1;
  ${({ warning }) =>
    warning &&
    css`
      background-color: ${theme.palette.error.main};
      color: ${theme.palette.common.white};
      &:hover {
        background-color: ${theme.palette.error.dark};
      }
    `}
  svg {
    color: currentColor;
  }

  ${({ $loading, color }) =>
    $loading &&
    color === 'primary' &&
    css`
      &.Mui-disabled {
        background-color: ${theme.palette.primary.main};
        color: ${theme.palette.primary.contrastText};
        opacity: 0.3;
      }
      .MuiCircularProgress-root {
        color: ${theme.palette.primary.contrastText};
      }
    `}
`;

/**
 * 버튼 크기 고정
 */
export const FixedSizedContainedButton = styled(ContainedButton).attrs({
  type: 'button',
  variant: 'contained',
})`
  height: 3.5rem;
  width: 12.5rem;
`;

export const ButtonDefault = styled(FixedSizedContainedButton).attrs({ color: 'inherit' })`
  ${buttonTextDefault};
`;

export const ButtonPrimary = styled(FixedSizedContainedButton).attrs({
  color: 'primary',
  size: 'large',
})`
  ${buttonTextPrimary};
`;
