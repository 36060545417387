import palette from 'shared/styles/mui/palette';

const MuiTooltip = {
  styleOverrides: {
    tooltip: {
      backgroundColor: palette.grey[900],
      color: palette.common.white,
      fontSize: '0.875rem',
    },
    arrow: {
      color: palette.grey[900],
    },
  },
};

export default MuiTooltip;
