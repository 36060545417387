import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Dialog, SvgIcon, SvgIconProps, Toolbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import SatelliteLayer from 'features/common/2D/layers/satellite/SatelliteLayer';
import Map from 'features/common/2D/Map';
import TextButton from 'features/common/button/TextButton';
import L from 'leaflet';
import { Rectangle } from 'react-leaflet';
import { CONSTRUCTION_SITE_AREA_COLOR } from 'shared/common/colorScheme';
import { nls } from 'shared/locale/language';
import palette from 'shared/styles/mui/palette';
import styled from 'styled-components';
import MapEventHandlers from './MapEventHandlers';
import OrthophotoLayer from './OrthophotoLayer';
import useModalData from './useModalData';

interface Props {
  open: boolean;
  onClose: () => void;
}

function FieldBoundaryDialog({ open, onClose }: Props) {
  const { zoneData, hasBoundingBoxData, boundingBox } = useModalData();

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <AppBar>
        <FieldBoundaryDialogToolbar>
          <AppbarText variant="subtitle1">{nls.projectFieldBoundaryDialogButton()}</AppbarText>
          <CloseButton startIcon={<WhiteCloseIcon />} onClick={onClose}>
            <AppbarText>{nls.close()}</AppbarText>
          </CloseButton>
        </FieldBoundaryDialogToolbar>
      </AppBar>

      <Map>
        <MapEventHandlers />
        <SatelliteLayer />
        {zoneData?.map((zone, index) => (
          <OrthophotoLayer key={`${JSON.stringify(zone.id)}-zone-${index}`} zoneData={zone} />
        ))}
        {hasBoundingBoxData &&
          boundingBox.map((box, index) => {
            if (box.length === 0) return null;
            return (
              <Rectangle
                key={`${JSON.stringify(box)}-bounding-box-${index}`}
                bounds={box}
                color={CONSTRUCTION_SITE_AREA_COLOR}
                weight={3}
                renderer={L.canvas()}
              />
            );
          })}
      </Map>
    </Dialog>
  );
}

export default FieldBoundaryDialog;

const AppbarText = styled(Typography)`
  color: ${palette.primary.contrastText};
`;
const WhiteCloseIcon = styled(SvgIcon).attrs<SvgIconProps>({ component: CloseIcon })`
  color: ${palette.primary.contrastText};
`;
const CloseButton = styled(TextButton)`
  position: absolute;
  right: 1rem;
`;
const FieldBoundaryDialogToolbar = styled(Toolbar)`
  height: 4rem;
  min-height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${palette.grey[900]};
`;
