import Box from '@mui/material/Box';
import Pagination, { PaginationProps } from '@material-ui/lab/Pagination';
import React from 'react';
import styled from 'styled-components';

type Props = PaginationProps;

export default function BasicPagination({ count, page, onChange, ...rest }: Props) {
  return (
    <Box display="flex" justifyContent="center">
      <StyledPagination count={count} page={page} onChange={onChange} {...rest} />
    </Box>
  );
}

const StyledPagination = styled(Pagination)`
  button:focus {
    outline: none;
  }
`;
