import { IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { nls } from 'shared/locale/language';
import React from 'react';
import styled from 'styled-components';
import {
  DialogActions,
  DialogButton,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogTitleText,
  DialogWrapper,
} from './common';
import { DialogProps } from './types';

export default function Modal({
  title,
  content,
  primaryButtonProps,
  secondaryButtonProps,
  children,
  ...rest
}: Omit<DialogProps, 'type'>) {
  const {
    title: primaryButtonTitle,
    color: primaryButtonColor,
    ...restPrimaryButtonProps
  } = primaryButtonProps || {};
  const {
    title: secondaryButtonTitle,
    color: secondaryButtonColor,
    ...restSecondaryButtonProps
  } = secondaryButtonProps || {};
  return (
    <DialogWrapper {...rest}>
      <DialogTitle>
        <DialogTitleText>{title}</DialogTitleText>
        <CloseIconButton onClick={rest.onClose}>
          <Close />
        </CloseIconButton>
      </DialogTitle>
      <DialogContent>
        {content && <DialogContentText>{content}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions>
        <DialogButton color={secondaryButtonColor || 'inherit'} {...restSecondaryButtonProps}>
          {secondaryButtonTitle || nls.cancel()}
        </DialogButton>
        <DialogButton color={primaryButtonColor || 'primary'} {...restPrimaryButtonProps}>
          {primaryButtonTitle || nls.confirm()}
        </DialogButton>
      </DialogActions>
    </DialogWrapper>
  );
}

const CloseIconButton = styled(IconButton)`
  position: absolute;
  right: 1rem;
  top: 0;

  &:focus {
    outline: none;
  }
`;
