import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, Tooltip, Typography } from '@mui/material';
import useGlobalDialog from 'features/common/hooks/useGlobalDialog';
import WidePopup from 'features/common/modal/WidePopup';
import { useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { memberPermission, PermissionType } from 'shared/common/types';
import { nls } from 'shared/locale/language';
import { others } from 'shared/styles/colors/others';
import theme from 'shared/styles/mui/theme';
import { globalSnackbarAtom } from 'stores/v3/snapshotDetail/globalSnackbar';
import styled from 'styled-components';
import useParticipantMutation from '../../query/useParticipantMutation';
import Footer from './Footer';
import Inputs from './Inputs';
import InviteeQueue from './InviteeQueue';
import { validateEmail, validateGrade } from './validators';

interface Props {
  projectList: { id: number; name: string }[];
  open: boolean;
  onClose: () => void;
  clear?: () => void;
}

const gradesToDisplay = [memberPermission.MANAGER, memberPermission.USER, memberPermission.GUEST];

export default function InvitationModal({ projectList, open, onClose, clear: onClear }: Props) {
  const { showConfirm } = useGlobalDialog();
  const setGlobalSnackbar = useSetAtom(globalSnackbarAtom);

  const { createParticiantMutation } = useParticipantMutation();

  const [values, setValues] = useState({ email: '', grade: '' });
  const [errors, setErrors] = useState({ email: '', grade: '' });
  const [tempInvitees, setTempInvitees] = useState<typeof values[]>([]);
  const [dirty, setDirty] = useState(false);
  const { status } = createParticiantMutation;

  useEffect(() => {
    !open && clear();
  }, [open]);

  useEffect(() => {
    const anyInputTyped = Object.values(values).some((x) => x && x !== '');
    const anyInviteeAdded = tempInvitees.length > 0;
    setDirty(anyInputTyped || anyInviteeAdded);
  }, [values, tempInvitees]);

  function onValueChange(key: keyof typeof values, value: string) {
    setValues((prev) => ({
      ...prev,
      [key]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [key]: '',
    }));
  }

  function onAddClick() {
    const newErrors = {
      email: validateEmail(values.email, tempInvitees),
      grade: validateGrade(values.grade),
    };
    if (newErrors.email || newErrors.grade) {
      setErrors(newErrors);
      return;
    }
    setTempInvitees((prev) => [...prev, values]);
    setValues({ email: '', grade: '' });
  }

  function onGradeChange(email: string, grade: string) {
    setTempInvitees((prev) => prev.map((x) => (x.email === email ? { ...x, grade } : x)));
  }
  function onDeleteClick(email: string) {
    setTempInvitees((prev) => prev.filter((x) => x.email !== email));
  }

  function onInviteClick() {
    createParticiantMutation.mutate(
      {
        projectIdList: projectList.map((x) => x.id),
        projectUserList: tempInvitees.map((x) => ({
          email: x.email,
          permission: x.grade as PermissionType,
        })),
      },
      {
        onSuccess: () => {
          clear();
          onClear?.();
          setGlobalSnackbar((prev) => ({
            ...prev,
            open: true,
            message: nls.invitationSuccessSnackbarMessage(),
            type: 'success',
          }));
          onClose();
        },
      },
    );
  }

  function onCloseClick() {
    if (dirty) {
      showConfirm({
        title: nls.titleExitWithoutSaving(),
        content: nls.exitWithoutSaving(),
        primaryButtonProps: {
          title: nls.confirm(),
          onClick: onClose,
        },
      });
      return;
    }
    onClose();
  }

  function clear() {
    setValues({ email: '', grade: '' });
    setErrors({ email: '', grade: '' });
    setTempInvitees([]);
    setDirty(false);
  }

  const disableSend = tempInvitees.length === 0;
  const disableButtons = status === 'loading';

  return (
    <WidePopup
      isShowing={open}
      closeWidePopup={onCloseClick}
      disableClose={disableButtons}
      title={nls.inviteParticipant()}
    >
      <Content>
        <Section>
          <Typography variant="subtitle2">{nls.targetProjectInvitationModalSubtitle()}</Typography>
          <ProjectNameListBox>
            {projectList?.map((x) => (
              <span key={x.id}>{x.name}</span>
            ))}
          </ProjectNameListBox>
        </Section>
        <Section>
          <Box display="flex" gap="0.25rem" alignItems="center">
            <Typography variant="subtitle2">{nls.targetUserInvitationModalSubtitle()}</Typography>
            <HelpTooltip />
          </Box>
          <Inputs
            gradeOptions={gradesToDisplay}
            values={values}
            errors={errors}
            onValueChange={onValueChange}
            onAddClick={onAddClick}
            disableAdd={disableButtons}
          />
          <InviteeQueue
            data={tempInvitees}
            onGradeChange={onGradeChange}
            onDeleteClick={onDeleteClick}
            gradeOptions={gradesToDisplay}
            loading={status === 'loading'}
          />
        </Section>
      </Content>
      <Footer
        status={status}
        onInviteClick={onInviteClick}
        disableSend={disableSend}
        loading={disableButtons}
      />
    </WidePopup>
  );
}

function HelpTooltip() {
  return (
    <Tooltip
      arrow
      placement="bottom"
      componentsProps={{
        tooltip: {
          sx: {
            width: '27.5rem',
            maxWidth: '27.5rem',
            backgroundColor: theme.palette.background.paper,
            boxShadow:
              '0px 11px 15px -7px rgba(0, 0, 0, 0.20), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
            borderRadius: '1rem',
            padding: '2.5rem',
          },
        },
        arrow: {
          sx: {
            color: theme.palette.background.paper,
          },
        },
      }}
      title={
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h6">{nls.participantGradeTooltipHeader()}</Typography>
          <TooltipGrid>
            <Typography color="inherit" variant="subtitle2">
              {nls.ownerTitle()}
            </Typography>
            <Typography color="inherit" variant="body2">
              {nls.ownerDesc()}
            </Typography>
            <Typography color="inherit" variant="subtitle2">
              {nls.managerTitle()}
            </Typography>
            <Typography color="inherit" variant="body2">
              {nls.managerDesc()}
            </Typography>
            <Typography color="inherit" variant="subtitle2">
              {nls.userTitle()}
            </Typography>
            <Typography color="inherit" variant="body2">
              {nls.userDesc()}
            </Typography>
            <Typography color="inherit" variant="subtitle2">
              {nls.guestTitle()}
            </Typography>
            <Typography color="inherit" variant="body2">
              {nls.guestDesc()}
            </Typography>
          </TooltipGrid>
        </Box>
      }
    >
      <HelpOutlineIcon className="help-icon" fontSize="small" />
    </Tooltip>
  );
}

const TooltipGrid = styled.div`
  display: grid;
  grid-template-columns: 5rem 1fr;
  gap: 1.375rem 0.5rem;
  margin-top: 2.25rem;
  color: ${theme.palette.text.secondary};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem 4rem;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  .help-icon {
    color: ${theme.palette.text.secondary};
    width: 1rem;
    height: 1rem;
  }
`;

const ProjectNameListBox = styled.div`
  display: flex;
  padding: 0.5rem 0.75rem;
  align-items: flex-start;
  align-content: flex-start;
  border-radius: 0.25rem;
  border: 1px solid ${others.BORDER};
  gap: 0.5rem;
  max-width: 100%;
  width: 100%;
  flex-wrap: wrap;
  max-height: 5.25rem;
  overflow-y: auto;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 0.5rem;
    height: 1.875rem;
    border-radius: 1rem;
    background: #ebebec;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 0.975rem */
  }
`;
