import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Typography } from '@mui/material';
import React from 'react';
import theme from 'shared/styles/mui/theme';

interface Props {
  title: string;
  onClickTitle?: () => void;
  secondaryTitle?: string;
  headerActions?: React.ReactNode;
  children?: React.ReactNode;
}

export default function SettingContentLayout({
  title,
  onClickTitle = () => {},
  secondaryTitle,
  headerActions,
  children,
}: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        padding: '3rem 0',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '62.5rem',
          gap: '2.5rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '2.5rem',
            gap: '0.75rem',
          }}
        >
          <Typography
            key="title"
            variant="h5"
            onClick={onClickTitle}
            noWrap
            sx={{
              minWidth: 'fit-content',
              cursor: secondaryTitle ? 'pointer' : 'default',
              color: secondaryTitle ? theme.palette.text.secondary : theme.palette.text.primary,
              '&:hover': {
                textDecoration: secondaryTitle ? 'underline' : 'none',
              },
            }}
          >
            {title}
          </Typography>
          {secondaryTitle && <ChevronRightIcon sx={{ color: theme.palette.text.secondary }} />}
          {secondaryTitle && <Typography variant="h5">{secondaryTitle}</Typography>}
          {headerActions && (
            <Box sx={{ marginLeft: 'auto', minWidth: 'fit-content' }}>{headerActions}</Box>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            height: 'calc(100vh - 12rem)',
            overflow: 'auto',
            paddingBottom: '1rem',
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}
