import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { nls } from 'shared/locale/language';
import { hexToRgba } from 'shared/styles/colors/utils';
import palette from 'shared/styles/mui/palette';
import theme from 'shared/styles/mui/theme';
import styled from 'styled-components';

interface Props {
  onBack: () => void;
  centerMessage?: string;
  children?: React.ReactNode;
}

export default function TopBar({ onBack, centerMessage, children }: Props) {
  return (
    <Container>
      <Button
        variant="text"
        onClick={onBack}
        size="large"
        startIcon={<CloseIcon />}
        sx={{ color: palette.common.white }}
      >
        {nls.close()}
      </Button>
      <TopBarMessage>{centerMessage || ''}</TopBarMessage>
      <RightButtonGroup>{children}</RightButtonGroup>
    </Container>
  );
}

const Container = styled.header`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  background-color: ${hexToRgba(theme.palette.grey[900], 0.9)};
  width: 100%;
  height: 4rem;
  padding-left: 0.625rem;
  padding-right: 1.5rem;
  border-bottom: 1px solid ${theme.palette.grey[800]};
  z-index: ${theme.zIndex.appBar};
`;

const TopBarMessage = styled(Typography).attrs({
  variant: 'subtitle1',
})`
  color: ${theme.palette.primary.contrastText};
`;

const RightButtonGroup = styled.div`
  display: flex;
  align-items: center;
`;
