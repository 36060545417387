import TextField from '@material-ui/core/TextField';
import { Box, Button, FormHelperText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { memberPermission } from 'shared/common/types';
import { nls } from 'shared/locale/language';
import { others } from 'shared/styles/colors/others';
import { hexToRgba } from 'shared/styles/colors/utils';
import theme from 'shared/styles/mui/theme';
import styled from 'styled-components';

interface Props {
  gradeOptions: typeof memberPermission.OWNER[];
  values: {
    email: string;
    grade: string;
  };
  errors: {
    email: string;
    grade: string;
  };
  onValueChange: (key: 'email' | 'grade', value: string) => void;
  onAddClick: () => void;
  disableAdd: boolean;
}

/** @todo
 * 권한 선택 시, 무한 루프가 발생하는 문제가 있음.
 * 동작에는 이상이 없으나 수정이 필요함.
 */
export default function Inputs({
  gradeOptions,
  values,
  errors,
  onAddClick,
  onValueChange,
  disableAdd,
}: Props) {
  const handleGradeChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    onValueChange('grade', value);
  };

  return (
    <InputContainer>
      <StyledTextField
        error={Boolean(errors.email)}
        helperText={errors.email}
        value={values.email}
        onChange={(e) => onValueChange('email', e.target.value.toLowerCase())}
        placeholder={nls.invitationEmailInputPlaceholderMessage()}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Select
          placeholder={nls.grade()}
          value={values.grade}
          onChange={handleGradeChange}
          sx={{
            height: '2.5rem',
            borderColor: errors.grade ? theme.palette.error.main : undefined,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: errors.grade ? theme.palette.error.main : undefined,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: errors.grade ? theme.palette.error.main : undefined,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: errors.grade ? theme.palette.error.main : undefined,
            },
            color: !values.grade ? theme.palette.text.secondary : undefined,
          }}
          displayEmpty
        >
          <MenuItem value="" disabled sx={{ display: 'none' }}>
            {nls.invitationPermissionSelectPlaceholderMessage()}
          </MenuItem>
          {gradeOptions.map((x) => (
            <MenuItem key={x.value} value={x.value}>
              {x.title}
            </MenuItem>
          ))}
        </Select>
        {errors.grade && <FormHelperText error>{errors.grade}</FormHelperText>}
      </Box>
      <Button
        variant="text"
        onClick={onAddClick}
        disabled={disableAdd}
        sx={{
          height: '2.5rem',
          backgroundColor: hexToRgba(theme.palette.primary.main, 0.08),
        }}
      >
        {nls.add()}
      </Button>
    </InputContainer>
  );
}

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 15rem 6rem;
  align-items: flex-start;
  gap: 0.5rem;
`;

const StyledTextField = styled(TextField).attrs({ variant: 'outlined', size: 'small' })`
  flex: 1;

  label  {
    color: ${theme.palette.text.secondary};
  }

  & .MuiOutlinedInput-root:not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline {
    border-color: ${others.BORDER};
  }

  .MuiOutlinedInput-input {
    padding: 1.15625rem 0.875rem;
    padding-top: 0.65625rem;
    padding-bottom: 0.65625rem;
  }
`;
