const PRIMARY = '#1D2125';
const SECONDARY = '#777A7C';
const DISABLED = '#BBBCBE';
/** @deprecated
 * src/shared/styles/muiV6/colors.ts 또는 Mui theme의 palette 값으로 대체해주세요.
 */
export const text = {
  PRIMARY,
  SECONDARY,
  DISABLED,
};
