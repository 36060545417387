import useGlobalDialog from 'features/common/hooks/useGlobalDialog';
import pickBy from 'lodash/pickBy';
import { useEffect, useState } from 'react';
import {
  COMPANY_MIN_LENGTH,
  FULL_NAME_MIN_LENGTH,
  isValidPassword,
} from 'shared/common/policies/limit';
import { nls } from 'shared/locale/language';
import { useUserInfoQuery, useUserMutation } from 'shared/query/user/useUserQuery';
import { initialUserInfo } from 'stores/data/initialState';
import { UserInfo } from 'stores/data/types';

const initialErrors = {
  fullName: '',
  password: '',
  newPassword: '',
  confirmPassword: '',
  company: '',
};

export default function useMyInformationManagement() {
  const { showConfirm } = useGlobalDialog();
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [editingData, setEditingData] = useState(null);

  const [dirty, setDirty] = useState(false);
  const [passwordDialogShowing, showPasswordDialog] = useState(false);
  const [errors, setErrors] = useState(initialErrors);

  const { data } = useUserInfoQuery();
  const { updateInfoMutation } = useUserMutation();

  useEffect(() => {
    setEditingData({
      ...(data || initialUserInfo),
      password: '',
      newPassword: '',
      confirmPassword: '',
    });
  }, [data]);

  function onClickEditButton() {
    setMode('edit');
  }
  function onClickCancelButton() {
    if (dirty) {
      showConfirm({
        title: nls.titleExitWithoutSaving(),
        content: nls.exitWithoutSaving(),
        primaryButtonProps: {
          onClick: _cancel,
        },
      });
    } else {
      _cancel();
    }
  }

  function onClickSaveButton() {
    if (!dirty) return;
    if (!_isValid(editingData)) return;

    showPasswordDialog(true);
  }

  function _cancel() {
    setDirty(false);
    setMode('view');
    setEditingData({
      ...(data || initialUserInfo),
      password: '',
      newPassword: '',
      confirmPassword: '',
    });
    setErrors(initialErrors);
  }

  function onChangeName(e: React.ChangeEvent<HTMLInputElement>) {
    e.persist();
    setDirty(true);
    setEditingData((prev) => ({ ...prev, fullName: e.target.value }));
  }

  function onChangeCompany(e: React.ChangeEvent<HTMLInputElement>) {
    e.persist();
    setDirty(true);
    setEditingData((prev) => ({ ...prev, company: e.target.value }));
  }

  function onChangeJobTitle(e: React.ChangeEvent<HTMLInputElement>) {
    e.persist();
    setDirty(true);
    setEditingData((prev) => ({ ...prev, jobTitle: e.target.value }));
  }

  function onChangeNewPassword(e: React.ChangeEvent<HTMLInputElement>) {
    e.persist();
    setDirty(true);
    setEditingData((prev) => ({ ...prev, newPassword: e.target.value }));
  }

  function onChangeConfirmPassword(e: React.ChangeEvent<HTMLInputElement>) {
    e.persist();
    setDirty(true);
    setEditingData((prev) => ({ ...prev, confirmPassword: e.target.value }));
  }

  function onChangePassword(e: React.ChangeEvent<HTMLInputElement>) {
    e.persist();
    setDirty(true);
    setEditingData((prev) => ({ ...prev, password: e.target.value }));
  }

  function _isValid(values: UserInfo) {
    const newErrors = _validate(values);
    setErrors(newErrors);

    return Object.values(newErrors).every((error) => !error);
  }

  function _validate(values: UserInfo) {
    const newErrors = { ...initialErrors };

    if (!values.fullName) {
      newErrors.fullName = nls.requiredName();
    } else if (values.fullName.length < FULL_NAME_MIN_LENGTH) {
      newErrors.fullName = nls.minLength(FULL_NAME_MIN_LENGTH);
    }

    if (!values.company) {
      newErrors.company = nls.requiredCompany();
    } else if (values.company.length < COMPANY_MIN_LENGTH) {
      newErrors.company = nls.minLength(COMPANY_MIN_LENGTH);
    }
    if (values.newPassword && !isValidPassword(values.newPassword)) {
      newErrors.newPassword = nls.checkPwLength();
    } else if (values.confirmPassword && !values.newPassword) {
      newErrors.newPassword = nls.requiredPassword();
    }

    if (values.confirmPassword && !isValidPassword(values.confirmPassword)) {
      newErrors.confirmPassword = nls.checkPwLength();
    } else if (values.newPassword && !values.confirmPassword) {
      newErrors.confirmPassword = nls.requiredConfirmNewPassword();
    } else if (values.confirmPassword !== values.newPassword) {
      newErrors.confirmPassword = nls.notEqualNewPassword();
    }
    return newErrors;
  }

  function requestUpdateUserInfo() {
    const payload = pickBy(editingData, (item) => !!item);

    updateInfoMutation.mutate(payload, {
      onSuccess: () => {
        setDirty(false);
        setMode('view');
        setErrors(initialErrors);
        showPasswordDialog(false);
      },
    });
  }

  return {
    data: editingData,
    mode,
    onClickEditButton,
    onClickCancelButton,
    onClickSaveButton,

    onChangeName,
    onChangeCompany,
    onChangeJobTitle,
    onChangeNewPassword,
    onChangeConfirmPassword,

    onChangePassword,

    errors,
    setErrors,
    passwordDialogShowing,
    hidePasswordDialog: () => showPasswordDialog(false),
    requestUpdateUserInfo,
  };
}
