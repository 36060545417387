import { Project, Snapshot } from 'stores/data/types';
import { UserInfo } from './types';

export const initialProject: Project = {
  id: undefined,
  name: '',
  description: '',
  constructionDate: '',
  completedDate: '',
  state: null,
  owner: '',
  snapshots: [],
  coordinate: '',
  permissionInfo: null,
};

export const initialUserInfo: UserInfo = {
  fullName: '',
  email: '',
  company: '',
  password: '',
  confirmPassword: '',
  newPassword: '',
  jobTitle: '',
};

export const initialAerialImages = [];
export const initialSnapshot: Snapshot = {
  id: undefined,
  takeDate: '',
  description: '',
  coreEngine: 'MEISSA',
  isPreprocessed: false,
  processingStatus: 'IDLE',
};
