const MAIN = '#FF9800';
const DARK = '#C77700';
const LIGHT = '#FFB547';
const CONTRAST_TEXT = '#1D2125';
/** @deprecated
 * src/shared/styles/muiV6/colors.ts 또는 Mui theme의 palette 값으로 대체해주세요.
 */
export const warning = {
  MAIN,
  DARK,
  LIGHT,
  CONTRAST_TEXT,
};
