import { downloadFileWithCustomName } from 'shared/common/utils';
import { downloadFile } from 'shared/common/utils/file';
import GA from 'shared/ga';
import {
  ARCHIVE_LINK_FILE_DELETE,
  ARCHIVE_LINK_FILE_DOWNLOAD,
  ARCHIVE_LINK_FILE_SEARCH,
} from 'shared/ga/actions';
import { ARCHIVE } from 'shared/ga/category';
import { getArchiveLabel } from 'shared/ga/label';
import {
  useAnnotationMutation,
  useChainMutation,
  useFileArchiveQuery,
  useResourceMutation,
} from 'shared/query';
import { BaseAnnotation, getLowerCaseAnnotationType } from 'shared/query/annotation/types';
import { ArchiveFile, ArchiveFileTableRow } from 'shared/query/fileArchive/types';
import { Permission } from 'shared/query/permission/types';
import { fileArchiveKeys } from 'shared/query/queryKeys';
import { ResourceType } from 'shared/query/resource/types';
import useResourceV4Mutation from 'shared/query/resource/useResourceV4Mutation';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  FILE_ARCHIVE_TYPE_NAME,
  getFileIcon,
  isAnnotation,
  isFileSettingResource,
  isResource,
} from './utils';

interface Props {
  projectId: number;
  size?: number;
  showAnnotationDeleteConfirm: (fn: () => void, name: string) => void;
  showResourceDeleteConfirm: (fn: () => void, name: string) => void;
  permission: Permission;
}

/** TODO: 타이핑하는 실시간 검색도 가능한듯 추후 개선 논의 필요 */
export default function useTableData({
  projectId,
  size = 8,
  showAnnotationDeleteConfirm,
  showResourceDeleteConfirm,
  permission,
}: Props) {
  const queryClient = useQueryClient();
  const [page, setPage] = useState<number>(1); // 1이상의 정수
  const [value, setValue] = useState<string>(''); // 검색어
  const [search, setSearch] = useState<string>(undefined);
  const canGetFileArchive = Boolean(permission?.filearchiveGet);
  const canDownloadFile = Boolean(permission?.downloadProjectFiles);
  const canDeleteFile = Boolean(permission?.snapshotfileDelete);
  const canDeleteAnnotation = Boolean(permission?.annotationDelete);

  const { data, isLoading, isError } = useFileArchiveQuery(
    {
      projectId,
      page,
      size,
      search,
    },
    {
      enabled: canGetFileArchive,
    },
  );
  const rawData = data?.results || [];

  const { deleteAnnotation } = useAnnotationMutation();
  const { deleteResource } = useResourceMutation();
  const { deleteChain } = useChainMutation();
  const { deleteMutation } = useResourceV4Mutation();

  function setTrimSearchWord() {
    setPage(1);
    setSearch(value.trim());
    setValue((prev) => prev.trim());
    GA.event(ARCHIVE, ARCHIVE_LINK_FILE_SEARCH);
  }

  function clearSearch() {
    setPage(1);
    setSearch(undefined);
    setValue('');
  }

  /**
   * async (비동기) 처리 하면 안됨.
   * Todo async 를 달았을 경우 비동기 처리 되어서 반환값에 객체가 발생 합니다.
   * 추후 함수의 유무로 비교 판정하는 케이스에 대한 처리를 변경해야 할 것으로 보임.
   */
  const getDownloadFunction = (file: ArchiveFile) => {
    if (isFileSettingResource(file.type) && permission?.storageResource?.downloadResource) {
      return () => {
        downloadFileWithCustomName(file.fileUrl, file.name);
        GA.event(ARCHIVE, ARCHIVE_LINK_FILE_DOWNLOAD, getArchiveLabel(file.type));
      };
    } else if (canDownloadFile && file?.fileUrl) {
      return () => {
        if (!file?.name || !file.fileUrl) return;
        downloadFileWithCustomName(file.fileUrl, file.name);
        GA.event(ARCHIVE, ARCHIVE_LINK_FILE_DOWNLOAD, getArchiveLabel(file.type));
      };
    }
  };

  const getDeleteFunction = (file: ArchiveFile) => {
    if (canDeleteAnnotation && file.type === 'ANNOTATION_CSV') {
      return () => {
        showResourceDeleteConfirm(() => {
          deleteChain.mutate(
            {
              zoneId: file.zoneId,
              resourceId: file.id,
            },
            {
              onSuccess: () => {
                onSuccessDelete(file.type);
              },
            },
          );
        }, file.name);
      };
    }
    if (canDeleteAnnotation && isAnnotation(file.type)) {
      return () => {
        showAnnotationDeleteConfirm(() => {
          deleteAnnotation.mutate(
            {
              zoneId: file.zoneId,
              annotationType: getLowerCaseAnnotationType(
                file.type as BaseAnnotation['annotationType'],
              ),
              annotationId: file.id,
            },
            {
              onSuccess: () => {
                onSuccessDelete(file.type);
              },
            },
          );
        }, file.name);
      };
    }
    if (canDeleteFile && isResource(file.type)) {
      return () => {
        showResourceDeleteConfirm(() => {
          deleteResource.mutate(
            {
              zoneId: file.zoneId,
              type: file.type as ResourceType,
              resourceId: file.id,
            },
            {
              onSuccess: () => {
                onSuccessDelete(file.type);
              },
            },
          );
        }, file.name);
      };
    }
    if (isFileSettingResource(file.type) && permission?.resource?.deleteResource) {
      return () => {
        showResourceDeleteConfirm(() => {
          deleteMutation.mutate(
            {
              resourceId: file.id,
            },
            {
              onSuccess: () => {
                onSuccessDelete(file.type);
              },
            },
          );
        }, file.name);
      };
    }
    return null;
  };

  const onSuccessDelete = (type) => {
    queryClient.invalidateQueries(fileArchiveKeys.list(projectId, page, size, search));
    GA.event(ARCHIVE, ARCHIVE_LINK_FILE_DELETE, getArchiveLabel(type));
  };

  const getDateFormat = (d: string) =>
    `${d
      .split('-')
      .map((x) => +x)
      .join('. ')}.`;

  return {
    isLoading,
    isError,
    maxPage: Math.ceil(data?.count / size),
    page,
    setPage,
    value,
    setValue,
    search,
    setTrimSearchWord,
    clearSearch,
    rawData,
    rows:
      (rawData.map((file: ArchiveFile) => ({
        ...file,
        date: getDateFormat(file.created),
        icon: getFileIcon(file.type),
        download: getDownloadFunction(file),
        delete: getDeleteFunction(file),
        displayType: FILE_ARCHIVE_TYPE_NAME[file.type] || file.type,
      })) as ArchiveFileTableRow[]) ?? [],
  };
}
