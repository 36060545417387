import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { nls } from 'shared/locale/language';
import theme from 'shared/styles/mui/theme';
import styled from 'styled-components';
import NavigationBar from '../NavigationBar';
import NoticeSnackbarProvider from '../topbar/Notice/SnackbarProvider';
import WarningBrowserBar from '../topbar/WarningBrowserBar';

interface Props {
  fixed?: boolean;
  disabledNotice?: boolean;
  onProjectClick?: () => void;
}
function NavigationNoticeLayout({
  fixed = false,
  disabledNotice = false,
  onProjectClick = null,
}: Props) {
  return (
    <Wrapper>
      <NavigationBar fixed={fixed} onProjectClick={onProjectClick} />
      {!disabledNotice && <NoticeSnackbarProvider />}
      <WarningBrowserBar />
      <TemporaryTooltip />
    </Wrapper>
  );
}

/** @todo 2025년 2월 28일 이후 제거 필요 */
function TemporaryTooltip() {
  const dueDate = new Date('2025-02-28');
  const isClosed = localStorage.getItem('project-management-temporary-tooltip') === 'closed';
  const outdated = new Date() > dueDate;

  const [show, setShow] = React.useState(true);

  if (isClosed || outdated || !show) {
    return null;
  }

  function onClickClose() {
    setShow(false);
    localStorage.setItem('project-management-temporary-tooltip', 'closed');
  }
  return (
    <Box
      sx={{
        position: 'fixed',
        top: '10.5rem',
        left: '4.5rem',
        padding: '0.5rem 1rem',
        backgroundColor: theme.palette.grey[900],
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        zIndex: 1300,
        color: theme.palette.primary.contrastText,
        '&::before': {
          content: '""',
          position: 'absolute',
          left: '-1rem',
          top: '50%',
          transform: 'translateY(-50%)',
          border: '0.5rem solid transparent',
          borderRightColor: theme.palette.grey[900],
        },
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography color="inherit" variant="subtitle1">
          {nls.projectManagementTemporaryTooltipTitle()}
        </Typography>
        <IconButton size="small" color="inherit" onClick={onClickClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Typography
        color="inherit"
        variant="body2"
        sx={{ whiteSpace: 'pre-line', marginLeft: '0.5rem' }}
      >
        {nls.projectManagementTemporaryTooltipContent()}
      </Typography>
    </Box>
  );
}

export default NavigationNoticeLayout;

const Wrapper = styled.div`
  display: flex;
`;
