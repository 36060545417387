import Cancel from '@mui/icons-material/Cancel';
import Search from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import theme from 'shared/styles/mui/theme';

type Props = TextFieldProps & {
  variant?: 'outlined' | 'filled';
};

export default function SearchField({ variant = 'outlined', value, onChange, ...rest }: Props) {
  const valueStr = (value as string) || '';
  return (
    <TextField
      {...rest}
      variant="outlined"
      value={value}
      onChange={onChange}
      autoComplete="off"
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: valueStr?.length > 0 && (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                size="small"
                onClick={(e) => onChange?.({ target: { value: '' } } as any)}
              >
                <Cancel />
              </IconButton>
            </InputAdornment>
          ),
        },
      }}
      sx={{
        width: '100%',
        margin: 0,
        padding: 0,
        borderRadius: '0.25rem',
        '.MuiInputBase-root': {
          height: '2.5rem',
        },
        '.MuiInputBase-input': {
          fontSize: '1rem',
          fontWeight: 400,
          lineHeight: '175%',
        },
        '.MuiSvgIcon-root': {
          color: theme.palette.text.secondary,
        },
        '.MuiIconButton-sizeSmall': {
          padding: 0,
        },
        '.MuiIconButton-edgeEnd': {
          svg: {
            width: '1.25rem',
            height: '1.25rem',
          },
        },
        ...(variant === 'filled' && {
          backgroundColor: theme.palette.grey[100],
          '.MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.grey[100]}`,
          },
        }),
      }}
    />
  );
}
