import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import InputPasswordDialog from 'features/general/setting/component/myInformationManagement/InputPasswordDialog';
import { PASSWORD_MAX_LENGTH } from 'shared/common/policies/limit';
import { nls } from 'shared/locale/language';
import theme from 'shared/styles/mui/theme';
import styled from 'styled-components';
import SettingContentLayout from '../SettingContentLayout';
import useMyInformationManagement from './useMyInformationManagement';

/** @todo default button (grey color) 추가 */
export default function MyInformationManagement() {
  const {
    data,
    mode,
    onClickEditButton,
    onClickCancelButton,
    onClickSaveButton,

    onChangeName,
    onChangeCompany,
    onChangeJobTitle,
    onChangeNewPassword,
    onChangeConfirmPassword,

    errors,
    setErrors,
    onChangePassword,
    passwordDialogShowing,
    hidePasswordDialog,
    requestUpdateUserInfo,
  } = useMyInformationManagement();

  return (
    <SettingContentLayout
      title={nls.myInfo()}
      headerActions={
        <HeaderActions
          mode={mode}
          onClickEditButton={onClickEditButton}
          onClickCancelButton={onClickCancelButton}
          onClickSaveButton={onClickSaveButton}
        />
      }
    >
      <>
        <Divider sx={{ width: '100%', marginBottom: '2.5rem' }} />
        {mode === 'view' && (
          <Grid>
            <GridHeader variant="subtitle1" color="textSecondary">
              {nls.email()}
            </GridHeader>
            <Typography variant="body1">{data?.email}</Typography>
            <GridHeader variant="subtitle1" color="textSecondary">
              {nls.name()}
            </GridHeader>
            <Typography variant="body1">{data?.fullName}</Typography>
            <GridHeader variant="subtitle1" color="textSecondary">
              {nls.company()}
            </GridHeader>
            <Typography variant="body1">{data?.company}</Typography>
            <GridHeader variant="subtitle1" color="textSecondary">
              {nls.jobTitle()}
            </GridHeader>
            <Typography variant="body1">{data?.jobTitle}</Typography>
          </Grid>
        )}
        {mode === 'edit' && (
          <Grid>
            <GridHeader variant="subtitle1" color="textSecondary">
              {nls.email()}
            </GridHeader>
            <Typography variant="body1">{data?.email}</Typography>

            <GridHeader variant="subtitle1" color="textSecondary" className="required">
              {nls.name()}
            </GridHeader>
            <StyledTextField
              variant="outlined"
              value={data?.fullName}
              onChange={onChangeName}
              placeholder={nls.phName()}
              sx={{ maxHeight: '3.5rem' }}
              helperText={errors.fullName}
            />

            <GridHeader variant="subtitle1" color="textSecondary" className="required">
              {nls.company()}
            </GridHeader>
            <StyledTextField
              variant="outlined"
              value={data?.company}
              onChange={onChangeCompany}
              placeholder={nls.phCompany()}
              sx={{ maxHeight: '3.5rem' }}
              helperText={errors.company}
            />

            <GridHeader variant="subtitle1" color="textSecondary">
              {nls.jobTitle()}
            </GridHeader>
            <StyledTextField
              variant="outlined"
              value={data?.jobTitle}
              onChange={onChangeJobTitle}
              placeholder={nls.phJobTitle()}
              sx={{ maxHeight: '3.5rem' }}
            />

            <GridHeader variant="subtitle1" color="textSecondary">
              {nls.newPassword()}
            </GridHeader>
            <StyledTextField
              type="password"
              variant="outlined"
              value={data?.newPassword}
              onChange={onChangeNewPassword}
              placeholder={nls.phNewPassword()}
              autoComplete="new-password"
              inputProps={{ maxLength: PASSWORD_MAX_LENGTH }}
              sx={{ maxHeight: '3.5rem' }}
              helperText={errors.newPassword}
            />

            <GridHeader variant="subtitle1" color="textSecondary">
              {nls.confirmNewPassword()}
            </GridHeader>
            <StyledTextField
              type="password"
              variant="outlined"
              value={data?.confirmPassword}
              onChange={onChangeConfirmPassword}
              autoComplete="new-password"
              placeholder={nls.phConfirmNewPassword()}
              inputProps={{ maxLength: PASSWORD_MAX_LENGTH }}
              sx={{ maxHeight: '3.5rem' }}
              helperText={errors.confirmPassword}
            />
          </Grid>
        )}
        <InputPasswordDialog
          hideDialog={hidePasswordDialog}
          isShowing={passwordDialogShowing}
          onChange={onChangePassword}
          value={data?.password || ''}
          request={requestUpdateUserInfo}
          error={errors.password}
          setErrors={setErrors}
        />
      </>
    </SettingContentLayout>
  );
}

function HeaderActions({ mode, onClickEditButton, onClickCancelButton, onClickSaveButton }) {
  return (
    <Box sx={{ display: 'flex', gap: '1rem' }}>
      {mode === 'view' ? (
        <Button
          variant="contained"
          onClick={onClickEditButton}
          sx={{
            height: '2.5rem',
            backgroundColor: theme.palette.grey[200],
            color: theme.palette.text.primary,
            '&:hover': {
              backgroundColor: theme.palette.grey[300],
            },
          }}
        >
          {nls.modified()}
        </Button>
      ) : (
        <Button
          variant="contained"
          onClick={onClickCancelButton}
          sx={{
            height: '2.5rem',
            backgroundColor: theme.palette.grey[200],
            color: theme.palette.text.primary,
            '&:hover': {
              backgroundColor: theme.palette.grey[300],
            },
          }}
        >
          {nls.cancel()}
        </Button>
      )}
      {mode === 'edit' && (
        <Button variant="contained" onClick={onClickSaveButton} color="secondary">
          {nls.save()}
        </Button>
      )}
    </Box>
  );
}

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 7.5rem 1fr;
  gap: 2rem 1rem;
`;

const GridHeader = styled(Typography)`
  display: flex;
  align-items: center;
  &.required {
    &:after {
      content: '*';
      color: ${theme.palette.error.main};
      margin-left: 0.25rem;
    }
  }
`;

const StyledTextField = styled(TextField)`
  max-height: 3.5rem;
  .MuiFormHelperText-root {
    color: ${theme.palette.error.main};
  }
`;
