const GREY_50 = '#FAFAFC';
const GREY_100 = '#F4F4F4';
const GREY_200 = '#F0F0F0';
const GREY_300 = '#EBEBEC';
const GREY_400 = '#DEE0E2';
const GREY_500 = '#9BA0A3';
const GREY_600 = '#71777B';
const GREY_700 = '#5C6165';
const GREY_800 = '#3F4446';
const GREY_900 = '#1D2125';
const GREY_A100 = '#D5D5D5';
const GREY_A200 = '#AAAAAA';
const GREY_A400 = '#616161';
const GREY_A700 = '#303030';
/** @deprecated
 * src/shared/styles/muiV6/colors.ts 또는 Mui theme의 palette 값으로 대체해주세요.
 */
export const grey = {
  GREY_50,
  GREY_100,
  GREY_200,
  GREY_300,
  GREY_400,
  GREY_500,
  GREY_600,
  GREY_700,
  GREY_800,
  GREY_900,
  GREY_A100,
  GREY_A200,
  GREY_A400,
  GREY_A700,
};
