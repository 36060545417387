export const DEFAULT_POLLING_INTERVAL = 5_000;
export const POLLING_MEDIUM_INTERVAL = 10_000;
export const DRAWING_POLLING_LONG_INTERVAL = 30_000;

export const DEFAULT_REQUEST_TIMEOUT = 15_000;
export const DEFAULT_HEADERS = {
  pragma: 'no-cache',
  'Cache-Control': 'no-cache, no-store, must-revalidate',
  'x-meissa-frontend-version': import.meta.env.RELEASE,
  'Content-Type': 'application/json',
};

export const DEFAULT_QUERY_STALE_TIME = Infinity;

export const NOTICE_QUERY_STALE_TIME = 30 * 1000;

export const FEATURE_FLAG_INTERVAL = 30 * 1000;

export const LABORER_LIST_QUERY_REFETCH_INTERVAL = 10_000;

export const VEHICLE_LIST_QUERY_REFETCH_INTERVAL = 10_000;
export const READ_ELEVATION_GROUP_INTERVAL = 10_000;

export const EXPORT_EXCEL_INTERVAL = 2_000;
