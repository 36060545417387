export const TWO_D = '2D';
export const TWO_D_COMPARE = '2DCompare';
export const TWO_D_CURRENT = '2DCurrent';
export const TWO_D_ETC = '2DETC';
export const TWO_D_FLOATING = '2DFloating';
export const TWO_D_LINK = '2DLink';
export const TWO_D_TOOL = '2DTool';
export const TWO_D_NDVI = '2DNDVI';
export const TWO_D_EARTHWORK_ZONE = '2DEarthworkZone';
export const TWO_D_EARTHWORK_TABLE = '2DEarthworkTable';
export const TWO_D_EARTHWORK_FILE_SET = '2DEarthworkFileSet';
export const TWO_D_PILE_FILE_SET = '2DPileFileSet';
export const THREE_D_COMPARE = '3DCompare';
export const THREE_D_FLOATING = '3DFloating';
export const THREE_D_LINK = '3DLink';
export const THREE_D_MESH = '3DMesh';
export const THREE_D_POINT_CLOUD = '3DPointCloud';
export const DOWNLOAD = 'Download';
export const LOCA = 'Loca';
export const TWO_D_PANORAMA = '2DPanorama';
export const PANORAMA = 'Panorama';
export const PRINT = 'Print';
export const PROJECT = 'Project';
export const SLIDER = 'Slider';
export const SNAPSHOT = 'Snapshot';
export const VIDEO = 'Video';
export const ZONE = 'Zone';
export const BIRDS_EYE_VIEW = 'BirdsEyeView';
export const ARCHIVE = 'Archive';
export const ANALYSIS_START = 'AnalysisStart';
export const ANALYSIS_GCP = 'AnalysisGCP';
export const INDOOR = 'Indoor';
export const EARTHWORK_TABLE = 'EarthworkTable';
export const TWO_D_VEGETATION_INDEX = '2DVegetationIndex';
export const TWO_D_GREEN = '2DGreen';
export const TWO_D_THER = '2DTher';
export const DAILY_SCHEDULE = 'DailySchedule';
export const QUICK_VIEW = 'QuickView';
export const PHOTO_BOX = 'PhotoBox';
export const MOBILE_WEB = 'MobileWeb';
export const QUICK_SHOOTING = 'QuickShooting';
