export type S3FileUploadType = NormalFileType | LargeFileType;
export type NormalFileType = {
  url: string;
  fields: { [key: string]: string };
};
export type LargeFileType = {
  urls: string[];
  complete_multipart_upload_url: string;
  fields: { [Key: string]: string };
};

export const isNormalFile = (x: S3FileUploadType): x is NormalFileType =>
  !!(x as NormalFileType)?.url && !!x?.fields;

export const isLargeFile = (x: S3FileUploadType): x is LargeFileType =>
  !!(x as LargeFileType)?.urls && !!(x as LargeFileType)?.complete_multipart_upload_url;
