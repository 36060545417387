import Send from '@mui/icons-material/Send';
import { ContainedButton } from 'features/common/button/ContainedButton';
import RequestStatusMessage from 'features/common/RequestStatusMessage';
import { QueryStatus } from 'react-query';
import { nls } from 'shared/locale/language';
import { others } from 'shared/styles/colors/others';
import styled from 'styled-components';

interface Props {
  status: QueryStatus;
  onInviteClick: () => void;
  disableSend: boolean;
  loading: boolean;
}

const matchStatus = (status: QueryStatus) => {
  switch (status) {
    case 'loading':
      return 'LOADING';
    case 'error':
      return 'ERROR';
    case 'success':
      return 'SUCCESS';
    default:
      return 'NONE';
  }
};

export default function Footer({ status, onInviteClick, disableSend, loading }: Props) {
  const statusType = matchStatus(status);
  const message = {
    LOADING: nls.sendInvitationLoadingMessage(),
    ERROR: nls.sendInvitationErrorMessage(),
    SUCCESS: nls.sendInvitationSuccessMessage(),
  };

  return (
    <FooterLayout>
      <RequestStatusMessage status={statusType} message={message} progress={null} />
      <StyledPrimaryButton
        startIcon={<Send />}
        onClick={onInviteClick}
        disabled={disableSend}
        $loading={loading}
      >
        {nls.sendInvitation()}
      </StyledPrimaryButton>
    </FooterLayout>
  );
}

const FooterLayout = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${others.BORDER};
  padding: 2rem 4rem 2.5rem 4rem;
`;

const StyledPrimaryButton = styled(ContainedButton).attrs({ size: 'large' })``;
