import { Snackbar } from '@mui/material';
import {
  DEFAULT_ANNOTATION_COLOR,
  DEFAULT_CIRCLE_CENTER,
  DEFAULT_CIRCLE_MARKER_RADIUS,
} from 'shared/common/policies/2d';
import { isTouchDevice } from 'shared/common/utils';
import { LatLngLiteral, LeafletKeyboardEvent, LeafletMouseEvent } from 'leaflet';
import { nls } from 'shared/locale/language';
import React, { useRef, useState } from 'react';
import { CircleMarker, Polygon, useMapEvents } from 'react-leaflet';
import styled from 'styled-components';
import theme from 'shared/styles/mui/theme';

interface Props {
  onCancel: () => void;
  onSubmit: (points: LatLngLiteral[]) => void;
}

export default function CreatingPolygon({ onCancel, onSubmit }: Props) {
  const markerRef = useRef(null);
  const polygonRef = useRef<L.Polygon>(null);
  const [positions, setPositions] = useState([]);
  const tooltipMessage = isTouchDevice() ? nls.areaExitTooltipTouchDevice() : nls.areaExitTooltip();

  useMapEvents({
    mousemove(e: LeafletMouseEvent) {
      if (!markerRef.current || !polygonRef.current) return;
      markerRef.current.setLatLng(e.latlng);
      polygonRef.current.setLatLngs([...positions, e.latlng]);
    },
    click(e: LeafletMouseEvent) {
      if (!polygonRef.current) return;
      polygonRef.current.addLatLng(e.latlng);
      setPositions((prev) => [...prev, e.latlng]);
    },
    contextmenu() {
      if (positions?.length < 3) {
        onCancel();
        return;
      }
      onSubmit(positions);
    },
    keydown: (e: LeafletKeyboardEvent) => {
      if (e.originalEvent.key !== 'Escape') return;
      if (positions?.length < 3) {
        onCancel();
        return;
      }
      onSubmit(positions);
    },
  });

  return (
    <>
      <CircleMarker
        ref={markerRef}
        center={DEFAULT_CIRCLE_CENTER}
        radius={DEFAULT_CIRCLE_MARKER_RADIUS}
        color={DEFAULT_ANNOTATION_COLOR}
      />
      <Polygon
        ref={polygonRef}
        positions={polygonRef?.current?.getLatLngs() || []}
        color={DEFAULT_ANNOTATION_COLOR}
      />
      {positions?.map((position, index) => (
        <CircleMarker
          key={index}
          center={position}
          radius={DEFAULT_CIRCLE_MARKER_RADIUS}
          color={DEFAULT_ANNOTATION_COLOR}
        />
      ))}
      <StyledSnackbar message={tooltipMessage} open />
    </>
  );
}

const StyledSnackbar = styled(Snackbar).attrs( { anchorOrigin: { vertical: 'bottom', horizontal: 'center'} })`
  .MuiSnackbarContent-root {
    min-width: 0;
    background-color: ${theme.palette.grey[900]};
  }
`;
