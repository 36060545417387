import { primary } from './primary';
import { text } from './text';
import { hexToRgba } from './utils';

const PAPER = '#FFFFFF';
const DEFAULT = '#FAFAFC';
const ITEM = '#F7F8FB';
/** rgba(29, 33, 37, opacity?? 0.8) */
const DARK = (opacity?: number) => hexToRgba(text.PRIMARY, opacity ?? 0.8);
const PRIMARY = hexToRgba(primary.MAIN, 0.08);

/** @deprecated
 * src/shared/styles/muiV6/colors.ts 또는 Mui theme의 palette 값으로 대체해주세요.
 */
export const background = { PAPER, DEFAULT, DARK, PRIMARY, ITEM };
