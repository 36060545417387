import MuiAlert from '@material-ui/lab/Alert';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import MuiInfo from '@mui/icons-material/Info';
import { SvgIcon, SvgIconProps, Typography } from '@mui/material';
import MuiSnackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import { useAtom } from 'jotai';
import React, { SyntheticEvent } from 'react';
import theme from 'shared/styles/mui/theme';
import { globalSnackbarAtom } from 'stores/v3/snapshotDetail/globalSnackbar';
import styled from 'styled-components';

export const SNACKBAR_ANCHOR_ORIGIN = {
  topCenter: { vertical: 'top', horizontal: 'center' },
  bottomCenter: { vertical: 'bottom', horizontal: 'center' },
};

export default function Snackbar() {
  const [alertSnackbar, setAlertSnackbar] = useAtom(globalSnackbarAtom);

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    setAlertSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <StyledSnackbar
      open={alertSnackbar.open}
      onClose={handleClose}
      autoHideDuration={alertSnackbar.autoHideDuration}
      anchorOrigin={SNACKBAR_ANCHOR_ORIGIN[alertSnackbar.anchorOrigin] as SnackbarOrigin}
    >
      <Alert icon={<SnackbarIcon type={alertSnackbar.type} />}>
        <StyledTypography variant="body2">{alertSnackbar.message}</StyledTypography>
      </Alert>
    </StyledSnackbar>
  );
}

function SnackbarIcon({
  type,
  icon,
}: {
  type: 'info' | 'success' | 'warning' | 'error' | 'custom';
  icon?: React.ReactNode;
}) {
  return (
    <IconWrapper>
      {(() => {
        switch (type) {
          case 'success':
            return <SuccessIcon />;
          case 'custom':
            return icon;
          default:
            return <InfoIcon />;
        }
      })()}
    </IconWrapper>
  );
}

const StyledSnackbar = styled(MuiSnackbar)`
  &.MuiSnackbar-anchorOriginTopCenter {
    top: 6.5rem;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  > svg {
    width: 1rem;
    height: 1rem;
  }
`;

const InfoIcon = styled(MuiInfo)`
  padding: 0;
  color: ${theme.palette.warning.main};
`;
const SuccessIcon = styled(SvgIcon).attrs<SvgIconProps>({ component: CheckCircleIcon })`
  color: ${theme.palette.success.main};
`;

const Alert = styled(MuiAlert)`
  display: flex;
  align-items: center;
  &.MuiPaper-root {
    background-color: ${theme.palette.grey[900]};
  }
  &.MuiAlert-icon {
    padding: 0;
  }
`;

const StyledTypography = styled(Typography)`
  color: ${theme.palette.primary.contrastText};
`;
