import { useMatchParams } from 'features/common/hooks';
import useGlobalDialog from 'features/common/hooks/useGlobalDialog';
import { PLACEHOLDER_VALUE } from 'features/common/select/Select';
import useProjectMutation from 'features/general/project/query/useProjectMutation';
import useProjectQuery from 'features/general/project/query/useProjectQuery';
import { validateCoordSystemOption, validateTitle } from 'features/project/formPage/validators';
import routes from 'pages/routes';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { isJapan } from 'shared/common/customize';
import { mapTypes, mapTypesJp } from 'shared/common/policies/map';
import {
  DEFAULT_LOGOUT_BY_DISTANCE,
  LOGOUT_BY_DISTANCE_MAXIMUM_INPUT_VALUE,
  LOGOUT_BY_DISTANCE_MINIMUM_INPUT_VALUE,
} from 'shared/common/policies/project';
import { CoordinateSystem, datumList, datumListJp } from 'shared/common/types';
import { nls } from 'shared/locale/language';
import { useListCoordinatesQuery } from 'shared/query';
import projectManagementQueryKeys from '../../query/queryKeys';

export default function useProjectDetailManagementViewModel() {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { showAlert, showConfirm } = useGlobalDialog();

  const { projectId } = useMatchParams();
  const { data } = useProjectQuery(projectId);
  const projectName = data?.name ?? '';

  const localizedmapTypes = isJapan() ? mapTypesJp : mapTypes;

  const [dirty, setDirty] = useState(false);
  const [nameError, setNameError] = useState('');
  const [coordSystemText, setCoordSystemText] = useState('');
  const [coordSystemTextError, setCoordSystemTextError] = useState('');
  const [coordSystemCandidates, setCoordSystemCandidates] = useState({ query: '', data: [] });
  const [coordSystemOptionError, setCoordSystemOptionError] = useState('');
  const [coordSystemOption, setCoordSystemOption] = useState(PLACEHOLDER_VALUE);
  const coordSystemOptions = isJapan() ? datumListJp : datumList;
  const coordSystemDirectInputIsActive = coordSystemOption === CoordinateSystem.etc;

  const { deleteProject, updateProject } = useProjectMutation();
  useListCoordinatesQuery(
    { search: coordSystemText },
    {
      onSuccess: (data) => {
        setCoordSystemCandidates({
          data,
          query: coordSystemText,
        });
      },
    },
  );

  const [showQRCodeModal, setShowQRCodeModal] = useState(false);
  const [showBoundingBoxModal, setShowBoundingBoxModal] = useState(false);

  const [editingFormData, setEditingFormData] = useState(null);

  useEffect(() => {
    if (data) {
      setEditingFormData({
        ...data,
        volumeUnitId: data?.volumeUnit?.id || 3,
        data: {
          logoutByDistance: data.data.logoutByDistance || DEFAULT_LOGOUT_BY_DISTANCE,
        },
      });
      setCoordSystemOption(data.coordinate);
    }
  }, [data]);

  function onClickTitle() {
    if (dirty) {
      showConfirm({
        title: nls.titleExitWithoutSaving(),
        content: nls.exitWithoutSaving(),
        primaryButtonProps: {
          title: nls.confirm(),
          onClick: () => {
            history.push(routes.setting.project.path);
          },
        },
      });
    } else {
      history.push(routes.setting.project.path);
    }
  }

  function onClickDeleteProject() {
    showConfirm({
      title: nls.projectDeleteConfirmTitle(),
      content: nls.projectDeleteConfirmMessage(),
      primaryButtonProps: {
        title: nls.delete(),
        onClick: async () => {
          await deleteProject.mutateAsync({ projectId });
          queryClient.invalidateQueries(projectManagementQueryKeys.list());
          onClickTitle();
        },
        color: 'secondary',
      },
    });
  }

  function onClickSave() {
    const payload = {
      ...editingFormData,
      description: editingFormData?.description ? editingFormData?.description : null,
      coordinate: getCoordSystemValue(),
    };

    const titleErrorMessage = validateTitle(payload.name);
    setNameError(titleErrorMessage);

    const coordSystemOptionErrorMessage = validateCoordSystemOption(coordSystemOption);
    setCoordSystemOptionError(coordSystemOptionErrorMessage);

    if (titleErrorMessage || coordSystemOptionErrorMessage) return;

    updateProject.mutate(
      { projectId, data: payload },
      {
        onSuccess: () => {
          setDirty(false);
          showAlert({
            content: nls.projectFormUpdateSuccess(),
            primaryButtonProps: {
              title: nls.confirm(),
            },
          });
          queryClient.invalidateQueries(projectManagementQueryKeys.list());
        },
        onError: () => {
          showAlert({
            content: nls.projectFormUpdateError(),
            primaryButtonProps: { title: nls.confirm(), color: 'secondary' },
          });
        },
      },
    );
  }

  function getCoordSystemValue() {
    return coordSystemDirectInputIsActive ? coordSystemText : coordSystemOption;
  }

  function onChangeFormData(key, value) {
    setDirty(true);
    setEditingFormData({
      ...editingFormData,
      [key]: value,
    });
  }

  function onChangeName(e: React.ChangeEvent<HTMLInputElement>) {
    onChangeFormData('name', e.target.value);
  }
  function onChangeDescription(e: React.ChangeEvent<HTMLInputElement>) {
    onChangeFormData('description', e.target.value);
  }
  function onChangeConstructionDate(date: Date) {
    onChangeFormData('constructionDate', date.toISOString().split('T')[0]);
  }
  function onChangeCompletedDate(date: Date) {
    onChangeFormData('completedDate', date.toISOString().split('T')[0]);
  }

  function onClickQRCodeButton() {
    setShowQRCodeModal(true);
  }
  function onCloseQRCodeModal() {
    setShowQRCodeModal(false);
  }

  function onClickBoundingBoxButton() {
    setShowBoundingBoxModal(true);
  }
  function onCloseBoundingBoxModal() {
    setShowBoundingBoxModal(false);
  }

  function onChangeCoordSystemText(e: React.ChangeEvent<HTMLInputElement>) {
    setDirty(true);
    setCoordSystemText(e.target.value);
  }
  function onChangeCoordSystemOption(e) {
    setDirty(true);
    setCoordSystemOption(e.target.value);
  }

  function onChangeMapProvider(e: React.ChangeEvent<{ value: string }>) {
    onChangeFormData('mapProvider', e.target.value);
  }

  function onChangeVolumeUnit(value) {
    onChangeFormData('volumeUnitId', value);
  }

  function onChangeLogoutByDistance(e: React.ChangeEvent<HTMLInputElement>) {
    const value = +e.target.value;
    let logoutByDistance = value;
    if (!value || value < LOGOUT_BY_DISTANCE_MINIMUM_INPUT_VALUE) {
      logoutByDistance = 0;
    }
    if (value > LOGOUT_BY_DISTANCE_MAXIMUM_INPUT_VALUE) {
      logoutByDistance = LOGOUT_BY_DISTANCE_MAXIMUM_INPUT_VALUE;
    }
    onChangeFormData('data', { logoutByDistance });
  }

  const showDeleteButton = data?.permissionInfo?.projectDelete;
  const showSaveButton = data?.permissionInfo?.projectPut;

  return {
    projectName,
    onClickTitle,
    onClickDeleteProject,
    onClickSave,
    showDeleteButton,
    showSaveButton,

    data,
    editingFormData,
    onChangeFormData,

    nameError,
    onChangeName,
    onChangeDescription,
    onChangeConstructionDate,
    onChangeCompletedDate,

    coordSystemDirectInputIsActive,
    coordSystemCandidates,
    coordSystemText,
    setCoordSystemText,
    coordSystemTextError,
    setCoordSystemTextError,
    onChangeCoordSystemText,
    coordSystemOption,
    coordSystemOptions,
    coordSystemOptionError,
    onChangeCoordSystemOption,
    onChangeVolumeUnit,

    localizedmapTypes,
    onChangeMapProvider,

    onChangeLogoutByDistance,

    showQRCodeModal,
    onClickQRCodeButton,
    onCloseQRCodeModal,

    showBoundingBoxModal,
    onClickBoundingBoxButton,
    onCloseBoundingBoxModal,
  };
}
