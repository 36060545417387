import { error } from 'shared/styles/colors/error';
import theme from 'shared/styles/mui/theme';
import styled, { css } from 'styled-components';
import { textSecondary } from './style/TextStyles';

export const Label = styled.label<{ $isRequired?: boolean }>`
  ${textSecondary};
  margin: 0;
  min-width: 7.25rem;
  margin-right: 1.25rem;
  font-weight: 500;

  &:lang(en) {
    min-width: 10.5rem;
  }

  ${(props) =>
    props.$isRequired &&
    css`
      &::after {
        content: '*';
        margin-left: 0.125rem;
        color: ${theme.palette.error.main || error.MAIN};
        vertical-align: top;
      }
    `}
`;
