import { usePermissionQuery } from 'shared/query';
import ReactGa from 'react-ga4';
import { matchPath, withRouter } from 'react-router-dom';

const GATrackingId = import.meta.env.VITE_GA_TRACKING_ID;
const enabled = GATrackingId;

let currentProjectId: string | number;
let currentPermission: string | number;

function capitalize(text?: string): string {
  return text?.charAt(0).toUpperCase() + text?.substring(1).toLowerCase();
}

function initialize(): boolean {
  if (!enabled) return false;

  GATrackingId && ReactGa.initialize(GATrackingId);

  return true;
}

function sendEvent(category: string, action: string, label?: string, params?: any): void {
  if (!enabled) {
    return;
  }
  if (!label) {
    label = undefined;
  }
  if (typeof label === 'string') {
    label = capitalize(label);
  }

  // GA 추적 확인을 쉽게하기 위해 로그 표시
  console.log('sendEvent', {
    category,
    action,
    label,
    currentProjectId,
    currentPermission,
    ...params,
  });
  ReactGa.send({
    hitType: 'event',
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    project_id: currentProjectId,
    project_permission: currentPermission,
    ...params,
  });
}

function sendPageView(pathname: string): void {
  ReactGa.send({
    hitType: 'pageview',
    page: pathname,
    project_id: currentProjectId,
    project_permission: currentPermission,
  });
}

const setProperty = (projectId = undefined, permission = undefined) => {
  if (currentProjectId !== projectId) {
    currentProjectId = projectId;
  }
  if (currentPermission !== permission) {
    currentPermission = permission;
  }
};
function GA({ location }): null {
  const { pathname } = location;
  const match: any = matchPath(pathname, '/projects/:projectId');

  if (!match) {
    sendPageView(pathname);
    setProperty();
    return null;
  }

  const projectId = parseInt(match.params.projectId, 10);
  const permissionResult = usePermissionQuery(projectId);
  const permission = permissionResult.data?.permission;

  if (!permission) {
    return null;
  }

  setProperty(projectId, permission);
  sendPageView(pathname);

  return null;
}

export default Object.assign(withRouter(GA), {
  init: initialize,
  event: sendEvent,
});
