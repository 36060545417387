import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Box, Button } from '@mui/material';
import { nls } from 'shared/locale/language';
import theme from 'shared/styles/mui/theme';
import InvitationModal from '../invitationModal/InvitationModal';
import SettingContentLayout from '../SettingContentLayout';
import ProjectParticipantTable from './ProjectParticipantTable';
import useProjectParticipantManagementViewModel from './useProjectParticipantManagementViewModel';

export default function ProjectParticipantManagement() {
  const {
    projectId,
    projectName,
    onClickTitle,
    onClickDelete,
    onClickInvite,
    onClickCancelDelete,

    projectUsers,
    invitees,
    deleteMode,
    allChecked,
    checked,
    onToggleCheckbox,
    onAllCheckedChange,

    hasSendMailPermission,
    showDeleteButton,
    showInviteButton,

    showInvitationModal,
    onCloseInvitationModal,
  } = useProjectParticipantManagementViewModel();

  return (
    <SettingContentLayout
      title={nls.projectInfo()}
      secondaryTitle={`'${projectName}' ${nls.participantsInfo()}`}
      onClickTitle={onClickTitle}
      headerActions={
        <HeaderActions
          onClickCancelDelete={onClickCancelDelete}
          onClickDelete={onClickDelete}
          onClickInvite={onClickInvite}
          deleteMode={deleteMode}
          showDeleteButton={showDeleteButton}
          showInviteButton={showInviteButton}
        />
      }
    >
      <ProjectParticipantTable
        projectId={projectId}
        invites={invitees}
        projectUsers={projectUsers}
        deleting={deleteMode}
        allChecked={allChecked}
        checked={checked}
        onAllCheckedChange={onAllCheckedChange}
        onCheckedChange={onToggleCheckbox}
        hasSendMailPermission={hasSendMailPermission}
      />
      {showInvitationModal && (
        <InvitationModal
          open={showInvitationModal}
          onClose={onCloseInvitationModal}
          projectList={[{ id: projectId, name: projectName }]}
        />
      )}
    </SettingContentLayout>
  );
}

function HeaderActions({
  onClickDelete,
  onClickCancelDelete,
  onClickInvite,
  deleteMode,
  showDeleteButton,
  showInviteButton,
}: {
  onClickDelete: () => void;
  onClickCancelDelete: () => void;
  onClickInvite: () => void;
  deleteMode: boolean;
  showDeleteButton: boolean;
  showInviteButton: boolean;
}) {
  return (
    <Box sx={{ display: 'flex', gap: '0.75rem' }}>
      {showDeleteButton && (
        <Button
          variant="contained"
          onClick={onClickDelete}
          sx={{
            height: '2.5rem',
            backgroundColor: theme.palette.grey[200],
            color: theme.palette.text.primary,
            '&:hover': {
              backgroundColor: theme.palette.grey[300],
            },
          }}
        >
          {nls.delete()}
        </Button>
      )}
      {deleteMode ? (
        <Button
          variant="text"
          onClick={onClickCancelDelete}
          sx={{ height: '2.5rem' }}
          color="secondary"
          startIcon={<CloseIcon />}
        >
          {nls.cancelDeleteMode()}
        </Button>
      ) : (
        <>
          {showInviteButton && (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<PersonAddIcon />}
              onClick={onClickInvite}
              sx={{ height: '2.5rem' }}
            >
              {nls.inviteParticipant()}
            </Button>
          )}
        </>
      )}
    </Box>
  );
}
