import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { nls } from 'shared/locale/language';
import theme from 'shared/styles/mui/theme';
import { ProjectUser } from 'stores/data/types';
import styled from 'styled-components';
import MemberRow from './MemberRow';
import StyledTableRow from './StyledTableRow';
import TooltipContent from './TooltipContent';

interface Props {
  projectId: number;
  invites: ProjectUser[];
  projectUsers: ProjectUser[];
  deleting: boolean;
  allChecked: boolean;
  checked: { [id: number]: boolean };
  onAllCheckedChange: (open: boolean) => void;
  onCheckedChange: (id: number, checked: boolean) => void;
  hasSendMailPermission: boolean;
}

export default function ProjectParticipantTable({
  projectId,
  invites,
  projectUsers,
  deleting,
  allChecked,
  checked,
  onAllCheckedChange,
  onCheckedChange,
  hasSendMailPermission,
}: Props) {
  const showCheckBoxes = deleting;
  return (
    <StyledTableContainer>
      <Table stickyHeader>
        <TableHead>
          <StyledTableRow $header>
            <TableCell>
              <StyledCheckbox
                $visible={showCheckBoxes}
                checked={allChecked}
                onChange={(e) => onAllCheckedChange(e.target.checked)}
                color="secondary"
              />
            </TableCell>
            <TableCell>{nls.company()}</TableCell>
            <TableCell>{nls.name()}</TableCell>
            <TableCell>{nls.jobTitle()}</TableCell>
            <TableCell>
              {nls.basicPermission()}
              <Tooltip
                title={<TooltipContent />}
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: theme.palette.background.paper,
                      border: `1px solid ${theme.palette.divider}`,
                      maxWidth: '30rem',
                      marginTop: '0 !important',
                    },
                  },
                }}
              >
                <GradeHelpIconButton>
                  <HelpOutlineIcon />
                </GradeHelpIconButton>
              </Tooltip>
            </TableCell>
            <TableCell>{nls.status()}</TableCell>
            <TableCell />
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {invites.map((x) => (
            <MemberRow
              key={x.id}
              projectId={projectId}
              data={x}
              deleting={deleting}
              invitee
              checked={checked[x.id] || false}
              onCheckedChange={onCheckedChange}
              hasSendMailPermission={hasSendMailPermission}
            />
          ))}
          {projectUsers.map((x) => (
            <MemberRow
              key={x.id}
              projectId={projectId}
              data={x}
              deleting={deleting}
              invitee={false}
              checked={checked[x.id] || false}
              onCheckedChange={onCheckedChange}
            />
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}

const StyledTableContainer = styled(TableContainer)`
  border: 1px solid ${theme.palette.divider};
  border-radius: 0.25rem;
  background: ${theme.palette.background.paper};
`;

const StyledCheckbox = styled(Checkbox)<{ $visible: boolean }>`
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
`;

const GradeHelpIconButton = styled(IconButton)`
  color: ${theme.palette.text.disabled};
  padding: 0.6rem;
  margin-left: 0.19rem;

  &:focus {
    outline: none;
  }

  svg {
    font-size: 1rem;
  }
`;
