import RefreshIcon from '@mui/icons-material/Refresh';
import WarningIcon from '@mui/icons-material/Warning';
import { SvgIcon, SvgIconProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ContainedButton } from 'features/common/button/ContainedButton';
import NavigationBar from 'features/common/NavigationBar';
import { isLogin } from 'shared/common/utils';
import { nls } from 'shared/locale/language';
import theme from 'shared/styles/mui/theme';
import styled from 'styled-components';

export default function ErrorPage() {
  const refresh = () => window.location.reload();

  return (
    <Container>
      {isLogin() && <NavigationBar isErrorPage />}
      <div className="error-area">
        <StyledWarningIcon />
        <ErrorMessage>{nls.errorBoundaryMessage()}</ErrorMessage>
        <ContainedButton startIcon={<RefreshIcon />} size="large" onClick={refresh}>
          {nls.errorBoundaryRefreshButton()}
        </ContainedButton>
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
  min-height: 100vh;

  .navigation-bar {
    height: 100vh;
  }
  .error-area {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
const StyledWarningIcon = styled(SvgIcon).attrs<SvgIconProps>({ component: WarningIcon })`
  color: ${theme.palette.action.disabledBackground};
  width: 5rem;
  height: 5rem;
`;
const ErrorMessage = styled(Typography).attrs({ variant: 'h6' })`
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  color: ${theme.palette.text.disabled};
  white-space: pre-line;
  text-align: center;
`;
