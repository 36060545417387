import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, Button, Checkbox, Switch, Tooltip } from '@mui/material';
import DisabledCheckboxIcon from 'assets/images/iconCheckBoxOutlineBlankDisabledCustom.svg?react';
import React from 'react';
import { nls } from 'shared/locale/language';
import { hexToRgba } from 'shared/styles/colors/utils';
import theme from 'shared/styles/mui/theme';

interface Props {
  header?: boolean;
  name?: string;
  done?: boolean;
  permission?: string;
  numberOfParticipants?: number;
  numberOfSnapshots?: number;
  checkedSubscribedMail?: boolean;
  onClickProjectName?: () => void;
  onClickDetail?: () => void;
  onClickParticipant?: () => void;
  onToggleSubscribeMail?: () => void;
  disabledCheckbox?: boolean;
  disabledParticipant?: boolean;
  disabledDetail?: boolean;
  disabledSubscribeMail?: boolean;
  checked?: boolean;
  indeterminate?: boolean;
  onToggleCheckbox?: (c: boolean) => void;
}

export default function ProjectInfoTableRow({
  header,
  done,
  name,
  checked,
  checkedSubscribedMail,
  indeterminate,
  permission,
  numberOfParticipants,
  numberOfSnapshots,
  onClickProjectName,
  onClickDetail,
  onToggleSubscribeMail,
  onToggleCheckbox,
  onClickParticipant,
  disabledCheckbox,
  disabledDetail,
  disabledParticipant,
  disabledSubscribeMail,
}: Props) {
  if (header) {
    return (
      <TableRow header>
        <TableCell>
          <TableCheckbox
            checked={checked}
            onToggleCheckbox={onToggleCheckbox}
            indeterminate={indeterminate}
          />
          {nls.basicProjectName()}
        </TableCell>
        <TableCell>{nls.basicPermission()}</TableCell>
        <TableCell>{nls.basicParticipant()}</TableCell>
        <TableCell>{nls.basicSnapshot()}</TableCell>
        <TableCell>{nls.basicDetails()}</TableCell>
        <TableCell align="center" removePaddingRight>
          {nls.analyzedMailProjectListHeader()}
          <Tooltip arrow placement="top" title={nls.analyzeMailTooltipMessage()}>
            <HelpOutlineIcon className="help-icon" fontSize="small" />
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  }
  return (
    <TableRow checked={checked}>
      <TableCell onClick={onClickProjectName}>
        <TableCheckbox
          checked={checked}
          onToggleCheckbox={onToggleCheckbox}
          disabled={disabledCheckbox}
        />
        {done && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '2.3125rem',
              height: '1.375rem',
              borderRadius: '0.25rem',
              border: `1px solid ${theme.palette.text.disabled}`,
              background: theme.palette.grey[600],
              color: theme.palette.background.paper,
              textAlign: 'center',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: 500,
            }}
          >
            {nls.done()}
          </Box>
        )}
        {name}
      </TableCell>
      <TableCell>{permission}</TableCell>
      <TableCell>
        <Button
          onClick={onClickParticipant}
          disabled={disabledParticipant}
          color="primary"
          variant="text"
          endIcon={<ChevronRightIcon />}
          size="small"
        >
          {`${numberOfParticipants}${nls.basicPeopleUnit()}`}
        </Button>
      </TableCell>
      <TableCell>{numberOfSnapshots}</TableCell>
      <TableCell removePaddingRight>
        <Button
          onClick={onClickDetail}
          disabled={disabledDetail}
          color="primary"
          variant="text"
          endIcon={<ChevronRightIcon />}
          size="small"
        >
          {nls.projectDetailViewButtonTitle()}
        </Button>
      </TableCell>
      <TableCell align="center">
        <Switch
          checked={checkedSubscribedMail}
          onChange={onToggleSubscribeMail}
          disabled={disabledSubscribeMail}
        />
      </TableCell>
    </TableRow>
  );
}

function TableCheckbox({
  checked,
  onToggleCheckbox,
  indeterminate = false,
  disabled = false,
}: {
  checked: boolean;
  onToggleCheckbox: (c: boolean) => void;
  indeterminate?: boolean;
  disabled?: boolean;
}) {
  if (disabled) {
    return (
      <Box sx={{ '& img': { width: '1.5rem', height: '1.5rem' } }}>
        <DisabledCheckboxIcon />
      </Box>
    );
  }

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    onToggleCheckbox(e.target.checked);
  }

  function onClick(e: React.MouseEvent) {
    e.stopPropagation();
  }

  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      onClick={onClick}
      indeterminate={indeterminate}
      disabled={disabled}
      sx={{ padding: 0 }}
      color="secondary"
    />
  );
}

function TableRow({
  header,
  checked,
  children,
}: {
  header?: boolean;
  checked?: boolean;
  children: React.ReactNode;
}) {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 7.5rem 7.5rem 7.5rem 8.15rem 7.5rem',
        height: '4.375rem',
        minHeight: '4.375rem',
        borderBottom: `1px solid ${theme.palette.divider}`,
        boxSizing: 'border-box',
        fontSize: '0.875rem',
        '&:hover': {
          backgroundColor: header ? theme.palette.background.paper : theme.palette.action.hover,
        },
        position: header ? 'sticky' : 'static',
        top: header ? 0 : 'auto',
        zIndex: header ? 1 : 'auto',
        fontWeight: header ? 600 : 'normal',
        backgroundColor: (() => {
          if (header) return theme.palette.background.paper;
          return checked ? hexToRgba(theme.palette.primary.main, 0.08) : 'transparent';
        })(),
      }}
    >
      {children}
    </Box>
  );
}

function TableCell({
  align,
  removePaddingRight,
  onClick,
  children,
}: {
  align?: string;
  removePaddingRight?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        padding: removePaddingRight ? '0 0 0 1rem' : '0 1rem',
        justifyContent: align === 'center' ? 'center' : 'flex-start',
        '.help-icon': {
          color: theme.palette.text.secondary,
          width: '1rem',
          height: '1rem',
        },
        cursor: onClick ? 'pointer' : 'default',
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
}
