import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { MOBILE_MAX_WIDTH } from 'shared/common/policies/screen';
import { nls } from 'shared/locale/language';
import theme from 'shared/styles/mui/theme';
import styled from 'styled-components';
import TextButton from './TextButton';

interface Props {
  children?: string;
  onClick?: () => void;
  edge?: 'start' | '';
}
const BackButton = (props: Props) => {
  const { children = nls.back(), onClick, edge = '' } = props;
  const history = useHistory();
  const handleClick = () => (onClick ? onClick() : history.goBack());
  return (
    <>
      <StyledButton onClick={handleClick} startIcon={<ArrowBackIcon />} $edge={edge}>
        {children}
      </StyledButton>
    </>
  );
};

export default React.memo(BackButton);

const StyledButton = styled(TextButton).attrs({ size: 'large', color: 'inherit' })<{
  $edge?: string;
}>`
  align-items: end;
  ${({ $edge }) => $edge === 'start' && `margin-left: -1.375rem;`}
  .MuiButton-startIcon svg {
    color: ${theme.palette.text.secondary};
  }

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    display: none;
  }
`;
